import Nav from "../components/nav";

export default function Step2({
    landingPageData,
    setLandingPageData,
    editorContent,
    setEditorContent,
    showCountdown,
    setShowCountdown,
    showNavigation,
    setShowNavigation,
    showLogo,
    setShowLogo,
    logoFile,
    setLogoFile,
    updateLandingPageSettings,
    showTrending,
    setShowTrending,
    showRatings,
    setShowRatings,
    showRatingsCount,
    setShowRatingsCount,
    showRatingsAverage,
    setShowRatingsAverage,
    showDiscountFlag,
    setShowDiscountFlag,
    showAmazonCouponCount,
    setShowAmazonCouponCount,
}) {
    return (
        <Nav
            landingPageData={landingPageData}
            setLandingPageData={setLandingPageData}
            showCountdown={showCountdown}
            setShowCountdown={setShowCountdown}
            showNavigation={showNavigation}
            setShowNavigation={setShowNavigation}
            showLogo={showLogo}
            setShowLogo={setShowLogo}
            logoFile={logoFile}
            setLogoFile={setLogoFile}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            updateLandingPageSettings={updateLandingPageSettings}
            showTrending={showTrending}
            setShowTrending={setShowTrending}
            showRatings={showRatings}
            setShowRatings={setShowRatings}
            showRatingsCount={showRatingsCount}
            setShowRatingsCount={setShowRatingsCount}
            showRatingsAverage={showRatingsAverage}
            setShowRatingsAverage={setShowRatingsAverage}
            showDiscountFlag={showDiscountFlag}
            setShowDiscountFlag={setShowDiscountFlag}
            showAmazonCouponCount={showAmazonCouponCount}
            setShowAmazonCouponCount={setShowAmazonCouponCount}
        />
    );
}
