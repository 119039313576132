import { useEffect } from "react";
import AmbassadorList from "../components/AmbassadorList";

export default function Ambassador() {
    useEffect(() => {
        document.title = "Ambassador Programs - gotomarket.tools";
    }, []);

    return (
        <>
            <AmbassadorList />
        </>
    );
}
