import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../context/AuthContext";
import Spinner from "./Templates/Spinner";
import { Link } from "react-router-dom";

const fetchData = async (userToken) => {
    try {
        const response = await fetch(
            "https://api.gotomarket.tools/api/ambassadors/",
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        );
        if (!response.ok) {
            throw new Error("Failed to fetch Ambassador data");
        }
        const data = await response.json();
        return data.ambassadorSubmissions;
    } catch (error) {
        console.error("Fetching ambassador data failed:", error);
        return []; // Return an empty array in case of error to ensure .map can still function
    }
};

export default function AmbassadorList() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleEscape = (event) => {
            if (!showModal) return;

            if (event.key === "Escape") {
                setShowModal(false);
            }
        };

        document.addEventListener("keydown", handleEscape);
        return () => document.removeEventListener("keydown", handleEscape);
    }, [showModal]);

    const {
        data: ambassador,
        status,
        error,
    } = useQuery(["ambassador", userToken], () => fetchData(userToken), {
        enabled: !!userToken,
    });

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return <p>Error: {error.message}</p>;
    }

    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flow-root">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">
                                    Ambassadors
                                </h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    A list of Ambassadors on this account
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Ambassador Name
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Program Name
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Product Selected
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {ambassador.length === 0 ? (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="py-4 text-center text-sm"
                                                    >
                                                        No Ambassador Programs
                                                        available. Please add an
                                                        Ambassador Program.
                                                    </td>
                                                </tr>
                                            ) : (
                                                ambassador.map(
                                                    (ambassadors) => (
                                                        <tr
                                                            key={
                                                                ambassadors._id
                                                            }
                                                        >
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {`${ambassadors.personalInfo?.firstName} ${ambassadors.personalInfo?.lastName}`}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {`${ambassadors.programName}`}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {`${ambassadors.selectedProductName}`}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                <Link
                                                                    to={`${ambassadors._id}`}
                                                                    title="View"
                                                                    className="text-sky-600 hover:text-sky-900 mr-2"
                                                                >
                                                                    View
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Fallback JSX if none of the above conditions are met
    return null;
}
