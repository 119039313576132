import { useEffect } from "react";
import SurveyList from "../components/SurveyList";

export default function Surveys() {
    useEffect(() => {
        document.title = "Surveys - gotomarket.tools";
    }, []);

    return (
        <>
            <SurveyList />
        </>
    );
}
