import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Card, LineChart, Title } from "@tremor/react";

export default function Stats() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch view data
        const fetchViewData = async () => {
            try {
                const response = await fetch(
                    "https://api.gotomarket.tools/api/analytics/views",
                    { headers: { Authorization: `Bearer ${userToken}` } }
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();

                // Transform data to match the chart format
                const formattedData = data.map((item) => ({
                    date: item.date,
                    views: item.views,
                }));

                setChartData(formattedData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchViewData();
    }, [userToken]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <Card>
                <Title>View Count Over Time</Title>
                <LineChart
                    className="mt-6"
                    data={chartData}
                    index="date"
                    categories={["views"]}
                    colors={["blue"]}
                    startEndOnly="false"
                    showXAxis="true"
                />
            </Card>
        </div>
    );
}
