import { useEffect } from "react";
import GiveawayList from "../components/GiveawayList";

export default function Giveaways() {
    useEffect(() => {
        document.title = "Giveaways - gotomarket.tools";
    }, []);

    return (
        <>
            <GiveawayList />
        </>
    );
}
