import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step2Minimal from "./Step2Minimal";
import Step3 from "./Step3";

export default function AddLandingPage() {
    const { user } = useContext(AuthContext);
    const [layout, setLayout] = useState("");
    const [step, setStep] = useState(null);
    const [asin, setAsin] = useState("");
    const [landingPageData, setLandingPageData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showCountdown, setShowCountdown] = useState(true);
    const [showNavigation, setShowNavigation] = useState(true);
    const [showLogo, setShowLogo] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [showTrending, setShowTrending] = useState(true);
    const [showRatings, setShowRatings] = useState(true);
    const [showRatingsCount, setShowRatingsCount] = useState(true);
    const [showRatingsAverage, setShowRatingsAverage] = useState(true);
    const [showDiscountFlag, setShowDiscountFlag] = useState(true);
    const [showAmazonCouponCount, setShowAmazonCouponCount] = useState(true);
    const [trackingPixelHead, setTrackingPixelHead] = useState("");
    const [trackingPixelStartBody, setTrackingPixelStartBody] = useState("");
    const [trackingPixelEndBody, setTrackingPixelEndBody] = useState("");
    const [editorContent, setEditorContent] = useState("");
    const [couponUsageType, setCouponUsageType] = useState("");
    const [redemptionLimit, setRedemptionLimit] = useState(1);

    const { pageId } = useParams();

    useEffect(() => {
        const fetchProductData = async () => {
            setIsLoading(true);
            try {
                if (pageId) {
                    // The user is editing an existing landing page
                    const response = await axios.get(
                        `https://api.gotomarket.tools/api/landingpage/${pageId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${user.token}`,
                            },
                        }
                    );
                    const fetchedData = response.data;
                    setLandingPageData(fetchedData);

                    // Update all the state variables here
                    setLayout(fetchedData.layout);
                    setShowCountdown(fetchedData.showCountdown);
                    setShowNavigation(fetchedData.showNavigation);
                    setShowLogo(fetchedData.showLogo);
                    setLogoFile(fetchedData.logoFile);
                    setShowTrending(fetchedData.showTrending);
                    setShowRatings(fetchedData.showRatings);
                    setShowRatingsCount(fetchedData.showRatingsCount);
                    setShowRatingsAverage(fetchedData.showRatingsAverage);
                    setShowDiscountFlag(fetchedData.showDiscountFlag);
                    setShowAmazonCouponCount(fetchedData.showAmazonCouponCount);
                    setCouponUsageType(fetchedData.couponUsageType);

                    setStep(2);
                } else {
                    // The user is adding a new landing page
                    setLandingPageData({});
                    setStep(1);
                }
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
            setIsLoading(false);
        };

        fetchProductData();
    }, [pageId, user.token]);

    const saveAndContinue = async (e) => {
        setIsLoading(true);
        try {
            const response = await axios.post(
                "https://api.gotomarket.tools/api/landingpage/create",
                { asin, accountId: user.account._id, layout },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            setLandingPageData(response.data);

            // Check layout type and decide the next step
            const nextStep = layout === "Detailed" ? 2 : 3;

            setStep(nextStep); // set the next step accordingly
        } catch (error) {
            console.error("Error saving data:", error);
        }
        setIsLoading(false);
    };

    const updateLandingPageSettings = async () => {
        setIsLoading(true);
        try {
            await axios.patch(
                `https://api.gotomarket.tools/api/landingpage/${landingPageData._id}`,
                {
                    ...landingPageData,
                    showCountdown,
                    showNavigation,
                    showLogo,
                    logoFile,
                    showTrending,
                    showRatings,
                    showRatingsCount,
                    showRatingsAverage,
                    showDiscountFlag,
                    showAmazonCouponCount,
                    trackingPixelHead,
                    trackingPixelStartBody,
                    trackingPixelEndBody,
                    redemptionLimit,
                },
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            setStep(3);
        } catch (error) {
            console.error("Error updating settings:", error);
        }
        setIsLoading(false);
    };

    return (
        <div>
            {step === 1 && (
                <Step1
                    asin={asin}
                    setAsin={setAsin}
                    onSubmit={saveAndContinue}
                    isLoading={isLoading}
                    layout={layout}
                    setLayout={setLayout}
                />
            )}
            {step === 2 ? (
                layout === "Detailed" ? (
                    <Step2
                        landingPageData={landingPageData}
                        setLandingPageData={setLandingPageData}
                        showCountdown={showCountdown}
                        setShowCountdown={setShowCountdown}
                        showNavigation={showNavigation}
                        setShowNavigation={setShowNavigation}
                        showLogo={showLogo}
                        setShowLogo={setShowLogo}
                        logoFile={logoFile}
                        setLogoFile={setLogoFile}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        updateLandingPageSettings={updateLandingPageSettings}
                        showTrending={showTrending}
                        setShowTrending={setShowTrending}
                        showRatings={showRatings}
                        setShowRatings={setShowRatings}
                        showRatingsCount={showRatingsCount}
                        setShowRatingsCount={setShowRatingsCount}
                        showRatingsAverage={showRatingsAverage}
                        setShowRatingsAverage={setShowRatingsAverage}
                        showDiscountFlag={showDiscountFlag}
                        setShowDiscountFlag={setShowDiscountFlag}
                        showAmazonCouponCount={showAmazonCouponCount}
                        setShowAmazonCouponCount={setShowAmazonCouponCount}
                    />
                ) : (
                    <Step2Minimal
                        landingPageData={landingPageData}
                        setLandingPageData={setLandingPageData}
                        showCountdown={showCountdown}
                        setShowCountdown={setShowCountdown}
                        showNavigation={showNavigation}
                        setShowNavigation={setShowNavigation}
                        showLogo={showLogo}
                        setShowLogo={setShowLogo}
                        logoFile={logoFile}
                        setLogoFile={setLogoFile}
                        editorContent={editorContent}
                        setEditorContent={setEditorContent}
                        updateLandingPageSettings={updateLandingPageSettings}
                        showTrending={showTrending}
                        setShowTrending={setShowTrending}
                        showRatings={showRatings}
                        setShowRatings={setShowRatings}
                        showRatingsCount={showRatingsCount}
                        setShowRatingsCount={setShowRatingsCount}
                        showRatingsAverage={showRatingsAverage}
                        setShowRatingsAverage={setShowRatingsAverage}
                        showDiscountFlag={showDiscountFlag}
                        setShowDiscountFlag={setShowDiscountFlag}
                        showAmazonCouponCount={showAmazonCouponCount}
                        setShowAmazonCouponCount={setShowAmazonCouponCount}
                    />
                )
            ) : null}
            {step === 3 && (
                <Step3
                    landingPageData={landingPageData}
                    setLandingPageData={setLandingPageData}
                    updateLandingPageSettings={updateLandingPageSettings}
                    trackingPixelHead={trackingPixelHead}
                    setTrackingPixelHead={setTrackingPixelHead}
                    trackingPixelStartBody={trackingPixelStartBody}
                    setTrackingPixelStartBody={setTrackingPixelStartBody}
                    trackingPixelEndBody={trackingPixelEndBody}
                    setTrackingPixelEndBody={setTrackingPixelEndBody}
                    setStep={setStep}
                    couponUsageType={couponUsageType}
                    setCouponUsageType={setCouponUsageType}
                    redemptionLimit={redemptionLimit}
                    setRedemptionLimit={setRedemptionLimit}
                />
            )}
        </div>
    );
}
