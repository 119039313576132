import { useEffect, useContext } from "react";
import AccountEditComponent from "../components/AccountEditComponent";
import { AuthContext } from "../context/AuthContext";

export default function AccountPage() {
    const { user } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Edit Account - gotomarket.tools";
    }, []);

    return (
        <>
            <AccountEditComponent {...user} />
        </>
    );
}
