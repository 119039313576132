import { useState, useEffect } from "react";

export default function FirstRun() {
    useEffect(() => {
        document.title = "First Run - gotomarket.tools";
    }, []);

    const [step, setStep] = useState(1);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);

    const handleBusinessTypeChange = (type) => {
        if (businessTypes.includes(type)) {
            setBusinessTypes(businessTypes.filter((t) => t !== type));
        } else {
            setBusinessTypes([...businessTypes, type]);
        }
    };

    const handlePlatformChange = (platform) => {
        if (selectedPlatforms.includes(platform)) {
            setSelectedPlatforms(
                selectedPlatforms.filter((p) => p !== platform)
            );
        } else {
            setSelectedPlatforms([...selectedPlatforms, platform]);
        }
    };

    const handleNext = () => {
        if (step < 7) {
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">First Run Setup</h1>
            {step === 1 && (
                <div>
                    <h2 className="text-xl mb-2">Welcome to ReviewStream!</h2>
                    <p className="mb-4">
                        Ready to streamline your review collection? Let's get
                        started!
                    </p>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 hover:bg-sky-700"
                        onClick={() => setStep(2)}
                    >
                        Start Setup
                    </button>
                </div>
            )}
            {step === 2 && (
                <div>
                    <h2 className="text-xl mb-2">
                        What type of business do you run?
                    </h2>
                    <div className="grid grid-cols-2 gap-4">
                        {[
                            "eCommerce",
                            "Restaurant",
                            "Coffee Shop",
                            "Clothing Store",
                            "Software",
                            "Health and Wellness",
                            "Professional Services",
                            "Automotive Services",
                            "Real Estate",
                            "Education",
                            "Event Planning",
                            "Hospitality",
                            "Travel and Tourism",
                            "Healthcare",
                            "Beauty and Personal Care",
                        ].map((type) => (
                            <button
                                key={type}
                                className={`text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                    businessTypes.includes(type)
                                        ? "bg-sky-600 hover:bg-sky-700"
                                        : "bg-slate-400 hover:bg-slate-500"
                                }`}
                                onClick={() => handleBusinessTypeChange(type)}
                            >
                                {type}
                            </button>
                        ))}
                    </div>
                    <div className="mt-4">
                        <button
                            className="text-white mr-4 float-left font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 hover:bg-sky-700"
                            onClick={handleBack}
                        >
                            Back
                        </button>
                        <button
                            className="text-white float-left font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-500 hover:bg-orange-600"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
            {step === 3 && (
                <div>
                    <h2 className="text-xl mb-2">
                        Which platform(s) do you want to collect reviews on?
                    </h2>
                    <div className="space-y-6">
                        {businessTypes.map((type) => (
                            <div key={type}>
                                <h3 className="text-lg font-semibold mb-2">
                                    {type}
                                </h3>
                                <div className="grid grid-cols-2 gap-4">
                                    {getPlatformsForBusinessType(type).map(
                                        (platform) => (
                                            <button
                                                key={platform}
                                                className={`text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                                    selectedPlatforms.includes(
                                                        platform
                                                    )
                                                        ? "bg-sky-600 hover:bg-sky-700"
                                                        : "bg-slate-400 hover:bg-slate-500"
                                                }`}
                                                onClick={() =>
                                                    handlePlatformChange(
                                                        platform
                                                    )
                                                }
                                            >
                                                {platform}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4">
                        <button
                            className="text-white mr-4 float-left font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 hover:bg-sky-700"
                            onClick={handleBack}
                        >
                            Back
                        </button>
                        <button
                            className="text-white float-left font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-500 hover:bg-orange-600"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}
            {step === 4 && (
                <div>
                    <h2 className="text-xl mb-2">Customization</h2>
                    <p>
                        Guide the user through customizing their review
                        requests.
                    </p>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-500 hover:bg-orange-600"
                        onClick={handleNext}
                    >
                        Next
                    </button>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-400 hover:bg-slate-500"
                        onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
            )}
            {step === 5 && (
                <div>
                    <h2 className="text-xl mb-2">Review Request Setup</h2>
                    <p>
                        Provide default templates for review requests that can
                        be customized.
                    </p>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-500 hover:bg-orange-600"
                        onClick={handleNext}
                    >
                        Next
                    </button>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-400 hover:bg-slate-500"
                        onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
            )}
            {step === 6 && (
                <div>
                    <h2 className="text-xl mb-2">
                        Review Monitoring and Management
                    </h2>
                    <p>
                        Overview of collected reviews, platforms performance,
                        and analytics.
                    </p>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-500 hover:bg-orange-600"
                        onClick={handleNext}
                    >
                        Next
                    </button>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-400 hover:bg-slate-500"
                        onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
            )}
            {step === 7 && (
                <div>
                    <h2 className="text-xl mb-2">Final Setup and Go Live</h2>
                    <p>Review your settings and confirm.</p>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-orange-500 hover:bg-orange-600"
                        onClick={() => alert("Setup Complete!")}
                    >
                        Go Live
                    </button>
                    <button
                        className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-400 hover:bg-slate-500"
                        onClick={handleBack}
                    >
                        Back
                    </button>
                </div>
            )}
        </div>
    );
}

const getPlatformsForBusinessType = (type) => {
    switch (type) {
        case "eCommerce":
            return ["Amazon", "Walmart", "Target", "Temu", "Etsy", "eBay"];
        case "Restaurant":
            return ["Yelp", "Google", "Facebook", "TripAdvisor", "Zomato"];
        case "Coffee Shop":
            return ["Yelp", "Google", "Facebook", "TripAdvisor"];
        case "Clothing Store":
            return ["Yelp", "Google", "Facebook", "Instagram"];
        case "Software":
            return ["G2", "Capterra", "Trustpilot", "Software Advice"];
        case "Health and Wellness":
            return ["Yelp", "Google", "Facebook", "Mindbody"];
        case "Professional Services":
            return ["Yelp", "Google", "Facebook", "Avvo"];
        case "Automotive Services":
            return ["Yelp", "Google", "Facebook", "Cars.com"];
        case "Real Estate":
            return ["Zillow", "Realtor.com", "Yelp", "Google"];
        case "Education":
            return ["Google", "Yelp", "Trustpilot"];
        case "Event Planning":
            return ["Yelp", "Google", "Facebook", "The Knot"];
        case "Hospitality":
            return ["TripAdvisor", "Google", "Yelp", "Booking.com"];
        case "Travel and Tourism":
            return ["TripAdvisor", "Google", "Yelp"];
        case "Healthcare":
            return ["Healthgrades", "Yelp", "Google", "Zocdoc"];
        case "Beauty and Personal Care":
            return ["Yelp", "Google", "Facebook", "StyleSeat"];
        default:
            return [];
    }
};
