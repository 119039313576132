import {
    createContext,
    useReducer,
    useEffect,
    useState,
    useContext,
} from "react";
import jwtDecode from "jwt-decode";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { user: action.payload };
        case "LOGOUT":
            return { user: null };
        case "UPDATE_PLAN":
            return {
                user: {
                    ...state.user,
                    plan: action.payload, // assuming payload is the plan object
                },
            };
        case "UPDATE_USER":
            return {
                ...state,
                user: {
                    ...state.user, // keeps the existing user data
                    ...action.payload, // merges in the new user data (overwrites existing fields if any)
                },
            };
        default:
            return state;
    }
};
export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, { user: null });
    const [loading, setLoading] = useState(true);

    const isTokenExpired = (token) => {
        try {
            const decoded = jwtDecode(token);
            return decoded.exp * 1000 < Date.now();
        } catch (err) {
            return true; // If there's an error decoding, treat it as expired/invalid
        }
    };

    useEffect(() => {
        const storedUser = localStorage.getItem("user");

        if (storedUser) {
            const user = JSON.parse(storedUser);

            // Check token expiration
            if (user.token && isTokenExpired(user.token)) {
                onLogout();
            } else {
                dispatch({ type: "LOGIN", payload: user });
            }
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (state.user) {
            localStorage.setItem("user", JSON.stringify(state.user));
        } else {
            localStorage.removeItem("user");
        }
    }, [state.user]);

    const onLogout = () => {
        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider value={{ ...state, dispatch, onLogout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const { user, loading } = useContext(AuthContext);
    return { user, loading };
};
