import { useEffect } from "react";
import AmbassadorProfileDetail from "../components/AmbassadorProfileDetail";

export default function AmbassadorProfile() {
    useEffect(() => {
        document.title = "Ambassador Profile - gotomarket.tools";
    }, []);

    return (
        <>
            <AmbassadorProfileDetail />
        </>
    );
}
