import { useEffect, useContext } from "react";
import AccountComponent from "../components/AccountComponent";
import { AuthContext } from "../context/AuthContext";

export default function AccountPage() {
    const { user } = useContext(AuthContext);

    useEffect(() => {
        document.title = "Account - gotomarket.tools";
    }, []);

    return (
        <>
            <AccountComponent {...user} />
        </>
    );
}
