import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import ErrorPage from "./pages/Error";
// import Reviews from "./pages/Reviews";
import Ambassador from "./pages/Ambassador";
import AmbassadorProfile from "./pages/AmbassadorProfile";
import Ambassadors from "./pages/Ambassadors";
import AmbassadorProduct from "./pages/AmbassadorProduct";
import CreateAmbassadorProduct from "./pages/CreateAmbassadorProduct";
import EditAmbassadorProduct from "./pages/EditAmbassadorProduct";
import AmbassadorPage from "./components/Templates/AmbassadorTemplate";
import SurveyPage from "./components/Templates/SurveyTemplate";
import CreateAmbassadorPage from "./pages/CreateAmbassadorPage";
import EditAmbassadorPage from "./pages/EditAmbassadorPage";
import CreateSurvey from "./pages/CreateSurveyPage";
import EditSurvey from "./pages/EditSurvey";
import CreateProduct from "./pages/CreateProduct";
import EditProduct from "./pages/EditProduct";
import CreateGiveaway from "./pages/CreateGiveaway";
import EditGiveaway from "./pages/EditGiveaway";
import Surveys from "./pages/Surveys";
import Products from "./pages/Products";
import Giveaways from "./pages/Giveaways";
import Reviews from "./pages/Reviews";
import ReviewDetail from "./pages/ReviewDetail";
import LandingPage from "./pages/LandingPage";
import LandingPages from "./pages/LandingPages";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import Add from "./pages/Add";
import Customers from "./pages/Customers";
import Customer from "./pages/Customer";
// import Marketplaces from "./pages/Marketplaces";
// import MarketplacesCreate from "./pages/MarketplacesCreate";
// import ConfigureMarketplaces from "./pages/ConfigureMarketplaces";
import FirstRun from "./pages/FirstRun";
import Account from "./pages/Account";
import AccountEdit from "./pages/AccountEdit";
import Billing from "./pages/Billing";
import QrCodes from "./pages/QrCodes";
import CreateQr from "./pages/CreateQr";
import EditQr from "./pages/EditQr";
import Links from "./pages/Links";
import CreateLink from "./pages/CreateLink";
import EditLink from "./pages/EditLink";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import withAuth from "./withAuth";
import withoutAuth from "./withoutAuth";
import { AuthContextProvider } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import TagManager from "react-gtm-module";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Success from "./pages/Success";
import LandingPageTemplates from "./pages/LandingPageTemplate";
// import ReviewTemplate from "./components/Templates/ReviewTemplate";

const tagManagerArgs = {
    gtmId: "GTM-5S2LKWH4",
};

TagManager.initialize(tagManagerArgs);

const AuthenticatedHome = withAuth(Home);
const AuthenticatedLandingPage = withAuth(LandingPage);
// const AuthenticatedReviews = withAuth(Reviews);
const AuthenticatedAmbassador = withAuth(Ambassador);
const AuthenticatedAmbassadorProfile = withAuth(AmbassadorProfile);
const AuthenticatedAmbassadors = withAuth(Ambassadors);
const AuthenticatedAmbassadorProduct = withAuth(AmbassadorProduct);
const AuthenticatedCreateAmbassadorProduct = withAuth(CreateAmbassadorProduct);
const AuthenticatedEditAmbassadorProduct = withAuth(EditAmbassadorProduct);
const AuthenticatedCreateAmbassadorPage = withAuth(CreateAmbassadorPage);
const AuthenticatedEditAmbassadorPage = withAuth(EditAmbassadorPage);
const AuthenticatedCreateSurveyPage = withAuth(CreateSurvey);
const AuthenticatedEditSurvey = withAuth(EditSurvey);
const AuthenticatedCreateProduct = withAuth(CreateProduct);
const AuthenticatedCreateGiveaway = withAuth(CreateGiveaway);
const AuthenticatedSurveyPage = withAuth(Surveys);
const AuthenticatedProductPage = withAuth(Products);
const AuthenticatedEditProduct = withAuth(EditProduct);
const AuthenticatedGiveawayPage = withAuth(Giveaways);
const AuthenticatedEditGiveaway = withAuth(EditGiveaway);
const AuthenticatedReviewsPage = withAuth(Reviews);
const AuthenticatedReviewDetailPage = withAuth(ReviewDetail);
const AuthenticatedQrCodes = withAuth(QrCodes);
const AuthenticatedLinks = withAuth(Links);
const AuthenticatedCreateLink = withAuth(CreateLink);
const AuthenticatedEditLink = withAuth(EditLink);
const AuthenticatedCreateQr = withAuth(CreateQr);
const AuthenticatedEditQr = withAuth(EditQr);
const AuthenticatedAdd = withAuth(Add);
const AuthenticatedEdit = withAuth(Add);
const AuthenticatedCustomers = withAuth(Customers);
const AuthenticatedCustomer = withAuth(Customer);
// const AuthenticatedMarketplacesCreate = withAuth(MarketplacesCreate);
// const AuthenticatedMarketplaces = withAuth(Marketplaces);
// const AuthenticatedConfigureMarketplaces = withAuth(ConfigureMarketplaces);
const AuthenticatedFirstRun = withAuth(FirstRun);
const AuthenticatedAccount = withAuth(Account);
const AuthenticatedAccountEdit = withAuth(AccountEdit);
const AuthenticatedBilling = withAuth(Billing);
const AuthenticatedSuccess = withAuth(Success);
const UnauthenticatedLogin = withoutAuth(Login);
const UnauthenticatedSignup = withoutAuth(Signup);
// const UnauthenticatedReviewTemplate = withoutAuth(ReviewTemplate);
const stripePromise = loadStripe(
    "pk_live_51NVGjTDzaQMhQTLjaQXvsxmsLsWd59CVEhSW6Sltl0Q4pGPHLACimD6rBzFBc8BvS25odkwUX7guVbfHMhL5AIFv00NDtD7U6F"
);

const queryClient = new QueryClient();

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <AuthenticatedHome withNavigation={true} />,
            },
            {
                path: "landingpage/pages",
                element: <AuthenticatedLandingPage withNavigation={true} />,
            },
            // {
            //     path: "reviews",
            //     element: <AuthenticatedReviews withNavigation={true} />,
            // },
            {
                path: "ambassador/programs",
                element: <AuthenticatedAmbassador withNavigation={true} />,
            },
            {
                path: "ambassador/ambassadors",
                element: <AuthenticatedAmbassadors withNavigation={true} />,
            },
            {
                path: "ambassador/ambassadors/:ambassadorId",
                element: (
                    <AuthenticatedAmbassadorProfile withNavigation={true} />
                ),
            },
            {
                path: "ambassador/products",
                element: (
                    <AuthenticatedAmbassadorProduct withNavigation={true} />
                ),
            },
            {
                path: "ambassador/products/create",
                element: (
                    <AuthenticatedCreateAmbassadorProduct
                        withoutNavigation={true}
                    />
                ),
            },
            {
                path: "ambassador/products/edit/:id",
                element: <AuthenticatedEditAmbassadorProduct />,
            },
            {
                path: "ambassador/programs/create",
                element: <AuthenticatedCreateAmbassadorPage />,
            },
            {
                path: "ambassador/programs/edit/:id",
                element: <AuthenticatedEditAmbassadorPage />,
            },
            {
                path: "ambassador/program/:pageId",
                element: <AmbassadorPage />,
            },
            {
                path: "survey/:id",
                element: <SurveyPage />,
            },
            {
                path: "reviews/surveys",
                element: <AuthenticatedSurveyPage withNavigation={true} />,
            },
            {
                path: "reviews/surveys/create",
                element: <AuthenticatedCreateSurveyPage />,
            },
            {
                path: "reviews/surveys/edit/:id",
                element: <AuthenticatedEditSurvey />,
            },
            {
                path: "reviews/products",
                element: <AuthenticatedProductPage withNavigation={true} />,
            },
            {
                path: "reviews/products/create",
                element: <AuthenticatedCreateProduct />,
            },
            {
                path: "reviews/products/edit/:id",
                element: <AuthenticatedEditProduct />,
            },
            {
                path: "reviews/giveaways",
                element: <AuthenticatedGiveawayPage withNavigation={true} />,
            },
            {
                path: "reviews/giveaways/edit/:id",
                element: <AuthenticatedEditGiveaway />,
            },
            {
                path: "reviews/giveaways/create",
                element: <AuthenticatedCreateGiveaway />,
            },
            {
                path: "reviews/reviews",
                element: <AuthenticatedReviewsPage withNavigation={true} />,
            },
            {
                path: "reviews/reviews/:id",
                element: (
                    <AuthenticatedReviewDetailPage withNavigation={true} />
                ),
            },
            {
                path: "qrcodes",
                element: <AuthenticatedQrCodes withNavigation={true} />,
            },
            {
                path: "qrcodes/create",
                element: <AuthenticatedCreateQr />,
            },
            {
                path: "qrcodes/edit/:id",
                element: <AuthenticatedEditQr />,
            },
            {
                path: "links",
                element: <AuthenticatedLinks withNavigation={true} />,
            },
            {
                path: "links/create",
                element: <AuthenticatedCreateLink />,
            },
            {
                path: "links/edit/:id",
                element: <AuthenticatedEditLink />,
            },
            {
                path: "landingpage/pages/add",
                element: <AuthenticatedAdd />,
            },
            {
                path: "landingpage/edit/:pageId",
                element: <AuthenticatedEdit />,
            },
            {
                path: "landingpage/:pageId",
                element: <LandingPages />,
            },
            {
                path: "landingpage/customers",
                element: <AuthenticatedCustomers withNavigation={true} />,
            },
            // {
            //     path: "marketplaces",
            //     element: <AuthenticatedMarketplaces withNavigation={true} />,
            // },
            // {
            //     path: "marketplaces/create",
            //     element: (
            //         <AuthenticatedMarketplacesCreate withNavigation={true} />
            //     ),
            // },
            // {
            //     path: "marketplaces/configure/:id",
            //     element: (
            //         <AuthenticatedConfigureMarketplaces withNavigation={true} />
            //     ),
            // },
            {
                path: "firstrun",
                element: <AuthenticatedFirstRun withNavigation={true} />,
            },
            {
                path: "account",
                element: <AuthenticatedAccount withNavigation={true} />,
            },
            {
                path: "billing",
                element: <AuthenticatedBilling withNavigation={true} />,
            },
            {
                path: "account/edit",
                element: <AuthenticatedAccountEdit withNavigation={true} />,
            },
            {
                path: "customer/:customerId",
                element: <AuthenticatedCustomer withNavigation={true} />,
            },
            {
                path: "success",
                element: <AuthenticatedSuccess withNavigation={true} />,
            },
            {
                path: "login",
                element: <UnauthenticatedLogin />,
            },
            {
                path: "signup",
                element: <UnauthenticatedSignup />,
            },
            {
                path: "landingpagetemplate",
                element: <LandingPageTemplates />,
            },
            // {
            //     path: "reviewpagetemplate",
            //     element: <ReviewTemplate />,
            // },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthContextProvider>
        <QueryClientProvider client={queryClient}>
            <Elements stripe={stripePromise}>
                <RouterProvider router={router} />
            </Elements>
        </QueryClientProvider>
    </AuthContextProvider>
);
