import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext"; // Adjust path as necessary
import Pricing from "../components/Pricing";
import PlanDetails from "../components/PlanDetails"; // Your PlanDetails component

export default function Billing() {
    // Get the user data from context
    const { user } = useContext(AuthContext);

    // Check if the user has a subscription plan
    // The condition checks both the existence of 'planId' and whether it's not null
    const hasSubscription = user?.planId != null;

    useEffect(() => {
        document.title = "Billing - gotomarket.tools";
    }, []);

    // Render PlanDetails if the user has a subscription, otherwise render Pricing
    return hasSubscription ? <PlanDetails /> : <Pricing />;
}
