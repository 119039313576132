import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Step3({ landingPageData, handleDateChange }) {
    const initialStartDate = new Date(
        landingPageData.countdownConfig.startDate
    );
    const initialEndDate = new Date(landingPageData.countdownConfig.endDate);

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    useEffect(() => {
        handleDateChange(startDate, endDate);
    }, [startDate, endDate]);

    return (
        <div className="flex space-x-4 mt-2">
            <div className="w-1/2">
                <label className="block text-sm leading-6 text-gray-600">
                    Start Date
                </label>
                <div className="mt-1 w-full">
                    <div className="w-full">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                </div>
            </div>
            <div className="w-1/2">
                <label className="block text-sm leading-6 text-gray-600">
                    End Date
                </label>
                <div className="mt-1 w-full">
                    <div className="w-full">
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
