import { useEffect } from "react";
import LandingPageList from "../components/LandingPageList";

export default function LandingPage() {
    useEffect(() => {
        document.title = "Landing Pages - gotomarket.tools";
    }, []);
    return (
        <>
            <LandingPageList />
        </>
    );
}
