import { useEffect } from "react";
import LinkList from "../components/LinkList";

export default function LinksPage() {
    useEffect(() => {
        document.title = "Links - gotomarket.tools";
    }, []);

    return (
        <>
            <LinkList />
        </>
    );
}
