import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import Spinner from "./Spinner";

export default function AmbassadorTemplate() {
    const [account, setAccount] = useState("");
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [programName, setProgramName] = useState("");
    const [step, setStep] = useState(1);
    const [logo, setLogo] = useState("");
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [asin1, setAsin1] = useState("");
    const [reason1, setReason1] = useState("");
    const [productDetails1, setProductDetails1] = useState("");
    const [asin2, setAsin2] = useState("");
    const [reason2, setReason2] = useState("");
    const [asin3, setAsin3] = useState("");
    const [reason3, setReason3] = useState("");
    const [ordernumber, setOrderNumber] = useState("");
    const [paypal, setPaypal] = useState("");
    const [productDetails2, setProductDetails2] = useState("");
    const { pageId } = useParams();
    const isStep1Incomplete =
        !firstName ||
        !lastName ||
        !email ||
        !phone ||
        !address1 ||
        !city ||
        !state ||
        !zip;
    const isStep2Incomplete = !asin1 || !reason1 || !productDetails1;
    const isStep3Incomplete = !asin2 || !reason2 || !productDetails2;
    const isStep4Incomplete = !asin3 || !reason3;
    const isStep5Incomplete = !ordernumber || !paypal;
    const [validationMessages, setValidationMessages] = useState({
        email: "",
        phone: "",
        zip: "",
    });
    const [validationErrors, setValidationErrors] = useState({});

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/;
    const zipRegex = /^\d{5}$/;
    const asinRegex = /^[A-Z0-9]{10}$/i;
    const orderNumberRegex = /^\d{3}-\d{7}-\d{7}$/;

    useEffect(() => {
        const fetchAmbassadorData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `https://api.gotomarket.tools/api/ambassador/${pageId}`
                );
                if (!response.ok) throw new Error("Failed to fetch data");
                const data = await response.json();
                setProgramName(data.programName);
                setAccount(data.account);
                setLogo(data.logo);
                setProducts(data.products);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching ambassador data:", error);
                setIsLoading(false);
            }
        };

        fetchAmbassadorData();
    }, [pageId]);

    const handleProductChange = (e) => {
        const selectedId = e.target.value;
        const product = products.find((p) => p._id === selectedId);
        setSelectedProduct(product);
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setValidationMessages({
            ...validationMessages,
            email: emailRegex.test(value) ? "" : "Invalid email format",
        });
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);
        setValidationMessages({
            ...validationMessages,
            phone: phoneRegex.test(value)
                ? ""
                : "Invalid phone format (e.g., 123-456-7890, (123) 456-7890, 1234567890)",
        });
    };

    const handleZipChange = (e) => {
        const value = e.target.value;
        setZip(value);
        setValidationMessages({
            ...validationMessages,
            zip: zipRegex.test(value)
                ? ""
                : "Invalid ZIP code (should be 5 digits)",
        });
    };

    const validateASIN = (asin) => asinRegex.test(asin);

    const handleASINChange = (setter, value) => {
        setter(value);
        if (!validateASIN(value)) {
            setValidationErrors({
                ...validationErrors,
                asin: "Invalid ASIN",
            });
        } else {
            const newErrors = { ...validationErrors };
            delete newErrors.asin;
            setValidationErrors(newErrors);
        }
    };

    const handleOrderNumberChange = (e) => {
        const value = e.target.value;
        setOrderNumber(value);
        if (!orderNumberRegex.test(value)) {
            setValidationErrors((prev) => ({
                ...prev,
                ordernumber:
                    "Order number must be in the format 114-4584592-4573804",
            }));
        } else {
            const newErrors = { ...validationErrors };
            delete newErrors.ordernumber;
            setValidationErrors(newErrors);
        }
    };

    const handlePaypalEmailChange = (e) => {
        const value = e.target.value;
        setPaypal(value);
        if (!emailRegex.test(value)) {
            setValidationErrors((prev) => ({
                ...prev,
                paypal: "Invalid email format",
            }));
        } else {
            const newErrors = { ...validationErrors };
            delete newErrors.paypal;
            setValidationErrors(newErrors);
        }
    };

    const nextStep = (event) => {
        event.preventDefault();
        setStep(step + 1);
        window.scrollTo({
            top: 0,
        });
    };

    const handleInputChange = (event, setter) => {
        setter(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        // Construct URLSearchParams with all the necessary data
        const submissionData = new URLSearchParams({
            account: account,
            programName: programName,
            selectedProduct: selectedProduct._id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            address1: address1,
            address2: address2, // Optional field, included if non-empty
            city: city,
            state: state,
            zip: zip,
            asin1: asin1,
            reason1: reason1,
            productDetails1: productDetails1,
            asin2: asin2,
            reason2: reason2,
            productDetails2: productDetails2,
            asin3: asin3,
            reason3: reason3,
            orderNumber: ordernumber,
            paypalEmail: paypal,
            pageId: pageId, // Assuming you have pageId in your state or props
        }).toString();

        console.log(submissionData);

        // Define the URL to which the form should be submitted
        const url = `https://api.gotomarket.tools/api/ambassadors/${pageId}`;

        try {
            // Send a POST request to the server with the URLSearchParams
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: submissionData,
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            // Convert response to JSON or handle response data as needed
            const responseData = await response.json();
            console.log("Submission successful:", responseData);
            // Handle additional UI updates or notifications on successful submission
        } catch (error) {
            console.error("Error submitting the form:", error);
            // Handle or display the error appropriately in the UI
        }
        setStep(step + 1);
    };

    useEffect(() => {
        if (programName) {
            document.title = `${programName} - GoToMarket.Tools`;
        }
    }, [programName]); // Runs whenever programName changes

    if (isLoading) {
        return <Spinner />;
    }

    switch (step) {
        case 1:
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="p-6 w-128 bg-white border border-black">
                            {/* Product Image */}
                            <div className="flex justify-center items-center mb-4">
                                <div className="w-28 h-28 p-2 rounded-md bg-white flex justify-center items-center overflow-hidden">
                                    <img
                                        src={`data:image/jpeg;base64,${logo}`}
                                        className="max-w-full max-h-full object-contain"
                                        alt={`${programName}`}
                                    />
                                </div>
                            </div>

                            {/* Product Name */}
                            <h2 className="text-center text-2xl mb-2 font-extrabold">
                                Brand Ambassador Questionnaire
                            </h2>

                            {/* Product Description */}
                            <p className="text-center">
                                As a brand ambassador, you're our eyes and ears,
                                we value your opinion on our branding and how it
                                compares in the marketplace. This short quiz
                                will only take a few minutes and you'll get your
                                free product once finished.
                            </p>

                            {/* Product Name */}
                            <h2 className="text-center text-2xl mt-4 mb-2 font-extrabold">
                                Steps to complete:
                            </h2>

                            {/* Product Description */}
                            <p className="text-center">
                                1.) Look at Products Online
                                <br />
                                2.) Answer Basic Questions about Appearance
                                <br />
                                3.) Tell us What Catches Your Eye and What
                                Doesn't
                                <br />
                                4.) Tell us what you would change
                                <br />
                                5.) Get Free Product
                            </p>

                            <h2 className="text-center text-2xl mt-4 mb-2 font-extrabold">
                                Please select one product that you would like to
                                review and provide feedback for:
                            </h2>
                            <select
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
                                value={selectedProduct?._id || ""}
                                onChange={handleProductChange}
                            >
                                <option value="">Select a product...</option>
                                {products.map((product) => (
                                    <option
                                        key={product._id}
                                        value={product._id}
                                    >
                                        {product.productName}
                                    </option>
                                ))}
                            </select>
                            {selectedProduct && (
                                <div className="mt-4 flex justify-center items-center">
                                    <img
                                        src={`data:image/jpeg;base64,${selectedProduct.image}`}
                                        alt={selectedProduct.product}
                                        style={{
                                            width: "100%",
                                            maxHeight: "200px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </div>
                            )}

                            <button
                                onClick={(e) => nextStep(e)}
                                disabled={!selectedProduct} // Adjust condition based on the step or requirements
                                className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 mt-6 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                    selectedProduct
                                        ? "bg-sky-600 hover:bg-sky-700"
                                        : "bg-gray-400 cursor-not-allowed"
                                }`}
                            >
                                Next Step
                            </button>
                        </div>
                    </div>
                </div>
            );
        case 2:
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-full max-w-3xl bg-white border border-black">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "30%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                20% &nbsp;
                                            </span>
                                            (1/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 pb-6 pt-4">
                                <h2 className="text-center text-3xl font-bold mb-5">
                                    Brand Ambassador Questionnaire
                                </h2>
                                <p className="text-center mb-8">
                                    As a brand ambassador, you're our eyes and
                                    ears, we value your opinion on our branding
                                    and how it compares in the marketplace. This
                                    short quiz will only take a few minutes and
                                    you'll get your free product once finished.
                                </p>

                                <h3 className="text-xl font-semibold mb-3 text-center">
                                    General Contact Information
                                </h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            First Name*
                                        </label>
                                        <input
                                            id="firstName"
                                            type="text"
                                            placeholder="First Name*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Last Name*
                                        </label>
                                        <input
                                            id="lastName"
                                            type="text"
                                            placeholder="Last Name*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={lastName}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Email Address*
                                        </label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="Email Address*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                        />
                                        {validationMessages.email && (
                                            <p className="text-red-500">
                                                {validationMessages.email}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Phone*
                                        </label>
                                        <input
                                            type="text"
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            placeholder="Phone*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                        />
                                        {validationMessages.phone && (
                                            <p className="text-red-500">
                                                {validationMessages.phone}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <h3 className="text-xl font-semibold mb-3 text-center">
                                    Shipping Information
                                </h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Address 1*
                                        </label>
                                        <input
                                            id="address1"
                                            type="text"
                                            placeholder="Address 1*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={address1}
                                            onChange={(e) =>
                                                setAddress1(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Address 2*
                                        </label>
                                        <input
                                            id="address2"
                                            type="text"
                                            placeholder="Address 2"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={address2}
                                            onChange={(e) =>
                                                setAddress2(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            City*
                                        </label>
                                        <input
                                            id="city"
                                            type="text"
                                            placeholder="City*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={city}
                                            onChange={(e) =>
                                                setCity(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            State*
                                        </label>
                                        <select
                                            id="state"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={state}
                                            onChange={(e) =>
                                                setState(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select State
                                            </option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">
                                                California
                                            </option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">
                                                Connecticut
                                            </option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">
                                                District Of Columbia
                                            </option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">
                                                Louisiana
                                            </option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">
                                                Massachusetts
                                            </option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">
                                                Minnesota
                                            </option>
                                            <option value="MS">
                                                Mississippi
                                            </option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">
                                                New Hampshire
                                            </option>
                                            <option value="NJ">
                                                New Jersey
                                            </option>
                                            <option value="NM">
                                                New Mexico
                                            </option>
                                            <option value="NY">New York</option>
                                            <option value="NC">
                                                North Carolina
                                            </option>
                                            <option value="ND">
                                                North Dakota
                                            </option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">
                                                Pennsylvania
                                            </option>
                                            <option value="RI">
                                                Rhode Island
                                            </option>
                                            <option value="SC">
                                                South Carolina
                                            </option>
                                            <option value="SD">
                                                South Dakota
                                            </option>
                                            <option value="TN">
                                                Tennessee
                                            </option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">
                                                Washington
                                            </option>
                                            <option value="WV">
                                                West Virginia
                                            </option>
                                            <option value="WI">
                                                Wisconsin
                                            </option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            ZIP*
                                        </label>
                                        <input
                                            type="text"
                                            value={zip}
                                            onChange={handleZipChange}
                                            placeholder="ZIP*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                        />
                                        {validationMessages.zip && (
                                            <p className="text-red-500">
                                                {validationMessages.zip}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="flex justify-end space-x-2 mt-4">
                                    <button
                                        onClick={nextStep}
                                        disabled={isStep1Incomplete} // Disables button if any field is incomplete
                                        className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                            isStep1Incomplete
                                                ? "bg-gray-400 cursor-not-allowed"
                                                : "bg-sky-600 hover:bg-sky-700"
                                        }`}
                                    >
                                        Next Step
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case 3: // Adding a new third step
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-black">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "40%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                40% &nbsp;
                                            </span>
                                            (2/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 pb-6">
                                <h2 className="text-center text-2xl mb-2 font-extrabold">
                                    Initial Questions
                                </h2>
                                <form>
                                    <p>
                                        Go to Amazon.com and search the word
                                        <svg
                                            width="100%"
                                            height="90"
                                            viewBox="0 0 300 60" // This should match the original width and height you design for
                                            style={{
                                                maxWidth: "100%", // Ensures it doesn't overflow its container
                                                userSelect: "none",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            <text
                                                x="50%"
                                                y="50%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                                fill="red"
                                                fontSize="24" // You can adjust this based on your needs or dynamically
                                                fontWeight="bold" // Makes the text bold
                                            >
                                                {selectedProduct
                                                    ? selectedProduct.keyword
                                                    : ""}
                                            </text>
                                        </svg>
                                        & pick the product most appealing to
                                        you. (Note: As this is for market
                                        research, please do not select our
                                        brand).
                                    </p>
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        What is the ASIN of the product? (
                                        <a
                                            href="https://www.dropbox.com/s/il23ep0nont4kly/ASIN.mov?dl=0"
                                            target="_blank"
                                            className="underline text-blue-500 cursor-pointer"
                                        >
                                            Where do I find the ASIN number?
                                        </a>
                                        )
                                    </label>

                                    <input
                                        type="text"
                                        placeholder="Enter ASIN"
                                        value={asin1}
                                        onChange={(e) =>
                                            handleASINChange(
                                                setAsin1,
                                                e.target.value
                                            )
                                        }
                                        className={`block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2 ${
                                            validationErrors.asin
                                                ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                                                : ""
                                        }`}
                                    />
                                    {validationErrors.asin && (
                                        <p className="text-red-500">
                                            {validationErrors.asin}
                                        </p>
                                    )}
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        What made you pick this product from the
                                        search results?
                                    </label>
                                    <textarea
                                        className="block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2"
                                        placeholder="What made you pick this product from the search
                                results?"
                                        value={reason1}
                                        onChange={(e) =>
                                            handleInputChange(e, setReason1)
                                        }
                                    />
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        Looking to the product detail page, what
                                        do you like about the product page, what
                                        grabs your attention the most?
                                    </label>
                                    <textarea
                                        className="block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2"
                                        placeholder="What do you
                                like about the product page?"
                                        value={productDetails1}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                setProductDetails1
                                            )
                                        }
                                    />
                                    <div className="flex justify-end space-x-2 mt-4">
                                        <button
                                            onClick={nextStep}
                                            disabled={isStep2Incomplete} // Disables button if any field is incomplete
                                            className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                                isStep2Incomplete
                                                    ? "bg-gray-400 cursor-not-allowed"
                                                    : "bg-sky-600 hover:bg-sky-700"
                                            }`}
                                        >
                                            Next Step
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 4: // Adding a new third step
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-black">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "60%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                60% &nbsp;
                                            </span>
                                            (3/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 pb-6">
                                <h2 className="text-center text-2xl mb-2 font-extrabold">
                                    Initial Questions
                                </h2>
                                <form>
                                    <p>
                                        Now, return to Amazon and search
                                        <svg
                                            width="100%"
                                            height="90"
                                            viewBox="0 0 300 60" // This should match the original width and height you design for
                                            style={{
                                                maxWidth: "100%", // Ensures it doesn't overflow its container
                                                userSelect: "none",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            <text
                                                x="50%"
                                                y="50%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                                fill="red"
                                                fontSize="24" // You can adjust this based on your needs or dynamically
                                                fontWeight="bold" // Makes the text bold
                                            >
                                                {selectedProduct
                                                    ? selectedProduct.keyword
                                                    : ""}
                                            </text>
                                        </svg>
                                        again. Pick the next product that grabs
                                        your attention, then answer the
                                        questions below
                                    </p>
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        What is the ASIN of the product? (
                                        <a
                                            href="https://www.dropbox.com/s/il23ep0nont4kly/ASIN.mov?dl=0"
                                            target="_blank"
                                            className="underline text-blue-500 cursor-pointer"
                                        >
                                            Where do I find the ASIN number?
                                        </a>
                                        )
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter ASIN"
                                        value={asin2}
                                        onChange={(e) =>
                                            handleASINChange(
                                                setAsin2,
                                                e.target.value
                                            )
                                        }
                                        className={`block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2 ${
                                            validationErrors.asin
                                                ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                                                : ""
                                        }`}
                                    />
                                    {validationErrors.asin && (
                                        <p className="text-red-500">
                                            {validationErrors.asin}
                                        </p>
                                    )}
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        What made you pick this product from the
                                        search results?
                                    </label>
                                    <textarea
                                        className="block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2"
                                        placeholder="What made you pick this product from the search
                                results?"
                                        value={reason2}
                                        onChange={(e) =>
                                            handleInputChange(e, setReason2)
                                        }
                                    />
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        Looking to the product detail page, what
                                        do you like about the product page, what
                                        grabs your attention the most?
                                    </label>
                                    <textarea
                                        className="block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2"
                                        placeholder="What do you
                                like about the product page?"
                                        value={productDetails2}
                                        onChange={(e) =>
                                            handleInputChange(
                                                e,
                                                setProductDetails2
                                            )
                                        }
                                    />
                                    <div className="flex justify-end space-x-2 mt-4">
                                        <button
                                            onClick={nextStep}
                                            disabled={isStep3Incomplete} // Disables button if any field is incomplete
                                            className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                                isStep3Incomplete
                                                    ? "bg-gray-400 cursor-not-allowed"
                                                    : "bg-sky-600 hover:bg-sky-700"
                                            }`}
                                        >
                                            Next Step
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 5: // Adding a new third step
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-black">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "80%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                80% Completed &nbsp;
                                            </span>
                                            (Step 4/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 pb-6">
                                <h2 className="text-center text-2xl mb-2 font-extrabold">
                                    Final Questions
                                </h2>
                                <form>
                                    <p>
                                        Finally, please return to Amazon and
                                        search
                                        <svg
                                            width="100%"
                                            height="90"
                                            viewBox="0 0 300 60" // This should match the original width and height you design for
                                            style={{
                                                maxWidth: "100%", // Ensures it doesn't overflow its container
                                                userSelect: "none",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            <text
                                                x="50%"
                                                y="50%"
                                                dominantBaseline="middle"
                                                textAnchor="middle"
                                                fill="red"
                                                fontSize="24" // You can adjust this based on your needs or dynamically
                                                fontWeight="bold" // Makes the text bold
                                            >
                                                {selectedProduct
                                                    ? selectedProduct.keyword
                                                    : ""}
                                            </text>
                                        </svg>
                                        and find the following product
                                        {selectedProduct && (
                                            <div className="my-4 flex justify-center items-center">
                                                <img
                                                    src={`data:image/jpeg;base64,${selectedProduct.image}`}
                                                    alt={
                                                        selectedProduct.product
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        maxHeight: "200px",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </p>
                                    <hr className="pt-4" />
                                    <label className="block text-sm font-medium text-gray-900">
                                        Once you've located the image, please
                                        click on the listing and find the ASIN
                                        number (
                                        <a
                                            href="https://www.dropbox.com/s/il23ep0nont4kly/ASIN.mov?dl=0"
                                            target="_blank"
                                            className="underline text-blue-500 cursor-pointer"
                                        >
                                            Where do I find the ASIN number?
                                        </a>
                                        )
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter ASIN"
                                        value={asin3}
                                        onChange={(e) =>
                                            handleASINChange(
                                                setAsin3,
                                                e.target.value
                                            )
                                        }
                                        className={`block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2 ${
                                            validationErrors.asin
                                                ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                                                : ""
                                        }`}
                                    />
                                    {validationErrors.asin && (
                                        <p className="text-red-500">
                                            {validationErrors.asin}
                                        </p>
                                    )}

                                    <p className="mt-6 mb-2 font-medium">
                                        To help us better understand how we can
                                        improve, please provide your feedback
                                        below. Things to consider when providing
                                        feedback:
                                    </p>
                                    <ol className="list-decimal list-inside">
                                        <li>Packaging</li>
                                        <li>Selling Points</li>
                                        <li>Descriptions</li>
                                        <li>Imagery</li>
                                        <li>Reviews</li>
                                    </ol>
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        Please be honest with your feedback. The
                                        information you provide will be used to
                                        create a better product for you.
                                    </label>
                                    <textarea
                                        className="block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2"
                                        placeholder="Please provide your honest feedback"
                                        value={reason3}
                                        onChange={(e) =>
                                            handleInputChange(e, setReason3)
                                        }
                                    />
                                    <div className="flex justify-end space-x-2 mt-4">
                                        <button
                                            onClick={nextStep}
                                            disabled={isStep4Incomplete} // Disables button if any field is incomplete
                                            className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                                isStep4Incomplete
                                                    ? "bg-gray-400 cursor-not-allowed"
                                                    : "bg-sky-600 hover:bg-sky-700"
                                            }`}
                                        >
                                            Next Step
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 6: // Adding a new third step
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-black">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "99%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                99% Completed &nbsp;
                                            </span>
                                            (Step 5/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 pb-6">
                                <h2 className="text-center text-2xl mb-2 font-extrabold">
                                    Next Steps
                                </h2>
                                <form>
                                    <p>
                                        1.) On Amazon, add the product shown
                                        below to your cart
                                        {selectedProduct && (
                                            <div className="my-4 flex justify-center items-center">
                                                <img
                                                    src={`data:image/jpeg;base64,${selectedProduct.image}`}
                                                    alt={
                                                        selectedProduct.product
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        maxHeight: "200px",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </p>
                                    <p>
                                        2.) Checkout and complete the purchase
                                    </p>
                                    <p>
                                        3.) Enter the order ID below for us to
                                        validate it (
                                        <a
                                            href="/img/Order_Number_Instructions.pdf"
                                            className="underline text-blue-500"
                                            target="_blank"
                                        >
                                            Where do I find my order number?
                                        </a>
                                        )
                                    </p>
                                    <p>
                                        4.) Your order will be reviewed by a
                                        team member in the next 24-48 hours.
                                        Once validated, we will reach out to you
                                        to process your reimbursement
                                    </p>
                                    <p>
                                        5.) Enter your PayPal email below for
                                        your reimbursement
                                    </p>
                                    <hr className="my-4" />
                                    <label className="block text-sm font-medium text-gray-900">
                                        Please provide your Order Number
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Order Number"
                                        className={`block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2 ${
                                            validationErrors.ordernumber
                                                ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                                                : ""
                                        }`}
                                        value={ordernumber}
                                        onChange={handleOrderNumberChange}
                                    />
                                    {validationErrors.ordernumber && (
                                        <p className="text-red-500">
                                            {validationErrors.ordernumber}
                                        </p>
                                    )}
                                    <label className="block text-sm font-medium text-gray-900 mt-4">
                                        Please provide your PayPal Email Address
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="PayPal Email Address"
                                        className={`block input focus:ring-sky-500 focus:border-sky-500 bg-gray-100 p-3 rounded w-full mt-2 mb-2 ${
                                            validationErrors.paypal
                                                ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                                                : ""
                                        }`}
                                        value={paypal}
                                        onChange={handlePaypalEmailChange}
                                    />
                                    {validationErrors.paypal && (
                                        <p className="text-red-500">
                                            {validationErrors.paypal}
                                        </p>
                                    )}
                                    <div className="flex justify-end space-x-2 mt-4">
                                        <button
                                            onClick={handleSubmit}
                                            disabled={isStep5Incomplete} // Disables button if any field is incomplete
                                            className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                                isStep5Incomplete
                                                    ? "bg-gray-400 cursor-not-allowed"
                                                    : "bg-sky-600 hover:bg-sky-700"
                                            }`}
                                        >
                                            Finish
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 7: // Adding a new third step
            return (
                <div className="bg-gray-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-black">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "100%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                100% Completed &nbsp;
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 pb-3">
                                <h2 className="text-center text-2xl mb-2 font-extrabold">
                                    Thank you for your feedback!
                                </h2>
                                <p className="text-center mt-2 mb-4">
                                    Please keep an eye on your email for further
                                    information.
                                </p>
                                <p className="text-center mb-2">
                                    You may now close out of this window.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        default:
            return <div>Unknown step</div>;
    }
}
