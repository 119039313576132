import { useEffect } from "react";
import ReviewDetailPage from "../components/ReviewDetail";

export default function ReviewDetail() {
    useEffect(() => {
        document.title = "Ambassador Profile - gotomarket.tools";
    }, []);

    return (
        <>
            <ReviewDetailPage />
        </>
    );
}
