import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import {
    faStar as fasStar,
    faStarHalfAlt,
} from "@fortawesome/free-solid-svg-icons";

// Function to round to nearest 0.5
function roundHalf(num) {
    const whole = Math.floor(num);
    const fraction = num - whole;
    if (fraction < 0.25) {
        return whole;
    } else if (fraction < 0.75) {
        return whole + 0.5;
    } else {
        return whole + 1;
    }
}

const StarRating = ({
    rating,
    ratings_count,
    showRatings,
    showRatingsCount,
    showRatingsAverage,
}) => {
    if (rating === null) {
        return null;
    }

    const roundedRating = roundHalf(rating);

    return (
        <>
            {showRatings && (
                <>
                    <div className="flex items-center mb-4 text-md">
                        {showRatingsAverage && (
                            <>
                                <span className="relative top-px">
                                    {rating}&nbsp;&nbsp;
                                </span>
                            </>
                        )}
                        {[...Array(5).keys()].map((index) => {
                            const starNumber = index + 1;

                            if (starNumber <= roundedRating) {
                                return (
                                    <FontAwesomeIcon
                                        key={index}
                                        icon={fasStar}
                                        className="text-yellow-400 h-5 w-5 flex-shrink-0"
                                    />
                                );
                            } else if (starNumber - 0.5 === roundedRating) {
                                return (
                                    <FontAwesomeIcon
                                        key={index}
                                        icon={faStarHalfAlt}
                                        className="text-yellow-400 h-5 w-5 flex-shrink-0"
                                    />
                                );
                            } else {
                                return (
                                    <FontAwesomeIcon
                                        key={index}
                                        icon={farStar}
                                        className="text-gray-300 h-5 w-5 flex-shrink-0"
                                    />
                                );
                            }
                        })}
                        &nbsp;&nbsp;
                        {showRatingsCount && (
                            <>
                                <span className="relative top-px">
                                    {ratings_count.toLocaleString()}
                                    &nbsp;Ratings
                                </span>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default StarRating;
