import { useEffect } from "react";
import CustomerList from "../components/CustomerList";

export default function Customers() {
    useEffect(() => {
        document.title = "Customers - gotomarket.tools";
    }, []);
    return (
        <>
            <CustomerList />
        </>
    );
}
