import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { EnvelopeOpenIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function CouponTemplate({
    asin,
    headline,
    description,
    title,
    couponCodes,
    landingPageId,
    images,
}) {
    // Add a state for showModal
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [isCodesAvailable, setIsCodesAvailable] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const location = useLocation();
    const isEditPage = location.pathname.includes("/edit/");

    useEffect(() => {
        document.title = `${title}`;
    }, [title]);

    useEffect(() => {
        // if there is a string of coupon codes, split it into an array of codes
        let codesArray =
            couponCodes && couponCodes.length > 0
                ? couponCodes[0].split(",")
                : [];

        // trim the resulting strings and filter out any empty strings
        codesArray = codesArray
            .map((code) => code.trim())
            .filter((code) => code !== "");

        // if the length of the array of coupon codes is greater than 0, there are still codes left
        setIsCodesAvailable(codesArray.length > 0);
    }, [couponCodes]);

    if (!couponCodes) {
        setIsCodesAvailable(true); // or a loading spinner, or some fallback component
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const response = await fetch(
            "https://api.gotomarket.tools/api/coupon/send",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    fname: event.target.fname.value,
                    lname: event.target.lname.value,
                    email: event.target.email.value,
                    phone: event.target.phone.value,
                    asin: asin,
                    landingPageId: landingPageId,
                }).toString(),
            }
        );

        const responseData = await response.json(); // Extract the JSON from the response

        if (response.ok) {
            setLoading(false);
            setCompleted(true);
            setErrorMessage(null); // If the request was successful, reset the error message
        } else {
            // Handle the error
            setLoading(false);
            // If there was an error, save it in the state
            setErrorMessage(responseData.error);
            if (responseData.error === "Redemption limit hit") {
                // If redemption limit hit, consider it as completed
                setCompleted(true);
            }
        }
    };

    return (
        <div>
            <div
                className="bg-[DDDBDC] bg-cover text-purple h-screen flex justify-center items-center font-sans"
                style={{ fontFamily: "DM Sans, sans-serif" }}
            >
                <div className="p-6 w-96">
                    {/* Product Image */}
                    <div className="flex justify-center items-center mb-4">
                        {images.map((image) => (
                            <div className="w-48 h-48 p-2 rounded-md bg-white border-2 flex justify-center items-center overflow-hidden">
                                <img
                                    src={`data:image/jpeg;base64,${image}`}
                                    className="max-w-full max-h-full object-contain"
                                />
                            </div>
                        ))}
                    </div>

                    {/* Product Name */}
                    <h2 className="text-center text-2xl mb-2 font-extrabold">
                        {headline}
                    </h2>

                    {/* Product Description */}
                    <p className="text-center">{description}</p>

                    {/* "Claim Coupon" Button */}
                    <button
                        className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-orange-500 mt-6 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                            !isCodesAvailable
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        }`}
                        onClick={() => setShowModal(true)}
                        disabled={!isCodesAvailable}
                        style={{
                            backgroundColor: !isCodesAvailable
                                ? "rgb(128, 128, 128)"
                                : "rgb(6, 20, 146)",
                        }}
                    >
                        {!isCodesAvailable
                            ? "No Coupons Remaining"
                            : "Claim Coupon"}
                    </button>

                    {isEditPage
                        ? null
                        : showModal && (
                              <div
                                  className="fixed inset-0 z-10 overflow-y-auto"
                                  aria-labelledby="modal-title"
                                  role="dialog"
                                  aria-modal="true"
                                  onClick={() => setShowModal(false)}
                              >
                                  {/* Centering Flex Container */}
                                  <div className="flex items-center justify-center min-h-screen">
                                      {/* Modal Overlay */}
                                      <div
                                          className="fixed inset-0 bg-gray-500 bg-opacity-75 pt-4 px-4 pb-20 transition-opacity"
                                          aria-hidden="true"
                                      ></div>

                                      {/* Modal Box */}
                                      <div
                                          className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
                                          onClick={(e) => e.stopPropagation()}
                                      >
                                          <div>
                                              <div className="mt-3 flex flex-col items-center sm:mt-5">
                                                  <button
                                                      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                                      onClick={() =>
                                                          setShowModal(false)
                                                      }
                                                  >
                                                      <XMarkIcon
                                                          className="h-5 w-5"
                                                          aria-hidden="true"
                                                      />
                                                  </button>
                                                  <EnvelopeOpenIcon className="w-12 mb-4" />
                                                  <h3
                                                      className="text-3xl leading-6 font-bold text-purple"
                                                      id="modal-title"
                                                  >
                                                      Claim Your Coupon
                                                  </h3>
                                                  <p className="mt-3 text-center">
                                                      Enter your details below
                                                      to claim your coupon.
                                                  </p>
                                                  <form
                                                      className="w-full"
                                                      onSubmit={handleSubmit}
                                                  >
                                                      <div className="mt-6">
                                                          <input
                                                              id="fname"
                                                              name="fname"
                                                              type="text"
                                                              autoComplete="fname"
                                                              placeholder="First Name"
                                                              required
                                                              className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                                          />
                                                      </div>
                                                      <div className="mt-3">
                                                          <input
                                                              id="lname"
                                                              name="lname"
                                                              type="text"
                                                              autoComplete="lname"
                                                              placeholder="Last Name"
                                                              required
                                                              className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                                          />
                                                      </div>
                                                      <div className="mt-3">
                                                          <input
                                                              id="email"
                                                              name="email"
                                                              type="email"
                                                              autoComplete="email"
                                                              placeholder="Email Address"
                                                              required
                                                              className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                                          />
                                                      </div>
                                                      <div className="mt-3">
                                                          <input
                                                              id="phone"
                                                              name="phone"
                                                              type="text"
                                                              autoComplete="phone"
                                                              placeholder="Cell Phone Number"
                                                              required
                                                              className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                                          />
                                                      </div>

                                                      <button
                                                          type="submit"
                                                          disabled={
                                                              loading ||
                                                              completed ||
                                                              !isCodesAvailable
                                                          }
                                                          className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-orange-500 mt-6 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                                              loading ||
                                                              completed ||
                                                              !isCodesAvailable
                                                                  ? "cursor-not-allowed"
                                                                  : "cursor-pointer"
                                                          }`}
                                                          style={{
                                                              backgroundColor:
                                                                  !isCodesAvailable
                                                                      ? "rgb(128, 128, 128)"
                                                                      : "rgb(6, 20, 146)",
                                                          }}
                                                      >
                                                          {loading
                                                              ? "Loading..."
                                                              : completed
                                                              ? errorMessage ===
                                                                "Redemption limit hit"
                                                                  ? "Redemption Limit Reached"
                                                                  : "Email Sent!"
                                                              : !isCodesAvailable
                                                              ? "No Coupons Remaining"
                                                              : "Claim Coupon"}
                                                      </button>
                                                  </form>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          )}
                </div>
            </div>
        </div>
    );
}
