import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

export default function EditSurveyFormFields() {
    const [surveyName, setSurveyName] = useState("");
    const [products, setProducts] = useState([]);
    const [minStarRating, setMinStarRating] = useState("");
    const [ratingReviewCollection, setRatingReviewCollection] = useState("");
    const [leaveReviewOnMarketplace, setLeaveReviewOnMarketplace] =
        useState("");
    const [trackingPixels, setTrackingPixels] = useState({
        headAfter: "",
        headBefore: "",
        bodyAfter: "",
        bodyBefore: "",
    });
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const { id } = useParams();
    const navigate = useNavigate();

    const handleMinStarRatingChange = (e) => {
        const value = Math.max(1, Math.min(5, Number(e.target.value)));
        setMinStarRating(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [surveyResponse, productsResponse] = await Promise.all([
                    axios.get(
                        `https://api.gotomarket.tools/api/surveys/${id}`,
                        {
                            headers: { Authorization: `Bearer ${userToken}` },
                        }
                    ),
                    axios.get(`https://api.gotomarket.tools/api/products/get`, {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }),
                ]);

                if (surveyResponse.data && productsResponse.data) {
                    setSurveyName(surveyResponse.data.surveyName || "");
                    setMinStarRating(surveyResponse.data.minStarRating || "");
                    setRatingReviewCollection(
                        surveyResponse.data.ratingReviewCollection || ""
                    );
                    setLeaveReviewOnMarketplace(
                        surveyResponse.data.leaveReviewOnMarketplace || ""
                    );
                    setTrackingPixels(
                        surveyResponse.data.trackingPixels || {
                            headAfter: "",
                            headBefore: "",
                            bodyAfter: "",
                            bodyBefore: "",
                        }
                    );

                    const eligibleProductIds = new Set(
                        surveyResponse.data.products.map((productId) =>
                            productId.toString()
                        )
                    );
                    const updatedProducts = productsResponse.data.products.map(
                        (product) => ({
                            ...product,
                            selected: eligibleProductIds.has(
                                product._id.toString()
                            ),
                        })
                    );
                    setProducts(updatedProducts);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [id, userToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.put(
                `https://api.gotomarket.tools/api/surveys/${id}`,
                {
                    account: user.account._id,
                    surveyName,
                    products: products
                        .filter((p) => p.selected)
                        .map((p) => p._id),
                    minStarRating,
                    ratingReviewCollection,
                    leaveReviewOnMarketplace,
                    trackingPixels,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            navigate(-1);
        } catch (error) {
            console.error(
                "Error updating survey:",
                error.response?.data || error.message
            );
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Survey Name
                                                <div className="inline text-red-500">
                                                    *
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Provide a name for this
                                                    survey. The survey name
                                                    serves two purposes. One as
                                                    an identifier, and two as
                                                    the <code>title</code> value
                                                    on the survey
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Survey Name
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={surveyName}
                                                    onChange={(e) =>
                                                        setSurveyName(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Product Eligibility
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    What product(s) are eligible
                                                    for this survey? By making a
                                                    product eligible for this
                                                    survey, you are enabling
                                                    anyone who purchased this
                                                    product to be allowed into
                                                    this survey's review flow
                                                    (pending a valid order
                                                    number)
                                                </label>
                                                {products.length > 0 ? (
                                                    <div className="space-y-2 mt-1 mb-2">
                                                        {products.map(
                                                            (product) => (
                                                                <div
                                                                    key={
                                                                        product._id
                                                                    }
                                                                    className="flex items-center"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={
                                                                            product._id
                                                                        }
                                                                        checked={
                                                                            product.selected ||
                                                                            false
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const checked =
                                                                                e
                                                                                    .target
                                                                                    .checked;
                                                                            setProducts(
                                                                                (
                                                                                    prevProducts
                                                                                ) =>
                                                                                    prevProducts.map(
                                                                                        (
                                                                                            p
                                                                                        ) =>
                                                                                            p._id ===
                                                                                            product._id
                                                                                                ? {
                                                                                                      ...p,
                                                                                                      selected:
                                                                                                          checked,
                                                                                                  }
                                                                                                : p
                                                                                    )
                                                                            );
                                                                        }}
                                                                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                                                                    />
                                                                    <label
                                                                        htmlFor={
                                                                            product._id
                                                                        }
                                                                        className="ml-2 block text-sm text-gray-900"
                                                                    >
                                                                        {
                                                                            product.productName
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md">
                                                        No products available.
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Minimum Star Rating
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Set a{" "}
                                                    <span className="font-bold italic">
                                                        minimum
                                                    </span>{" "}
                                                    star rating. By setting a
                                                    minimum star rating, only
                                                    users who rate at or above
                                                    your minimum star rating
                                                    will be shown a link to
                                                    share their rating or review
                                                    on the marketplace they
                                                    purchased your product on.
                                                    For example, if you set your
                                                    minimum star rating to 4,
                                                    this means that only
                                                    customers who rate your
                                                    product a 4 or a 5 will be
                                                    shown a link to post their
                                                    rating and review to the
                                                    marketplace they purchased
                                                    your product on.
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Minimum Star Rating
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="number"
                                                    value={minStarRating}
                                                    min="1"
                                                    max="5"
                                                    placeholder="5"
                                                    onChange={
                                                        handleMinStarRatingChange
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Rating/Review Collection
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Select whether or not you
                                                    would like to collect
                                                    ratings and/or reviews and,
                                                    if you are collecting
                                                    ratings and/or reviews,
                                                    whether or not you would
                                                    like to require your
                                                    customer to provide their
                                                    rating and/or review before
                                                    being allowed to proceed
                                                    through the survey.
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Rating/Review Collection
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <select
                                                    value={
                                                        ratingReviewCollection
                                                    }
                                                    onChange={(e) =>
                                                        setRatingReviewCollection(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                >
                                                    <option value="">
                                                        Select your option
                                                    </option>
                                                    <option value="Require rating and review">
                                                        Require rating and
                                                        review
                                                    </option>
                                                    <option value="Require rating">
                                                        Require rating
                                                    </option>
                                                    <option value="Do not require either">
                                                        Do not require either
                                                    </option>
                                                    <option value="Do not collect ratings or reviews">
                                                        Do not collect ratings
                                                        or reviews
                                                    </option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Leave Review on Marketplace?
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Select whether or not you
                                                    would like to collect
                                                    ratings and/or reviews and,
                                                    if you are collecting
                                                    ratings and/or reviews,
                                                    whether or not you would
                                                    like to require your
                                                    customer to provide their
                                                    rating and/or review before
                                                    being allowed to proceed
                                                    through the survey.
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Leave Review on Marketplace?
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <select
                                                    value={
                                                        leaveReviewOnMarketplace
                                                    }
                                                    onChange={(e) =>
                                                        setLeaveReviewOnMarketplace(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                >
                                                    <option value="">
                                                        Select your option
                                                    </option>
                                                    <option value="Require customer to leave review on marketplace">
                                                        Require customer to
                                                        leave review on
                                                        marketplace
                                                    </option>
                                                    <option value="Leaving review on marketplace optional">
                                                        Leaving review on
                                                        marketplace optional
                                                    </option>
                                                    <option value="Do not show marketplace review prompt at all">
                                                        Do not show marketplace
                                                        review prompt at all
                                                    </option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Install Tracking Pixels
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Install analytic and
                                                    advertising tracking pixels
                                                    where directed by the
                                                    issuing platform with the
                                                    below text areas. Content in
                                                    the text areas below will be
                                                    injected where stated when
                                                    your survey is created, i.e.
                                                    "Immediately After
                                                    &#x3c;head&#x3e;" will
                                                    inject contents of text area
                                                    immediately after the
                                                    opening{" "}
                                                    <code>
                                                        &#x3c;head&#x3e;
                                                    </code>{" "}
                                                    tag on your survey.
                                                </label>
                                                <div className="mb-2">
                                                    <label className="block text-xs font-medium text-gray-900">
                                                        Immediately After
                                                        &lt;head&gt;
                                                    </label>
                                                    <textarea
                                                        value={
                                                            trackingPixels.headAfter
                                                        }
                                                        onChange={(e) =>
                                                            setTrackingPixels({
                                                                ...trackingPixels,
                                                                headAfter:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                        className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label className="block text-xs font-medium text-gray-900">
                                                        Immediately Before
                                                        &lt;/head&gt;
                                                    </label>
                                                    <textarea
                                                        value={
                                                            trackingPixels.headBefore
                                                        }
                                                        onChange={(e) =>
                                                            setTrackingPixels({
                                                                ...trackingPixels,
                                                                headBefore:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                        className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label className="block text-xs font-medium text-gray-900">
                                                        Immediately After
                                                        &lt;body&gt;
                                                    </label>
                                                    <textarea
                                                        value={
                                                            trackingPixels.bodyAfter
                                                        }
                                                        onChange={(e) =>
                                                            setTrackingPixels({
                                                                ...trackingPixels,
                                                                bodyAfter:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                        className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label className="block text-xs font-medium text-gray-900">
                                                        Immediately Before
                                                        &lt;/body&gt;
                                                    </label>
                                                    <textarea
                                                        value={
                                                            trackingPixels.bodyBefore
                                                        }
                                                        onChange={(e) =>
                                                            setTrackingPixels({
                                                                ...trackingPixels,
                                                                bodyBefore:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                        className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="flex w-full m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Save
                </button>
            </form>
        </div>
    );
}
