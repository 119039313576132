import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function AmbassadorProductFormFields() {
    const [productName, setProductName] = useState("");
    const [keyword, setKeyword] = useState("");
    const [image, setImage] = useState(""); // Store base64 string
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const userToken = user?.token;

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Optionally, remove the prefix for the base64 string
                const base64String = reader.result.replace(/^data:(.*,)?/, "");
                setImage(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(
                "https://api.gotomarket.tools/api/ambassadorproduct/create",
                {
                    account: user.account._id,
                    productName: productName,
                    keyword: keyword,
                    image: image,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("Product created successfully:", response.data);
            navigate(-1);
        } catch (error) {
            console.error(
                "Error creating product: ",
                error.response?.data || error.message
            );
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <label className="block text-sm font-medium text-gray-900">
                    Product Name
                </label>
                <input
                    type="text"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                    required
                />

                <label className="block text-sm font-medium text-gray-900">
                    Keyword
                </label>
                <input
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                    required
                />

                <label className="block text-sm font-medium text-gray-900">
                    Image
                </label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                />

                <button
                    type="submit"
                    className="flex w-full m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Create
                </button>
            </form>
        </div>
    );
}
