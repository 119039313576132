import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import Spinner from "../components/Templates/Spinner";
import LandingTemplate from "../components/Templates/LandingTemplate";
import CouponTemplate from "../components/Templates/CouponTemplate";

const fetchLandingPage = async (pageId) => {
    const response = await fetch(
        `https://api.gotomarket.tools/api/landingpage/${pageId}`
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    return response.json();
};

const fetchRedemptionsByLandingPage = async (landingPageId) => {
    const response = await fetch(
        `https://api.gotomarket.tools/api/redemptions/${landingPageId}`
    );

    if (!response.ok) {
        throw new Error("Failed to fetch redemptions");
    }

    const { redemptionsCount } = await response.json();

    return redemptionsCount;
};

function LandingPages() {
    const { pageId } = useParams();
    const location = useLocation();

    // Extract the "public" query parameter from the URL
    const urlParams = new URLSearchParams(location.search);
    const isPublic = urlParams.get("public") === "true";

    const logLandingPageView = async () => {
        try {
            await fetch(
                `https://api.gotomarket.tools/api/analytics/register-view/landingpage/${pageId}`,
                {
                    method: "GET",
                }
            );
        } catch (error) {
            console.error("Error logging landing page view:", error);
        }
    };

    useEffect(() => {
        document.title = "Claim This Exclusive Offer!";

        logLandingPageView();
    }, []);

    const { data: redemptionCount } = useQuery(["redemptions", pageId], () =>
        fetchRedemptionsByLandingPage(pageId)
    );

    const { data, status, error } = useQuery(["landingPage", pageId], () =>
        fetchLandingPage(pageId)
    );

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return <p>Error: {error.message}</p>;
    }

    const landingPage = data;

    if (landingPage.layout === "Detailed") {
        return (
            <div>
                <LandingTemplate
                    uuid={data.uuid}
                    landingPageId={data._id}
                    title={data.title}
                    headline={data.headline}
                    asin={data.asin}
                    couponCodes={data.couponCodes}
                    description={data.description}
                    bullet_descriptions={data.bullet_descriptions}
                    images={data.images}
                    rating={data.rating}
                    ratings_count={data.ratings_count}
                    price={data.price}
                    salePrice={data.salePrice}
                    reviews={data.reviews}
                    countdownConfig={data.countdownConfig}
                    showCountdown={data.showCountdown}
                    showNavigation={data.showNavigation}
                    showLogo={data.showLogo}
                    showTrending={data.showTrending}
                    logoFile={data.logoFile}
                    showRatings={data.showRatings}
                    showRatingsCount={data.showRatingsCount}
                    showRatingsAverage={data.showRatingsAverage}
                    showDiscountFlag={data.showDiscountFlag}
                    showAmazonCouponCount={data.showAmazonCouponCount}
                    trackingPixelEndBody={data.trackingPixelEndBody}
                    trackingPixelHead={data.trackingPixelHead}
                    trackingPixelStartBody={data.trackingPixelStartBody}
                    redemptionCount={redemptionCount}
                    isPublic={isPublic}
                />
            </div>
        );
    } else {
        return (
            <div>
                <CouponTemplate
                    uuid={data.uuid}
                    landingPageId={data._id}
                    title={data.title}
                    headline={data.headline}
                    asin={data.asin}
                    couponCodes={data.couponCodes}
                    description={data.description}
                    countdownConfig={data.countdownConfig}
                    images={data.images}
                    showAmazonCouponCount={data.showAmazonCouponCount}
                    trackingPixelEndBody={data.trackingPixelEndBody}
                    trackingPixelHead={data.trackingPixelHead}
                    trackingPixelStartBody={data.trackingPixelStartBody}
                    redemptionCount={redemptionCount}
                    isPublic={isPublic}
                />
            </div>
        );
    }
}

export default LandingPages;
