import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import EditGiveawayFormFields from "../components/EditGiveawayFormFields";

export default function EditGiveaway() {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-gray-100">
                <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <div className="flex justify-start">
                        <button
                            className="flex items-center space-x-2 text-sky-600"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(-1); // Use navigate(-1) to go back
                            }}
                        >
                            <ArrowLeftIcon className="h-5 w-5" />
                            <span>Back</span>
                        </button>
                    </div>
                </div>
                <main className="mx-auto max-w-2xl px-4 pb-12 pt-10 lg:pb-16">
                    <div className="space-y-6">
                        <div className="flex justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="mt-3 flex flex-col items-center sm:mt-5">
                                <h3
                                    className="text-3xl leading-6 font-bold text-gray-900"
                                    id="modal-title"
                                >
                                    Edit Giveaway
                                </h3>

                                <p className="mt-3 text-center">
                                    A Giveaway is what you will give your
                                    customer for providing their feedback on
                                    your product
                                </p>
                            </div>
                        </div>
                        <EditGiveawayFormFields />
                    </div>
                </main>
            </div>
        </>
    );
}
