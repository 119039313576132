import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import {
    Bars3Icon,
    XMarkIcon,
    ArrowLeftIcon,
} from "@heroicons/react/24/outline";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import CouponTemplate from "./Templates/CouponTemplate";

export default function NavMinimal({
    landingPageData,
    setLandingPageData,
    updateLandingPageSettings,
}) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [openIndices, setOpenIndices] = useState([
        [false], // for the first main accordion
        [false], // for the second main accordion
        [false], // for the second main accordion
    ]);
    const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
    const [isHeadlineEditable, setIsHeadlineEditable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleHeadlineClick = () => {
        setIsHeadlineEditable(true);
    };

    const handleHeadlineChange = (e) => {
        const newHeadline = e.target.value;
        setLandingPageData((prevData) => ({
            ...prevData,
            headline: newHeadline,
        }));
    };

    const handleDescriptionClick = () => {
        setIsDescriptionEditable(true);
    };

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        setLandingPageData((prevData) => ({
            ...prevData,
            description: newDescription,
        }));
    };

    const navigate = useNavigate();

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(" ");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await updateLandingPageSettings();
        setIsLoading(false);
    };

    const toggleItem = (index, subIndex) => {
        const newOpenIndices = [...openIndices];
        if (typeof subIndex !== "undefined") {
            newOpenIndices[index][1][subIndex] =
                !newOpenIndices[index][1][subIndex];
        } else {
            newOpenIndices[index][0] = !newOpenIndices[index][0];
        }
        setOpenIndices(newOpenIndices);
    };

    const handleAddImage = (event) => {
        const file = event.target.files[0]; // Get the first file, assuming you only need one image
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                let imageData = reader.result;

                const jpegPrefix = "data:image/jpeg;base64,";
                const pngPrefix = "data:image/png;base64,";

                if (imageData.startsWith(jpegPrefix)) {
                    imageData = imageData.slice(jpegPrefix.length);
                } else if (imageData.startsWith(pngPrefix)) {
                    imageData = imageData.slice(pngPrefix.length);
                }

                // Instead of appending to the images array, we replace it with a new array containing just the new image
                setLandingPageData((prevData) => ({
                    ...prevData,
                    images: [imageData], // Set the images array to only contain the new image
                }));
            };
            reader.readAsDataURL(file);
        }

        // Reset the file input value
        event.target.value = null;
    };

    return (
        <>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-sky-500 bg-sky-600 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <div className="flex justify-start">
                    <button
                        className="flex items-center space-x-2 text-white"
                        onClick={(event) => {
                            event.preventDefault();
                            navigate(-1); // Use navigate(-1) to go back
                        }}
                    >
                        <ArrowLeftIcon className="h-5 w-5" />
                        <span>Cancel</span>
                    </button>
                </div>
                <div className="ml-auto flex items-center gap-x-4">
                    <a
                        href={`/landingpage/${landingPageData._id}`}
                        target="_blank"
                        className="flex items-center justify-center rounded-md border bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 border border-orange-700"
                    >
                        View Landing Page
                    </a>
                    <form onSubmit={handleSubmit}>
                        <button
                            type="submit"
                            className="flex items-center justify-center rounded-md border bg-lime-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-lime-700 border border-lime-700"
                        >
                            {isLoading ? "Loading..." : "Save and Continue"}
                        </button>
                    </form>
                </div>
            </div>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50 lg:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button
                                                type="button"
                                                className="-m-2.5 p-2.5"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XMarkIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src="https://tailwindui.com/img/logos/mark.svg?color=sky&shade=600"
                                                alt="Your Company"
                                            />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden mt-16 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white">
                        <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col">
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(1); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[1][0] // Update here
                                                ? "bg-gray-50 text-sky-600"
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold "
                                        )}
                                    >
                                        <div className="flex items-center">
                                            <div>Image</div>
                                        </div>
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[1][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    {openIndices[1][0] && (
                                        <ul
                                            role="list"
                                            className="px-2 border-t border-gray-200"
                                        >
                                            <li className="p-2 mt-2 mb-4 overflow-hidden">
                                                <label
                                                    htmlFor="addImage"
                                                    className="rounded-md bg-sky-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                                >
                                                    Upload new image
                                                </label>
                                                <input
                                                    type="file"
                                                    id="addImage"
                                                    accept=".png, .jpg, .jpeg"
                                                    multiple
                                                    onChange={handleAddImage}
                                                    style={{ display: "none" }}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(2); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[2][0] // Update here
                                                ? "bg-gray-50 text-sky-600"
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold "
                                        )}
                                    >
                                        Product
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[2][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    <div className="w-full">
                                        {openIndices[2][0] && (
                                            <ul role="list">
                                                <li className="border-y border-gray-200 px-2">
                                                    <span className="text-gray-700 w-full group flex gap-x-3 p-4 text-sm leading-6 font-semibold">
                                                        <label>
                                                            Headline
                                                            <input
                                                                type="text"
                                                                onClick={
                                                                    handleHeadlineClick
                                                                }
                                                                onChange={
                                                                    handleHeadlineChange
                                                                }
                                                                value={
                                                                    landingPageData.headline
                                                                }
                                                                readOnly={
                                                                    !isHeadlineEditable
                                                                }
                                                                className="w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="border-b border-gray-200 px-2">
                                                    <span className="text-gray-700 w-full group flex gap-x-3 p-4 text-sm leading-6 font-semibold">
                                                        <label>
                                                            Description&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <input
                                                                type="text"
                                                                onClick={
                                                                    handleDescriptionClick
                                                                }
                                                                onChange={
                                                                    handleDescriptionChange
                                                                }
                                                                value={
                                                                    landingPageData.description
                                                                }
                                                                readOnly={
                                                                    !isDescriptionEditable
                                                                }
                                                                className="w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
                        Edit Menu
                    </div>
                </div>

                <main className="lg:pl-72">
                    <CouponTemplate
                        {...landingPageData}
                        setLandingPageData={setLandingPageData}
                        selectedImageIndex={selectedImageIndex}
                        setSelectedImageIndex={setSelectedImageIndex}
                    />
                </main>
            </div>
        </>
    );
}
