import { useEffect } from "react";
import Stats from "../components/Stats";

export default function Home() {
    useEffect(() => {
        document.title = "Dashboard - gotomarket.tools";
    }, []);

    return <Stats />;
}
