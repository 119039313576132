import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";

const withoutAuth = (Component, redirectTo = "/") => {
    return (props) => {
        const { user, loading } = useAuth();

        if (loading) {
            return null;
        }

        if (user) {
            return <Navigate to={redirectTo} />;
        }

        return <Component {...props} />;
    };
};

export default withoutAuth;
