import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export default function AccountEditComponent({
    firstName: initialFname,
    lastName: initialLname,
    token,
}) {
    const [firstName, setFirstName] = useState(initialFname);
    const [lastName, setLastName] = useState(initialLname);
    const navigate = useNavigate();
    const { dispatch } = useContext(AuthContext); // Get the dispatch method from the AuthContext

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(
                "https://api.gotomarket.tools/api/account/edit",
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        firstName,
                        lastName,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to update account");
            }
            const updatedUser = await response.json();
            dispatch({
                type: "UPDATE_USER",
                payload: {
                    firstName: updatedUser.firstName,
                    lastName: updatedUser.lastName,
                },
            });
            navigate("/account");
        } catch (error) {}
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-gray-100">
                <div className="mx-auto max-w-7xl">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">
                                    Edit Account
                                </h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    Edit Your Account Information
                                </p>
                            </div>
                            <div className="mt-4 ml-4 sm:mt-0 sm:flex-none">
                                <Link
                                    to="/account"
                                    type="button"
                                    className="flex items-center gap-x-1.5 rounded-md bg-gray-300 px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                                >
                                    Cancel
                                </Link>
                            </div>
                            <div className="mt-4 ml-4 sm:mt-0 sm:flex-none">
                                <button
                                    type="submit"
                                    className="flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                        <div className="mt-4 flow-root">
                            <div className="sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"></div>
                                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-8">
                                        <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg p-4">
                                            <div className="col-span-1">
                                                <label className="block text-md font-medium text-black">
                                                    First Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    value={firstName}
                                                    onChange={(e) =>
                                                        setFirstName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg p-4">
                                            <div className="col-span-1">
                                                <label className="block text-md font-medium text-black">
                                                    Last Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    value={lastName}
                                                    onChange={(e) =>
                                                        setLastName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
