import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export default function CreateLink() {
    const [error, setError] = useState("");
    const [url, setUrl] = useState("");
    const [openHead, setopenHead] = useState("");
    const [closeHead, setcloseHead] = useState("");
    const [openBody, setopenBody] = useState("");
    const [closeBody, setcloseBody] = useState("");
    const [isTrackingEnabled, setIsTrackingEnabled] = useState(false);
    const [shortUrl, setShortUrl] = useState("");
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const userToken = user?.token;

    const isValidUrl = (url) => {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!urlPattern.test(url);
    };

    const handleBasicUrlSubmit = async (event) => {
        event.preventDefault();
        if (!isValidUrl(url)) {
            setError("Please enter a valid URL.");
            return;
        }

        setError("");

        try {
            const response = await axios.post(
                "https://api.gotomarket.tools/api/link/createBasicUrl",
                {
                    url: url,
                    account: user.account._id,
                    isTrackingEnabled: isTrackingEnabled,
                    openHead: openHead,
                    closeHead: closeHead,
                    openBody: openBody,
                    closeBody: closeBody,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setShortUrl(response.data.shortUrl);
        } catch (error) {
            console.error("Error creating link: ", error);
        }
    };

    const handleCheckboxChange = () => {
        setIsTrackingEnabled((prev) => !prev);
    };

    return (
        <>
            <div className="bg-gray-100">
                <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <div className="flex justify-start">
                        <button
                            className="flex items-center space-x-2 text-sky-600"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(-1); // Use navigate(-1) to go back
                            }}
                        >
                            <ArrowLeftIcon className="h-5 w-5" />
                            <span>Back</span>
                        </button>
                    </div>
                </div>
                <main className="mx-auto max-w-2xl px-4 pb-12 pt-10 lg:pb-16">
                    <div className="space-y-6">
                        <div className="flex justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="mt-3 flex flex-col items-center sm:mt-5">
                                <h3
                                    className="text-3xl leading-6 font-bold text-gray-900"
                                    id="modal-title"
                                >
                                    Create a Short Link
                                </h3>

                                <p className="mt-3 text-center">
                                    Enter a link below to generate a short link
                                </p>
                            </div>
                        </div>
                        <div className="w-full">
                            <form onSubmit={handleBasicUrlSubmit}>
                                <input
                                    type="text"
                                    value={url}
                                    placeholder="Enter your URL"
                                    className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mb-2"
                                    onChange={(e) => setUrl(e.target.value)}
                                    required
                                />
                                <div className="mb-6">
                                    <input
                                        type="checkbox"
                                        id="addTrackingPixels"
                                        checked={isTrackingEnabled}
                                        onChange={handleCheckboxChange}
                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                    />
                                    <label
                                        htmlFor="addTrackingPixels"
                                        className="text-sm text-gray-900 ml-2"
                                    >
                                        Add Tracking Pixels?
                                    </label>
                                    {isTrackingEnabled && (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 text-sm">
                                            <div className="">
                                                <h3 className="text-md font-bold underline">
                                                    Tracking Pixel #1
                                                </h3>
                                                <p className="mb-2">
                                                    The contents of this text
                                                    box will be injected
                                                    immediately after the
                                                    opening{" "}
                                                    <strong>
                                                        &lt;head&gt;
                                                    </strong>{" "}
                                                    of the document
                                                </p>
                                                <textarea
                                                    value={openHead}
                                                    onChange={(e) =>
                                                        setopenHead(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full h-32 p-2 border border-gray-300 rounded"
                                                />
                                            </div>
                                            <div>
                                                <h3 className="text-md font-bold underline">
                                                    Tracking Pixel #2
                                                </h3>
                                                <p className="mb-2">
                                                    The contents of this text
                                                    box will be injected
                                                    immediately before the
                                                    closing{" "}
                                                    <strong>
                                                        &lt;/head&gt;
                                                    </strong>{" "}
                                                    of the document
                                                </p>
                                                <textarea
                                                    value={closeHead}
                                                    onChange={(e) =>
                                                        setcloseHead(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full h-32 p-2 border border-gray-300 rounded"
                                                />
                                            </div>
                                            <div>
                                                <h3 className="text-md font-bold underline">
                                                    Tracking Pixel #3
                                                </h3>
                                                <p className="mb-2">
                                                    The contents of this text
                                                    box will be injected
                                                    immediately after the
                                                    opening{" "}
                                                    <strong>
                                                        &lt;body&gt;
                                                    </strong>{" "}
                                                    of the document
                                                </p>
                                                <textarea
                                                    value={openBody}
                                                    onChange={(e) =>
                                                        setopenBody(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full h-32 p-2 border border-gray-300 rounded"
                                                />
                                            </div>
                                            <div>
                                                <h3 className="text-md font-bold underline">
                                                    Tracking Pixel #4
                                                </h3>
                                                <p className="mb-2">
                                                    The contents of this text
                                                    box will be injected
                                                    immediately before the
                                                    closing{" "}
                                                    <strong>
                                                        &lt;/body&gt;
                                                    </strong>{" "}
                                                    of the document
                                                </p>
                                                <textarea
                                                    value={closeBody}
                                                    onChange={(e) =>
                                                        setcloseBody(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full h-32 p-2 border border-gray-300 rounded"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {error && (
                                    <div style={{ color: "red" }}>{error}</div>
                                )}
                                <button
                                    type="submit"
                                    className="flex m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                                >
                                    Create Short Link
                                </button>
                                {shortUrl && (
                                    <div className="text-center mt-8">
                                        <p>Your shortened URL is: </p>
                                        <a
                                            href={`https://g2m.app/${shortUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="font-bold text-corporate text-2xl underline"
                                        >
                                            g2m.app/{shortUrl}
                                        </a>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
