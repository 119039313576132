import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function AmbassadorFormFields() {
    const [programName, setProgramName] = useState("");
    const [logo, setLogo] = useState(""); // Store base64 string
    const [products, setProducts] = useState([]);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const userToken = user?.token;

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(
                    "https://api.gotomarket.tools/api/ambassadorproduct",
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                setProducts(response.data.ambassadorProduct || []);
            } catch (error) {
                console.error("Failed to fetch products:", error);
            }
        };

        fetchProducts();
    }, [userToken]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, "");
                setLogo(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(
                "https://api.gotomarket.tools/api/ambassador/create",
                {
                    account: user.account._id,
                    programName: programName,
                    logo: logo,
                    products: products
                        .filter((p) => p.selected)
                        .map((p) => p._id),
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("Program created successfully:", response.data);
            navigate(-1);
        } catch (error) {
            console.error(
                "Error creating program: ",
                error.response?.data || error.message
            );
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <label className="block text-sm font-medium text-gray-900">
                    Name
                </label>
                <input
                    type="text"
                    value={programName}
                    onChange={(e) => setProgramName(e.target.value)}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                    required
                />

                <label className="block text-sm font-medium text-gray-900">
                    Logo
                </label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                />

                <label className="block text-sm font-medium text-gray-900">
                    Ambassador Products
                </label>
                <div className="space-y-2 mt-1 mb-2">
                    {products.length > 0 ? (
                        <div className="space-y-2 mt-1 mb-2">
                            {products.map((product) => (
                                <div
                                    key={product._id}
                                    className="flex items-center"
                                >
                                    <input
                                        type="checkbox"
                                        id={product._id}
                                        checked={product.selected || false}
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            setProducts((prevProducts) =>
                                                prevProducts.map((p) =>
                                                    p._id === product._id
                                                        ? {
                                                              ...p,
                                                              selected: checked,
                                                          }
                                                        : p
                                                )
                                            );
                                        }}
                                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                                    />
                                    <label
                                        htmlFor={product._id}
                                        className="ml-2 block text-sm text-gray-900"
                                    >
                                        {product.productName}
                                    </label>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md">
                            No products available. Please{" "}
                            <Link
                                to="/ambassador/products/create"
                                className="text-blue-500 underline"
                            >
                                create a product
                            </Link>{" "}
                            first.
                        </div>
                    )}
                </div>

                <button
                    type="submit"
                    className="flex w-full m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Create
                </button>
            </form>
        </div>
    );
}
