import { useEffect } from "react";
import AmbassadorProductList from "../components/AmbassadorProductList";

export default function AmbassadorProduct() {
    useEffect(() => {
        document.title = "Products - gotomarket.tools";
    }, []);

    return (
        <>
            <AmbassadorProductList />
        </>
    );
}
