import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

export default function EditProductFormFields() {
    const [productName, setProductName] = useState("");
    const [productAsin, setProductAsin] = useState("");
    const [productImage, setProductImage] = useState("");
    const [giveaways, setGiveaways] = useState([]);
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productResponse, giveawaysResponse] = await Promise.all([
                    axios.get(
                        `https://api.gotomarket.tools/api/products/${id}`,
                        {
                            headers: { Authorization: `Bearer ${userToken}` },
                        }
                    ),
                    axios.get(
                        `https://api.gotomarket.tools/api/giveaways/get`,
                        {
                            headers: { Authorization: `Bearer ${userToken}` },
                        }
                    ),
                ]);

                if (productResponse.data && giveawaysResponse.data) {
                    setProductName(productResponse.data.productName || "");
                    setProductImage(productResponse.data.productImage || "");
                    setProductAsin(productResponse.data.productAsin || "");

                    const eligibleGiveawayIds = new Set(
                        productResponse.data.giveaways.map((giveawayId) =>
                            giveawayId.toString()
                        )
                    );
                    const updatedGiveaways =
                        giveawaysResponse.data.giveaways.map((giveaway) => ({
                            ...giveaway,
                            selected: eligibleGiveawayIds.has(
                                giveaway._id.toString()
                            ),
                        }));
                    setGiveaways(updatedGiveaways);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [id, userToken]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, "");
                setProductImage(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.put(
                `https://api.gotomarket.tools/api/products/${id}`,
                {
                    account: user.account._id,
                    productName: productName,
                    productAsin: productAsin,
                    productImage: productImage,
                    giveaways: giveaways
                        .filter((g) => g.selected)
                        .map((g) => g._id),
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            navigate(-1);
        } catch (error) {
            console.error(
                "Error updating product:",
                error.response?.data || error.message
            );
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Product Name
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    The name of your product
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Product Name
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={productName}
                                                    onChange={(e) =>
                                                        setProductName(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Logo Image
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Upload your product image
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Product Image
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Product ASIN
                                                <div className="inline text-red-500">
                                                    *
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    The ASIN for this product on
                                                    Amazon
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    ASIN
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={productAsin}
                                                    onChange={(e) =>
                                                        setProductAsin(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Giveaway Eligibility
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    What giveaway(s) are
                                                    eligible for this product?
                                                    By making a giveaway
                                                    eligible for this product,
                                                    you are enabling anyone who
                                                    purchased this product to be
                                                    allowed to claim the
                                                    selected giveaway.
                                                </label>
                                                {giveaways.length > 0 ? (
                                                    <div className="space-y-2 mt-1 mb-2">
                                                        {giveaways.map(
                                                            (giveaway) => (
                                                                <div
                                                                    key={
                                                                        giveaway._id
                                                                    }
                                                                    className="flex items-center"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={
                                                                            giveaway._id
                                                                        }
                                                                        checked={
                                                                            giveaway.selected ||
                                                                            false
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const checked =
                                                                                e
                                                                                    .target
                                                                                    .checked;
                                                                            setGiveaways(
                                                                                (
                                                                                    prevGiveaways
                                                                                ) =>
                                                                                    prevGiveaways.map(
                                                                                        (
                                                                                            g
                                                                                        ) =>
                                                                                            g._id ===
                                                                                            giveaway._id
                                                                                                ? {
                                                                                                      ...g,
                                                                                                      selected:
                                                                                                          checked,
                                                                                                  }
                                                                                                : g
                                                                                    )
                                                                            );
                                                                        }}
                                                                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                                                                    />
                                                                    <label
                                                                        htmlFor={
                                                                            giveaway._id
                                                                        }
                                                                        className="ml-2 block text-sm text-gray-900"
                                                                    >
                                                                        {
                                                                            giveaway.giveawayName
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md">
                                                        No giveaways available.
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="flex w-full m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Save
                </button>
            </form>
        </div>
    );
}
