import { useEffect } from "react";
import SubscriptionSuccessPage from "../components/SubscriptionSuccessPage";

export default function Success() {
    useEffect(() => {
        document.title = "Success - gotomarket.tools";
    }, []);

    return <SubscriptionSuccessPage />;
}
