import { useState } from "react";
import { ArrowTrendingUpIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Trending() {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) {
        return null;
    }
    return (
        <div className="border-l-4 border-blue-400 bg-blue-50 p-4 mt-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ArrowTrendingUpIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-blue-700">
                        This item is trending.
                    </p>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            className="inline-flex rounded-md p-1.5 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
                            onClick={() => setIsVisible(false)}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
