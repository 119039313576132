import NavMinimal from "../components/navMinimal";

export default function Step2Minimal({
    landingPageData,
    setLandingPageData,
    updateLandingPageSettings,
}) {
    return (
        <NavMinimal
            landingPageData={landingPageData}
            setLandingPageData={setLandingPageData}
            updateLandingPageSettings={updateLandingPageSettings}
        />
    );
}
