import { useContext, useEffect, useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Spinner from "./Templates/Spinner";

// Combine links and view count fetching in one function
const fetchData = async (userToken) => {
    const response = await fetch("https://api.gotomarket.tools/api/link/", {
        headers: { Authorization: `Bearer ${userToken}` },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch links");
    }

    const linksData = await response.json();

    const linksWithViews = await Promise.all(
        linksData.links.map(async (link) => {
            try {
                const viewCountResponse = await fetch(
                    `https://api.gotomarket.tools/api/analytics/get-view-count/link/${link.shortId}`,
                    { headers: { Authorization: `Bearer ${userToken}` } }
                );

                if (!viewCountResponse.ok) {
                    throw new Error("Failed to fetch view count");
                }

                const viewCountData = await viewCountResponse.json();
                return { ...link, views: viewCountData.views || 0 };
            } catch (error) {
                return { ...link, views: 0 };
            }
        })
    );

    return linksWithViews;
};

export default function LinkList() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();

    const handleDelete = async (id) => {
        if (!user) {
            return;
        }

        try {
            const response = await fetch(
                `https://api.gotomarket.tools/api/link/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.ok) {
                // Trigger a refetch of the links data
                queryClient.invalidateQueries("links");
            } else {
                console.error("Failed to delete the link");
            }
        } catch (error) {
            console.error("Error deleting link:", error);
        }
    };

    const shortenUrl = (url) => {
        return url.length > 50 ? url.substring(0, 50) + "..." : url;
    };

    useEffect(() => {
        const handleEscape = (event) => {
            if (!showModal) return;

            if (event.key === "Escape") {
                setShowModal(false);
            }
        };

        document.addEventListener("keydown", handleEscape);
        return () => document.removeEventListener("keydown", handleEscape);
    }, [showModal]);

    const {
        data: linkData,
        status,
        error,
    } = useQuery(["links", userToken], () => fetchData(userToken), {
        enabled: !!userToken,
    });

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return <p>Error: {error.message}</p>;
    }

    if (status === "success") {
        return (
            <div className="bg-gray-100">
                <div className="mx-auto max-w-7xl">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="flow-root">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                                        Links
                                    </h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        A list of links on this account
                                    </p>
                                </div>
                                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                    <Link
                                        type="button"
                                        to="create"
                                        className="flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                    >
                                        Create Link
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Short Link
                                                    </th>
                                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Destination URL
                                                    </th>
                                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Link Type
                                                    </th>
                                                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Views
                                                    </th>
                                                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span className="sr-only">
                                                            Actions
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {linkData.length === 0 ? (
                                                    <tr>
                                                        <td
                                                            colSpan="5"
                                                            className="py-4 text-center text-sm"
                                                        >
                                                            No links available.
                                                            Please add a link.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    linkData.map((link) => (
                                                        <tr key={link._id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                <a
                                                                    href={`https://g2m.app/${link.shortId}`}
                                                                    target="_blank"
                                                                    className="underline text-sky-600"
                                                                >
                                                                    https://g2m.app/
                                                                    {
                                                                        link.shortId
                                                                    }
                                                                </a>
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {link.linkType ===
                                                                "Single Product Amazon URL"
                                                                    ? shortenUrl(
                                                                          `https://amazon.com/dp/${link.asin}`
                                                                      )
                                                                    : shortenUrl(
                                                                          link.originalUrl
                                                                      )}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {link.linkType}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {link.views ||
                                                                    0}
                                                            </td>
                                                            <td className="py-4 pr-4 flex items-center justify-end sm:pr-6">
                                                                <Link
                                                                    to={`edit/${link._id}`}
                                                                    title="Edit"
                                                                    className="text-sky-600 hover:text-sky-900 mr-2"
                                                                >
                                                                    <PencilIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </Link>
                                                                <button
                                                                    title="Delete"
                                                                    className="text-red-600 hover:text-red-900"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            link._id
                                                                        )
                                                                    } // Call handleDelete with the link's id
                                                                >
                                                                    <TrashIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Fallback JSX if none of the above conditions are met
    return null;
}
