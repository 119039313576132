import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";

export default function CreateSimpleQr() {
    const [url, setUrl] = useState("");
    const [qrCodeDataURL, setQrCodeDataURL] = useState(null);
    const [openHead, setopenHead] = useState("");
    const [closeHead, setcloseHead] = useState("");
    const [openBody, setopenBody] = useState("");
    const [closeBody, setcloseBody] = useState("");
    const [isTrackingEnabled, setIsTrackingEnabled] = useState(false);
    const { user } = useContext(AuthContext);
    const userToken = user?.token;

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const requestData = {
                originalUrl: url,
                account: user.account._id,
                isTrackingEnabled: isTrackingEnabled,
                openHead: openHead,
                closeHead: closeHead,
                openBody: openBody,
                closeBody: closeBody,
            };

            // API Request
            const response = await axios.post(
                "https://api.gotomarket.tools/api/qr/createQr",
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            // Set the QR Code Data URL to state
            setQrCodeDataURL(response.data.qrCodeDataURL);
        } catch (error) {
            console.error("Error creating QR code: ", error);
        }
    };

    const handleCheckboxChange = () => {
        setIsTrackingEnabled((prev) => !prev);
    };

    return (
        <div className="w-full">
            <label
                htmlFor="email"
                className="block mt-2 text-sm font-medium leading-6 text-gray-900 text-left"
            >
                URL
            </label>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={url}
                    placeholder="Enter your URL"
                    className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mb-2"
                    onChange={(e) => setUrl(e.target.value)}
                    required
                />
                <div className="mb-6">
                    <input
                        type="checkbox"
                        id="addTrackingPixels"
                        checked={isTrackingEnabled}
                        onChange={handleCheckboxChange}
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                    />
                    <label
                        htmlFor="addTrackingPixels"
                        className="text-sm text-gray-900 ml-2"
                    >
                        Add Tracking Pixels?
                    </label>
                    {isTrackingEnabled && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 text-sm">
                            <div className="">
                                <h3 className="text-md font-bold underline">
                                    Tracking Pixel #1
                                </h3>
                                <p className="mb-2">
                                    The contents of this text box will be
                                    injected immediately after the opening{" "}
                                    <strong>&lt;head&gt;</strong> of the
                                    document
                                </p>
                                <textarea
                                    value={openHead}
                                    onChange={(e) =>
                                        setopenHead(e.target.value)
                                    }
                                    className="w-full h-32 p-2 border border-gray-300 "
                                />
                            </div>
                            <div>
                                <h3 className="text-md font-bold underline">
                                    Tracking Pixel #2
                                </h3>
                                <p className="mb-2">
                                    The contents of this text box will be
                                    injected immediately before the closing{" "}
                                    <strong>&lt;/head&gt;</strong> of the
                                    document
                                </p>
                                <textarea
                                    value={closeHead}
                                    onChange={(e) =>
                                        setcloseHead(e.target.value)
                                    }
                                    className="w-full h-32 p-2 border border-gray-300 "
                                />
                            </div>
                            <div>
                                <h3 className="text-md font-bold underline">
                                    Tracking Pixel #3
                                </h3>
                                <p className="mb-2">
                                    The contents of this text box will be
                                    injected immediately after the opening{" "}
                                    <strong>&lt;body&gt;</strong> of the
                                    document
                                </p>
                                <textarea
                                    value={openBody}
                                    onChange={(e) =>
                                        setopenBody(e.target.value)
                                    }
                                    className="w-full h-32 p-2 border border-gray-300 "
                                />
                            </div>
                            <div>
                                <h3 className="text-md font-bold underline">
                                    Tracking Pixel #4
                                </h3>
                                <p className="mb-2">
                                    The contents of this text box will be
                                    injected immediately before the closing{" "}
                                    <strong>&lt;/body&gt;</strong> of the
                                    document
                                </p>
                                <textarea
                                    value={closeBody}
                                    onChange={(e) =>
                                        setcloseBody(e.target.value)
                                    }
                                    className="w-full h-32 p-2 border border-gray-300 "
                                />
                            </div>
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    className="flex m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Create QR Code
                </button>
            </form>
            {qrCodeDataURL && (
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Your QR Code
                    </h2>
                    <div className="mt-6 flex justify-center">
                        <img src={qrCodeDataURL} alt="QR Code" />
                    </div>
                </div>
            )}
        </div>
    );
}
