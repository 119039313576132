import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useParams } from "react-router-dom"; // import useParams hook
import { StarIcon } from "@heroicons/react/20/solid";
import { StarIcon as StarOutlineIcon } from "@heroicons/react/24/outline";

export default function ReviewDetail() {
    const { user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [review, setReview] = useState(null);
    const { id } = useParams(); // get id from route params

    const fetchReview = async () => {
        try {
            const response = await fetch(
                `https://api.gotomarket.tools/api/reviews/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch review profile");
            }
            const review = await response.json(); // Directly use the JSON response as the review data
            setReview(review);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching review", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (user) {
            fetchReview();
        }
    }, [user, id]);

    return (
        <main>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                review && (
                    <>
                        <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                            <div className="flex items-center space-x-5">
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900">
                                        {review.firstName}
                                        &nbsp;
                                        {review.lastName}
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto grid max-w-3xl sm:px-6 lg:max-w-7xl">
                            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                <section aria-labelledby="redemptions-title">
                                    <div className="bg-white shadow rounded-lg mt-6">
                                        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                                            <h2
                                                id="redemptions-title"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Product Review
                                            </h2>
                                            <div className="flex items-center">
                                                {[...Array(5)].map((_, index) =>
                                                    index < review.rating ? (
                                                        <StarIcon
                                                            key={index}
                                                            className="h-6 w-6 text-yellow-400"
                                                        />
                                                    ) : (
                                                        <StarOutlineIcon
                                                            key={index}
                                                            className="h-6 w-6 text-gray-300"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="border-t border-gray-200 py-1">
                                            <div className="px-4 py-5 sm:px-6">
                                                "{review.review}"
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <section aria-labelledby="applicant-information-title">
                                            <div className="bg-white shadow rounded-lg">
                                                <div className="px-4 py-5 sm:px-6">
                                                    <h2
                                                        id="applicant-information-title"
                                                        className="text-lg font-medium leading-6 text-gray-900"
                                                    >
                                                        Personal Information
                                                    </h2>
                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                        Personal details
                                                    </p>
                                                </div>
                                                <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Full Name
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.firstName
                                                                    ? review.firstName
                                                                    : "-"}{" "}
                                                                {review.lastName
                                                                    ? review.lastName
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Email Address
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.email
                                                                    ? review.email
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Phone Number
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.phone
                                                                    ? review.phone
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Product Selected
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.productName
                                                                    ? review.productName
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Order Number
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.orderNumber
                                                                    ? review.orderNumber
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Giveaway
                                                                Selected
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.giveawayName
                                                                    ? review.giveawayName
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <section aria-labelledby="applicant-information-title">
                                            <div className="bg-white shadow rounded-lg">
                                                <div className="px-4 py-5 sm:px-6">
                                                    <h2
                                                        id="applicant-information-title"
                                                        className="text-lg font-medium leading-6 text-gray-900"
                                                    >
                                                        Shipping Information
                                                    </h2>
                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                        Shipping details
                                                    </p>
                                                </div>
                                                <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Address 1
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.address1
                                                                    ? review.address1
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Address 2
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.address2
                                                                    ? review.address2
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                City
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.city
                                                                    ? review.city
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                State
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.state
                                                                    ? review.state
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                ZIP
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {review.zip
                                                                    ? review.zip
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5"></div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </>
                )
            )}
        </main>
    );
}
