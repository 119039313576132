import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useParams } from "react-router-dom"; // import useParams hook

export default function AmbassadorProfileDetail() {
    const { user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [ambassador, setAmbassador] = useState(null);
    const { ambassadorId } = useParams(); // get ambassadorId from route params

    const fetchAmbassador = async () => {
        try {
            const response = await fetch(
                `https://api.gotomarket.tools/api/ambassadors/${ambassadorId}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch ambassador profile");
            }
            const ambassador = await response.json(); // Directly use the JSON response as the ambassador data
            setAmbassador(ambassador);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching ambassador profile:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (user) {
            fetchAmbassador();
        }
    }, [user, ambassadorId]);

    return (
        <main>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                ambassador && (
                    <>
                        <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                            <div className="flex items-center space-x-5">
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900">
                                        {ambassador.personalInfo.firstName}
                                        &nbsp;
                                        {ambassador.personalInfo.lastName}
                                    </h1>
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto mt-8 grid max-w-3xl sm:px-6 lg:max-w-7xl">
                            <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                                {/* Description list*/}
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <section aria-labelledby="applicant-information-title">
                                            <div className="bg-white shadow sm:rounded-lg">
                                                <div className="px-4 py-5 sm:px-6">
                                                    <h2
                                                        id="applicant-information-title"
                                                        className="text-lg font-medium leading-6 text-gray-900"
                                                    >
                                                        Personal Information
                                                    </h2>
                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                        Personal details
                                                    </p>
                                                </div>
                                                <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Full Name
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .personalInfo
                                                                    .firstName
                                                                    ? ambassador
                                                                          .personalInfo
                                                                          .firstName
                                                                    : "-"}{" "}
                                                                {ambassador
                                                                    .personalInfo
                                                                    .lastName
                                                                    ? ambassador
                                                                          .personalInfo
                                                                          .lastName
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Email Address
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .personalInfo
                                                                    .email
                                                                    ? ambassador
                                                                          .personalInfo
                                                                          .email
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Phone Number
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .personalInfo
                                                                    .phone
                                                                    ? ambassador
                                                                          .personalInfo
                                                                          .phone
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Product Selected
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .selectedProduct
                                                                    .productName
                                                                    ? ambassador
                                                                          .selectedProduct
                                                                          .productName
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Order Number
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador.orderNumber
                                                                    ? ambassador.orderNumber
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                PayPal Email
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador.paypalEmail
                                                                    ? ambassador.paypalEmail
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <section aria-labelledby="applicant-information-title">
                                            <div className="bg-white shadow sm:rounded-lg">
                                                <div className="px-4 py-5 sm:px-6">
                                                    <h2
                                                        id="applicant-information-title"
                                                        className="text-lg font-medium leading-6 text-gray-900"
                                                    >
                                                        Shipping Information
                                                    </h2>
                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                        Shipping details
                                                    </p>
                                                </div>
                                                <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Address 1
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .shippingInfo
                                                                    .address1
                                                                    ? ambassador
                                                                          .shippingInfo
                                                                          .address1
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                Address 2
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .shippingInfo
                                                                    .address2
                                                                    ? ambassador
                                                                          .shippingInfo
                                                                          .address2
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                City
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .shippingInfo
                                                                    .city
                                                                    ? ambassador
                                                                          .shippingInfo
                                                                          .city
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                State
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .shippingInfo
                                                                    .state
                                                                    ? ambassador
                                                                          .shippingInfo
                                                                          .state
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                    <dl className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
                                                        <div className="sm:col-span-1 mt-5">
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                ZIP
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {ambassador
                                                                    .shippingInfo
                                                                    .zip
                                                                    ? ambassador
                                                                          .shippingInfo
                                                                          .zip
                                                                    : "-"}
                                                            </dd>
                                                        </div>
                                                        <div className="sm:col-span-1 mt-5"></div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </dl>

                                {/* Competitor Feedback Section */}
                                <section aria-labelledby="redemptions-title">
                                    <div className="bg-white shadow sm:rounded-lg mt-6">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2
                                                id="redemptions-title"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Competitor Feedback
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                Insights provided by the
                                                ambassador on competitors'
                                                products.
                                            </p>
                                        </div>
                                        <div className="border-t border-gray-200 py-1">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {ambassador.feedback
                                                        ?.slice(0, 2)
                                                        .map(
                                                            (
                                                                feedback,
                                                                index
                                                            ) => (
                                                                <tr
                                                                    key={index}
                                                                    className="bg-white"
                                                                >
                                                                    <td className="px-6 py-4 text-sm text-gray-900">
                                                                        <div className="flex flex-col">
                                                                            <div className="mb-3">
                                                                                <strong>
                                                                                    What
                                                                                    is
                                                                                    the
                                                                                    ASIN
                                                                                    of
                                                                                    the
                                                                                    product?
                                                                                </strong>{" "}
                                                                                <br />
                                                                                {feedback.asin ||
                                                                                    "-"}
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <strong>
                                                                                    What
                                                                                    made
                                                                                    you
                                                                                    pick
                                                                                    this
                                                                                    product
                                                                                    from
                                                                                    the
                                                                                    search
                                                                                    results?
                                                                                </strong>{" "}
                                                                                <br />
                                                                                {feedback.reason ||
                                                                                    "No specific reason provided."}
                                                                            </div>
                                                                            <div>
                                                                                <strong>
                                                                                    Looking
                                                                                    to
                                                                                    the
                                                                                    product
                                                                                    detail
                                                                                    page,
                                                                                    what
                                                                                    do
                                                                                    you
                                                                                    like
                                                                                    about
                                                                                    the
                                                                                    product
                                                                                    page,
                                                                                    what
                                                                                    grabs
                                                                                    your
                                                                                    attention
                                                                                    the
                                                                                    most?
                                                                                </strong>{" "}
                                                                                <br />
                                                                                {feedback.productDetails ||
                                                                                    "No details provided."}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>

                                {/* Competitor Feedback Section */}
                                <section aria-labelledby="redemptions-title">
                                    <div className="bg-white shadow sm:rounded-lg mt-6">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2
                                                id="redemptions-title"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Feedback on Your Product
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                Insights provided by the
                                                ambassador on your product.
                                            </p>
                                        </div>
                                        <div className="border-t border-gray-200 py-1">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {/* Check if at least 3 items exist and then access the third item directly */}
                                                    {ambassador.feedback &&
                                                        ambassador.feedback
                                                            .length > 2 && (
                                                            <tr className="bg-white">
                                                                <td className="px-6 py-4 text-sm text-gray-900">
                                                                    <div className="flex flex-col">
                                                                        <div>
                                                                            <strong>
                                                                                What
                                                                                is
                                                                                the
                                                                                ASIN
                                                                                of
                                                                                the
                                                                                product?
                                                                            </strong>{" "}
                                                                            {ambassador
                                                                                .feedback[2]
                                                                                .asin ||
                                                                                "-"}
                                                                        </div>
                                                                        <div>
                                                                            <strong>
                                                                                Why
                                                                                Chosen:
                                                                            </strong>{" "}
                                                                            {ambassador
                                                                                .feedback[2]
                                                                                .reason ||
                                                                                "No specific reason provided."}
                                                                        </div>
                                                                        <div>
                                                                            <strong>
                                                                                Page
                                                                                Highlights:
                                                                            </strong>{" "}
                                                                            {ambassador
                                                                                .feedback[2]
                                                                                .productDetails ||
                                                                                "No details provided."}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </>
                )
            )}
        </main>
    );
}
