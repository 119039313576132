import { useEffect } from "react";
import QrList from "../components/QrList";

export default function QrCodes() {
    useEffect(() => {
        document.title = "QR Codes - gotomarket.tools";
    }, []);

    return (
        <>
            <QrList />
        </>
    );
}
