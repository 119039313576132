import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const SubscriptionSuccessPage = () => {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const { dispatch } = useContext(AuthContext);

    // Function to fetch the latest user data after subscription
    const fetchUpdatedUserInfo = async () => {
        try {
            // fetch data from your endpoint
            const response = await fetch(
                "https://api.gotomarket.tools/api/users/get-user-info",
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            const updatedUserInfo = await response.json();

            const newUserObject = {
                ...user, // existing user data
                ...updatedUserInfo, // new user data (this will overwrite existing fields with the same name)
            };

            // Check if the user info includes the planId and update the context
            if (updatedUserInfo) {
                dispatch({
                    type: "UPDATE_USER",
                    payload: newUserObject,
                });
            }
        } catch (error) {
            console.error("Failed to fetch updated user info:", error);
        }
    };

    // Call the fetch function when the component mounts after a successful subscription
    useEffect(() => {
        fetchUpdatedUserInfo();
    }, []);

    return (
        <div>
            {/* Content to display after successful subscription */}
            <h1>Subscription Successful!</h1>
        </div>
    );
};

export default SubscriptionSuccessPage;
