import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function GiveawayFormFields() {
    const [giveawayName, setGiveawayName] = useState("");
    const [giveawaySku, setGiveawaySku] = useState("");
    const [giveawayImage, setGiveawayImage] = useState("");
    const [products, setProducts] = useState([]);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const userToken = user?.token;

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(
                    "https://api.gotomarket.tools/api/products/get",
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                setProducts(response.data.products || []);
            } catch (error) {
                console.error("Failed to fetch products:", error);
            }
        };

        fetchProducts();
    }, [userToken]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, "");
                setGiveawayImage(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(
                "https://api.gotomarket.tools/api/giveaways/create",
                {
                    account: user.account._id,
                    giveawayName: giveawayName,
                    giveawaySku: giveawaySku,
                    giveawayImage: giveawayImage,
                    products: products
                        .filter((p) => p.selected)
                        .map((p) => p._id),
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("Giveaway created successfully:", response.data);
            navigate(-1);
        } catch (error) {
            console.error(
                "Error creating program: ",
                error.response?.data || error.message
            );
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Giveaway Name
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    The name of your giveaway
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Giveaway Name
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={giveawayName}
                                                    onChange={(e) =>
                                                        setGiveawayName(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Giveaway Image
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    Upload your giveaway image
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    Giveaway Image
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Giveaway SKU
                                                <div className="inline text-red-500">
                                                    *
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    The SKU for this giveaway
                                                </label>
                                                <label className="block text-xs font-normal text-gray-900">
                                                    SKU
                                                    <div className="inline text-red-500">
                                                        *
                                                    </div>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={giveawaySku}
                                                    onChange={(e) =>
                                                        setGiveawaySku(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                                                    required
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="p-4 text-left text-md font-bold text-gray-900">
                                                Product Eligibility
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        <tr>
                                            <td className="p-4 text-sm">
                                                <label className="block text-sm font-normal text-gray-900 mb-2">
                                                    What product(s) are eligible
                                                    for this giveaway? By making
                                                    a product eligible for this
                                                    product, you are enabling
                                                    anyone who purchased this
                                                    product to be allowed to
                                                    claim the giveaway you are
                                                    creating now.
                                                </label>
                                                {products.length > 0 ? (
                                                    <div className="space-y-2 mt-1 mb-2">
                                                        {products.map(
                                                            (product) => (
                                                                <div
                                                                    key={
                                                                        product._id
                                                                    }
                                                                    className="flex items-center"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={
                                                                            product._id
                                                                        }
                                                                        checked={
                                                                            product.selected ||
                                                                            false
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const checked =
                                                                                e
                                                                                    .target
                                                                                    .checked;
                                                                            setProducts(
                                                                                (
                                                                                    prevProducts
                                                                                ) =>
                                                                                    prevProducts.map(
                                                                                        (
                                                                                            p
                                                                                        ) =>
                                                                                            p._id ===
                                                                                            product._id
                                                                                                ? {
                                                                                                      ...p,
                                                                                                      selected:
                                                                                                          checked,
                                                                                                  }
                                                                                                : p
                                                                                    )
                                                                            );
                                                                        }}
                                                                        className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                                                                    />
                                                                    <label
                                                                        htmlFor={
                                                                            product._id
                                                                        }
                                                                        className="ml-2 block text-sm text-gray-900"
                                                                    >
                                                                        {
                                                                            product.productName
                                                                        }
                                                                    </label>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md">
                                                        No products available.
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="flex w-full m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Create
                </button>
            </form>
        </div>
    );
}
