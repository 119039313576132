import { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import {
    Bars3Icon,
    XMarkIcon,
    TrashIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";
import {
    ArrowUpIcon,
    ArrowLeftIcon,
    ArrowDownIcon,
} from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import LandingTemplate from "./Templates/LandingTemplate";

export default function Nav({
    landingPageData,
    setLandingPageData,
    showCountdown,
    setShowCountdown,
    showNavigation,
    setShowNavigation,
    editorContent,
    setEditorContent,
    showLogo,
    setShowLogo,
    logoFile,
    setLogoFile,
    updateLandingPageSettings,
    showTrending,
    setShowTrending,
    showRatings,
    setShowRatings,
    showRatingsCount,
    setShowRatingsCount,
    showRatingsAverage,
    setShowRatingsAverage,
    showDiscountFlag,
    setShowDiscountFlag,
    showAmazonCouponCount,
    setShowAmazonCouponCount,
}) {
    const inputRef = useRef(null);
    const ratingRef = useRef(null);
    const textRef = useRef(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [newReview, setNewReview] = useState({
        name: "",
        rating: 5,
        text: "",
    });
    const [editingReviewIndex, setEditingReviewIndex] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [openIndices, setOpenIndices] = useState([
        [false], // for the first main accordion
        [false], // for the second main accordion
        [false], // for the third main accordion
        [false], // for the third main accordion
        [false], // for the third main accordion
    ]);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [isHeadlineEditable, setIsHeadlineEditable] = useState(false);
    const [isPriceEditable, setIsPriceEditable] = useState(false);
    const [isSalePriceEditable, setIsSalePriceEditable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [priceInput, setPriceInput] = useState(
        (landingPageData.price / 100).toFixed(2)
    );
    const [salePriceInput, setSalePriceInput] = useState(
        (landingPageData.salePrice / 100).toFixed(2)
    );

    useEffect(() => {
        if (landingPageData.bullet_descriptions) {
            setEditorContent(landingPageData.bullet_descriptions);
        }
    }, [landingPageData, setEditorContent]);

    const quillModules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "bullet" }, { list: "ordered" }],
        ],
    };
    const quillFormats = [
        "bold",
        "italic",
        "underline",
        "list",
        "bullet",
        "ordered",
    ];

    const handleEditorChange = (content) => {
        setEditorContent(content);
        landingPageData.bullet_descriptions = content;
    };

    const handleHeadlineClick = () => {
        setIsHeadlineEditable(true);
    };

    const handleHeadlineChange = (e) => {
        const newHeadline = e.target.value;
        setLandingPageData((prevData) => ({
            ...prevData,
            headline: newHeadline,
        }));
    };

    const handleTitleClick = () => {
        setIsTitleEditable(true);
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setLandingPageData((prevData) => ({
            ...prevData,
            title: newTitle,
        }));
    };

    const handlePriceClick = () => {
        setIsPriceEditable(true);
    };

    const handleSalePriceClick = () => {
        setIsSalePriceEditable(true);
    };

    const navigate = useNavigate();

    const handlePriceChange = (e) => {
        const newInput = e.target.value;
        // Check if the input is empty
        if (newInput === "") {
            setPriceInput("");
            setLandingPageData((prevData) => ({
                ...prevData,
                price: 0, // or whatever default value you want
            }));
        } else {
            // Ensure the input is a number with at most two digits after the decimal
            const validInput = /^\d+(\.\d{0,2})?$/.test(newInput);
            if (validInput) {
                setPriceInput(newInput);
                const newPrice = parseFloat(newInput);
                if (!isNaN(newPrice)) {
                    const priceInCents = Math.round(newPrice * 100); // Convert to cents
                    setLandingPageData((prevData) => ({
                        ...prevData,
                        price: priceInCents,
                    }));
                }
            }
        }
    };

    const handleSalePriceChange = (e) => {
        const newInput = e.target.value;
        // Check if the input is empty
        if (newInput === "") {
            setSalePriceInput("");
            setLandingPageData((prevData) => ({
                ...prevData,
                salePrice: 0, // or whatever default value you want
            }));
        } else {
            // Ensure the input is a number with at most two digits after the decimal
            const validInput = /^\d+(\.\d{0,2})?$/.test(newInput);
            if (validInput) {
                setSalePriceInput(newInput);
                const newSalePrice = parseFloat(newInput);
                if (!isNaN(newSalePrice)) {
                    const salePriceInCents = Math.round(newSalePrice * 100); // Convert to cents
                    setLandingPageData((prevData) => ({
                        ...prevData,
                        salePrice: salePriceInCents,
                    }));
                }
            }
        }
    };

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(" ");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await updateLandingPageSettings();
        setIsLoading(false);
    };

    const toggleItem = (index, subIndex) => {
        const newOpenIndices = [...openIndices];
        if (typeof subIndex !== "undefined") {
            newOpenIndices[index][1][subIndex] =
                !newOpenIndices[index][1][subIndex];
        } else {
            newOpenIndices[index][0] = !newOpenIndices[index][0];
        }
        setOpenIndices(newOpenIndices);
    };

    const handleCountdownToggle = (e) => {
        setShowCountdown(e.target.checked);
    };

    const handleNavigationToggle = (e) => {
        setShowNavigation(e.target.checked);
    };

    const handleLogoToggle = (e) => {
        setShowLogo(e.target.checked);
    };

    const handleRatingsToggle = (e) => {
        setShowRatings(e.target.checked);
    };

    const handleRatingsCountToggle = (e) => {
        setShowRatingsCount(e.target.checked);
    };

    const handleRatingsAverageToggle = (e) => {
        setShowRatingsAverage(e.target.checked);
    };

    const handleTrendingToggle = (e) => {
        setShowTrending(e.target.checked);
    };

    const handleAmazonCouponCountToggle = (e) => {
        setShowAmazonCouponCount(e.target.checked);
    };

    const handleDiscountFlagToggle = (e) => {
        setShowDiscountFlag(e.target.checked);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const prefix = "data:image/jpeg;base64,";
                let imageData = reader.result;

                if (imageData.startsWith(prefix)) {
                    imageData = imageData.slice(prefix.length);
                }

                setLogoFile(imageData);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddReview = (e) => {
        e.preventDefault();

        const newReviews = [...landingPageData.reviews, newReview];
        setLandingPageData({ ...landingPageData, reviews: newReviews });

        // Clear form fields
        setNewReview({ name: "", rating: "", text: "" });
    };

    const handleUpdateReview = (e, index) => {
        e.preventDefault();
        const rating = parseInt(ratingRef.current.value);
        if (rating < 1 || rating > 5) {
            alert("Rating must be between 1 and 5.");
            return;
        }
        const updatedReview = {
            name: inputRef.current.value,
            rating: rating,
            text: textRef.current.value,
        };
        setLandingPageData((prevState) => {
            const newReviews = [...prevState.reviews];
            newReviews[index] = updatedReview;
            return { ...prevState, reviews: newReviews };
        });
        setEditingReviewIndex(null);
    };

    // Define the function to handle moving a review up in the array
    const handleMoveReviewUp = (index) => {
        if (index === 0) {
            return; // Exit if it's the first item
        }

        let newReviews = [...landingPageData.reviews];
        let temp = newReviews[index];
        newReviews[index] = newReviews[index - 1];
        newReviews[index - 1] = temp;

        setLandingPageData({ ...landingPageData, reviews: newReviews });
    };

    // Define the function to handle moving a review down in the array
    const handleMoveReviewDown = (index) => {
        if (index === landingPageData.reviews.length - 1) {
            return; // Exit if it's the last item
        }

        let newReviews = [...landingPageData.reviews];
        let temp = newReviews[index];
        newReviews[index] = newReviews[index + 1];
        newReviews[index + 1] = temp;

        setLandingPageData({ ...landingPageData, reviews: newReviews });
    };

    const handleRemoveReview = (index) => {
        const newReviews = [...landingPageData.reviews];
        newReviews.splice(index, 1);
        setLandingPageData({ ...landingPageData, reviews: newReviews });
    };

    // Define the function to handle moving an image up in the array
    const handleMoveUp = (index) => {
        if (index === 0) {
            return; // Exit if it's the first item
        }

        let newImages = [...landingPageData.images];
        let temp = newImages[index];
        newImages[index] = newImages[index - 1];
        newImages[index - 1] = temp;

        setLandingPageData({ ...landingPageData, images: newImages });
    };

    // Define the function to handle moving an image down in the array
    const handleMoveDown = (index) => {
        if (index === landingPageData.images.length - 1) {
            return; // Exit if it's the last item
        }

        let newImages = [...landingPageData.images];
        let temp = newImages[index];
        newImages[index] = newImages[index + 1];
        newImages[index + 1] = temp;

        setLandingPageData({ ...landingPageData, images: newImages });
    };

    const handleRemoveImage = (index) => {
        const newImages = [...landingPageData.images];
        newImages.splice(index, 1);
        setLandingPageData({ ...landingPageData, images: newImages });

        if (selectedImageIndex >= newImages.length) {
            setSelectedImageIndex(newImages.length - 1);
        }
    };

    const handleAddImage = (event) => {
        const files = event.target.files;
        if (files) {
            // Calculate how many files we can add before reaching the limit of 8
            const availableSlots = Math.max(
                0,
                8 - landingPageData.images.length
            );
            const fileCount = Math.min(files.length, availableSlots);

            for (let i = 0; i < fileCount; i++) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    let imageData = reader.result;

                    const jpegPrefix = "data:image/jpeg;base64,";
                    const pngPrefix = "data:image/png;base64,";

                    if (imageData.startsWith(jpegPrefix)) {
                        imageData = imageData.slice(jpegPrefix.length);
                    } else if (imageData.startsWith(pngPrefix)) {
                        imageData = imageData.slice(pngPrefix.length);
                    }

                    setLandingPageData((prevData) => ({
                        ...prevData,
                        images: [...prevData.images, imageData],
                    }));
                };
                reader.readAsDataURL(files[i]);
            }
        }

        // Reset the file input value
        event.target.value = null;
    };

    return (
        <>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-sky-500 bg-sky-600 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <div className="flex justify-start">
                    <button
                        className="flex items-center space-x-2 text-white"
                        onClick={(event) => {
                            event.preventDefault();
                            navigate(-1); // Use navigate(-1) to go back
                        }}
                    >
                        <ArrowLeftIcon className="h-5 w-5" />
                        <span>Cancel</span>
                    </button>
                </div>
                <div className="ml-auto flex items-center gap-x-4">
                    <a
                        href={`/landingpage/${landingPageData._id}`}
                        target="_blank"
                        className="flex items-center justify-center rounded-md border bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 border border-orange-700"
                    >
                        View Landing Page
                    </a>
                    <form onSubmit={handleSubmit}>
                        <button
                            type="submit"
                            className="flex items-center justify-center rounded-md border bg-lime-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-lime-700 border border-lime-700"
                        >
                            {isLoading ? "Loading..." : "Save and Continue"}
                        </button>
                    </form>
                </div>
            </div>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-50 lg:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button
                                                type="button"
                                                className="-m-2.5 p-2.5"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XMarkIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/* Sidebar component, swap this element with another sidebar if you like */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className="h-8 w-auto"
                                                src="https://tailwindui.com/img/logos/mark.svg?color=sky&shade=600"
                                                alt="Your Company"
                                            />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden mt-16 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white">
                        <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col">
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(0); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[0][0] // Update here
                                                ? "bg-gray-50 text-sky-600 "
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold"
                                        )}
                                    >
                                        Navigation
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[0][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    {openIndices[0][0] && (
                                        <ul>
                                            <li className="border-y border-gray-200">
                                                <span
                                                    className={classNames(
                                                        "text-gray-700",
                                                        "group flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                    )}
                                                >
                                                    <label>
                                                        Show
                                                        Navigation:&nbsp;&nbsp;
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                showNavigation
                                                            }
                                                            onChange={
                                                                handleNavigationToggle
                                                            }
                                                            className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                        />
                                                    </label>
                                                </span>
                                            </li>
                                            {showNavigation && (
                                                <>
                                                    <li className="border-b border-gray-200 pl-4 pr-2">
                                                        <span className="text-gray-700 text-gray-700 group flex gap-x-3 p-4 text-sm leading-6 font-semibold">
                                                            <div className="flex flex-col">
                                                                <label>
                                                                    Show
                                                                    Logo:&nbsp;&nbsp;
                                                                    <input
                                                                        type="checkbox"
                                                                        id="showLogo"
                                                                        checked={
                                                                            showLogo
                                                                        }
                                                                        onChange={
                                                                            handleLogoToggle
                                                                        }
                                                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                    />
                                                                </label>
                                                                {showLogo && (
                                                                    <div className="mb-2 mt-3">
                                                                        <input
                                                                            type="file"
                                                                            id="uploadLogo"
                                                                            accept=".png, .jpg, .jpeg"
                                                                            onChange={
                                                                                handleFileChange
                                                                            }
                                                                            className="w-full"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="pl-4 pr-2">
                                                        <span
                                                            className={classNames(
                                                                "text-gray-700",
                                                                "group flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                            )}
                                                        >
                                                            <label>
                                                                Show
                                                                Countdown:&nbsp;&nbsp;
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        showCountdown
                                                                    }
                                                                    onChange={
                                                                        handleCountdownToggle
                                                                    }
                                                                    className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                />
                                                            </label>
                                                        </span>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    )}
                                </li>
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(1); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[1][0] // Update here
                                                ? "bg-gray-50 text-sky-600"
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold "
                                        )}
                                    >
                                        <div className="flex items-center">
                                            <div>Images</div>
                                            <div className="text-sm font-normal ml-2">
                                                (max 8)
                                            </div>
                                        </div>
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[1][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    {openIndices[1][0] && (
                                        <ul
                                            role="list"
                                            className="px-2 border-t border-gray-200"
                                        >
                                            {landingPageData.images.map(
                                                (image, index) => (
                                                    <li
                                                        key={index}
                                                        className="px-2 py-1"
                                                    >
                                                        <div className="group flex justify-between items-center gap-x-3 rounded-md text-sm leading-6 font-semibold">
                                                            <div className="flex items-center gap-x-3">
                                                                <div>
                                                                    <ArrowUpIcon
                                                                        className="h-3 w-3 text-gray-700"
                                                                        onClick={() =>
                                                                            handleMoveUp(
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                    <ArrowDownIcon
                                                                        className="h-3 w-3 text-gray-700"
                                                                        onClick={() =>
                                                                            handleMoveDown(
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <img
                                                                    src={`data:image/jpeg;base64,${image}`}
                                                                    className="h-10 w-10"
                                                                    alt={
                                                                        landingPageData.title
                                                                    }
                                                                />
                                                                {`Image ${
                                                                    index + 1
                                                                }`}
                                                            </div>
                                                            <div>
                                                                <TrashIcon
                                                                    className="h-4 w-4 text-gray-700"
                                                                    onClick={() =>
                                                                        handleRemoveImage(
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            )}
                                            <li className="p-2 mt-2 mb-4 overflow-hidden">
                                                <label
                                                    htmlFor="addImage"
                                                    className="rounded-md bg-sky-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                                >
                                                    Upload image
                                                </label>
                                                <input
                                                    type="file"
                                                    id="addImage"
                                                    accept=".png, .jpg, .jpeg"
                                                    multiple
                                                    onChange={handleAddImage}
                                                    style={{ display: "none" }}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(2); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[2][0] // Update here
                                                ? "bg-gray-50 text-sky-600"
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold "
                                        )}
                                    >
                                        Product
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[2][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    <div className="w-full">
                                        {openIndices[2][0] && (
                                            <ul role="list">
                                                <li className="border-y border-gray-200 px-2">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <label>
                                                            Show
                                                            Ratings:&nbsp;&nbsp;
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    showRatings
                                                                }
                                                                onChange={
                                                                    handleRatingsToggle
                                                                }
                                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                {showRatings && (
                                                    <>
                                                        <li className="border-b border-gray-200 pl-4 pr-2">
                                                            <span
                                                                className={classNames(
                                                                    "text-gray-700",
                                                                    "w-full flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                                )}
                                                            >
                                                                <label>
                                                                    Show Average
                                                                    Rating
                                                                    :&nbsp;&nbsp;
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            showRatingsAverage
                                                                        }
                                                                        onChange={
                                                                            handleRatingsAverageToggle
                                                                        }
                                                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                    />
                                                                </label>
                                                            </span>
                                                        </li>
                                                        <li className="border-b border-gray-200 pl-4 pr-2">
                                                            <span
                                                                className={classNames(
                                                                    "text-gray-700",
                                                                    "w-full flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                                )}
                                                            >
                                                                <label>
                                                                    Show Ratings
                                                                    Count:&nbsp;&nbsp;
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            showRatingsCount
                                                                        }
                                                                        onChange={
                                                                            handleRatingsCountToggle
                                                                        }
                                                                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                                    />
                                                                </label>
                                                            </span>
                                                        </li>
                                                    </>
                                                )}
                                                <li className="border-b border-gray-200 px-2">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <label>
                                                            Show
                                                            Trending:&nbsp;&nbsp;
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    showTrending
                                                                }
                                                                onChange={
                                                                    handleTrendingToggle
                                                                }
                                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="border-b border-gray-200 px-2">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <label>
                                                            Show Amazon Coupon
                                                            Count:&nbsp;&nbsp;
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    showAmazonCouponCount
                                                                }
                                                                onChange={
                                                                    handleAmazonCouponCountToggle
                                                                }
                                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="border-b border-gray-200 px-2">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full group flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <label>
                                                            Headline
                                                            <input
                                                                type="text"
                                                                onClick={
                                                                    handleHeadlineClick
                                                                }
                                                                onChange={
                                                                    handleHeadlineChange
                                                                }
                                                                value={
                                                                    landingPageData.headline
                                                                }
                                                                readOnly={
                                                                    !isHeadlineEditable
                                                                }
                                                                className="w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="border-b border-gray-200 px-2">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full group flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <label>
                                                            Title&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <input
                                                                type="text"
                                                                onClick={
                                                                    handleTitleClick
                                                                }
                                                                onChange={
                                                                    handleTitleChange
                                                                }
                                                                value={
                                                                    landingPageData.title
                                                                }
                                                                readOnly={
                                                                    !isTitleEditable
                                                                }
                                                                className="w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="px-6 py-4">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full group flex gap-x-3 text-sm leading-6 pb-2 font-semibold"
                                                        )}
                                                    >
                                                        Description
                                                    </span>
                                                    <ReactQuill
                                                        theme="snow"
                                                        value={editorContent}
                                                        onChange={
                                                            handleEditorChange
                                                        }
                                                        modules={quillModules}
                                                        formats={quillFormats}
                                                    />
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </li>
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(3); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[3][0] // Update here
                                                ? "bg-gray-50 text-sky-600 "
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold"
                                        )}
                                    >
                                        Pricing
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[3][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    <div className="w-full">
                                        {openIndices[3][0] && (
                                            <ul role="list">
                                                <li className="border-y border-gray-200 px-2">
                                                    <span className="text-gray-700 w-full group flex gap-x-3 p-4 text-sm leading-6 font-semibold">
                                                        <label>
                                                            Regular Price
                                                            <input
                                                                type="text"
                                                                onClick={
                                                                    handlePriceClick
                                                                }
                                                                onChange={
                                                                    handlePriceChange
                                                                }
                                                                value={
                                                                    priceInput
                                                                }
                                                                readOnly={
                                                                    !isPriceEditable
                                                                }
                                                                className="w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="border-b border-gray-200 px-2">
                                                    <span className="text-gray-700 w-full group flex gap-x-3 p-4 text-sm leading-6 font-semibold">
                                                        <label>
                                                            Sale Price
                                                            <input
                                                                type="text"
                                                                onClick={
                                                                    handleSalePriceClick
                                                                }
                                                                onChange={
                                                                    handleSalePriceChange
                                                                }
                                                                value={
                                                                    salePriceInput
                                                                }
                                                                readOnly={
                                                                    !isSalePriceEditable
                                                                }
                                                                className="w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                                <li className="px-2">
                                                    <span
                                                        className={classNames(
                                                            "text-gray-700",
                                                            "w-full flex gap-x-3 p-4 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <label>
                                                            Show "Percent Off"
                                                            Flag:&nbsp;&nbsp;
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    showDiscountFlag
                                                                }
                                                                onChange={
                                                                    handleDiscountFlagToggle
                                                                }
                                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                                            />
                                                        </label>
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </li>
                                <li className="border-b border-gray-200">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleItem(4); // Update here
                                        }}
                                        className={classNames(
                                            openIndices[4][0] // Update here
                                                ? "bg-gray-50 text-sky-600"
                                                : "text-gray-700 hover:bg-gray-50",
                                            "group flex justify-between items-center gap-x-3 p-4 text-sm leading-6 font-semibold "
                                        )}
                                    >
                                        <div className="flex items-center">
                                            <div>Reviews</div>
                                            <div className="text-sm font-normal ml-2">
                                                (max 5)
                                            </div>
                                        </div>
                                        <ChevronDownIcon
                                            className={classNames(
                                                "h-5 w-5",
                                                openIndices[4][0] // Update here
                                                    ? "rotate-180 transition-transform duration-200"
                                                    : "transition-transform duration-200"
                                            )}
                                        />
                                    </a>
                                    <div className="w-full">
                                        {openIndices[4][0] && (
                                            <ul
                                                role="list"
                                                className="px-2 border-t border-gray-200"
                                            >
                                                {landingPageData.reviews.map(
                                                    (review, index) => (
                                                        <li
                                                            key={index}
                                                            className="px-2 py-1"
                                                        >
                                                            <div className="group flex justify-between items-center gap-x-3 rounded-md text-sm leading-6 font-semibold">
                                                                <div className="flex items-center gap-x-3 my-1">
                                                                    <div>
                                                                        {editingReviewIndex ===
                                                                            null && (
                                                                            <div>
                                                                                <ArrowUpIcon
                                                                                    className="h-3 w-3 text-gray-700"
                                                                                    onClick={() =>
                                                                                        handleMoveReviewUp(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <ArrowDownIcon
                                                                                    className="h-3 w-3 text-gray-700"
                                                                                    onClick={() =>
                                                                                        handleMoveReviewDown(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {editingReviewIndex ===
                                                                    index ? (
                                                                        <form
                                                                            onSubmit={(
                                                                                e
                                                                            ) =>
                                                                                handleUpdateReview(
                                                                                    e,
                                                                                    index
                                                                                )
                                                                            }
                                                                            className={`mt-2 mb-4 ${
                                                                                editingReviewIndex ===
                                                                                index
                                                                                    ? "border border-gray-300 rounded-md p-4"
                                                                                    : ""
                                                                            }`}
                                                                        >
                                                                            <div className="flex flex-col">
                                                                                <label>
                                                                                    <span className="font-semibold">
                                                                                        Name:
                                                                                    </span>
                                                                                    <input
                                                                                        type="text"
                                                                                        defaultValue={
                                                                                            review.name
                                                                                        }
                                                                                        ref={
                                                                                            inputRef
                                                                                        }
                                                                                        className="w-full my-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                                                    />
                                                                                </label>
                                                                                <label>
                                                                                    <span className="font-semibold">
                                                                                        Rating:
                                                                                    </span>
                                                                                    <input
                                                                                        type="number"
                                                                                        min="1"
                                                                                        max="5"
                                                                                        defaultValue={
                                                                                            review.rating
                                                                                        }
                                                                                        ref={
                                                                                            ratingRef
                                                                                        }
                                                                                        className="w-full my-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                                                    />
                                                                                </label>
                                                                                <label>
                                                                                    <span className="font-semibold">
                                                                                        Review
                                                                                        text:
                                                                                    </span>
                                                                                    <textarea
                                                                                        defaultValue={
                                                                                            review.text
                                                                                        }
                                                                                        ref={
                                                                                            textRef
                                                                                        }
                                                                                        className="w-full my-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                                                    />
                                                                                </label>
                                                                            </div>
                                                                            <div className="flex gap-x-2 mt-2">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
                                                                                >
                                                                                    Save
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                                                                                    onClick={() =>
                                                                                        setEditingReviewIndex(
                                                                                            null
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    ) : (
                                                                        review.name
                                                                    )}
                                                                </div>
                                                                {editingReviewIndex ===
                                                                    null && (
                                                                    <div className="flex items-center gap-x-3">
                                                                        <PencilIcon
                                                                            className="h-4 w-4 text-gray-700"
                                                                            onClick={() =>
                                                                                setEditingReviewIndex(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                        <TrashIcon
                                                                            className="h-4 w-4 text-gray-700"
                                                                            onClick={() =>
                                                                                handleRemoveReview(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                                {editingReviewIndex === null &&
                                                    landingPageData.reviews
                                                        .length < 5 && (
                                                        <>
                                                            <div className="p-2 mt-2 mb-4">
                                                                <button
                                                                    onClick={() =>
                                                                        setShowForm(
                                                                            !showForm
                                                                        )
                                                                    }
                                                                    className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
                                                                >
                                                                    Add review
                                                                </button>
                                                                {showForm && (
                                                                    <form
                                                                        onSubmit={
                                                                            handleAddReview
                                                                        }
                                                                        className="mt-4 text-sm"
                                                                    >
                                                                        <label>
                                                                            <span className="font-semibold">
                                                                                Name
                                                                            </span>
                                                                            <input
                                                                                type="text"
                                                                                value={
                                                                                    newReview.name
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setNewReview(
                                                                                        {
                                                                                            ...newReview,
                                                                                            name: e
                                                                                                .target
                                                                                                .value,
                                                                                        }
                                                                                    )
                                                                                }
                                                                                className="w-full my-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                                            />
                                                                        </label>
                                                                        <label>
                                                                            <div className="flex items-center">
                                                                                <span className="font-semibold">
                                                                                    Rating
                                                                                </span>
                                                                                <div className="text-sm font-normal ml-2">
                                                                                    (max
                                                                                    5)
                                                                                </div>
                                                                            </div>
                                                                            <input
                                                                                type="number"
                                                                                min="1"
                                                                                max="5"
                                                                                value={
                                                                                    newReview.rating
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setNewReview(
                                                                                        {
                                                                                            ...newReview,
                                                                                            rating: parseInt(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            ),
                                                                                        }
                                                                                    )
                                                                                }
                                                                                className="w-full my-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                                            />
                                                                        </label>
                                                                        <label>
                                                                            <span className="font-semibold">
                                                                                Review
                                                                                text:
                                                                            </span>
                                                                            <textarea
                                                                                value={
                                                                                    newReview.text
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setNewReview(
                                                                                        {
                                                                                            ...newReview,
                                                                                            text: e
                                                                                                .target
                                                                                                .value,
                                                                                        }
                                                                                    )
                                                                                }
                                                                                className="w-full my-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                                                            />
                                                                        </label>
                                                                        <div className="flex gap-x-2 mt-2">
                                                                            <button
                                                                                type="submit"
                                                                                className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button
                                                                                onClick={() =>
                                                                                    setShowForm(
                                                                                        !showForm
                                                                                    )
                                                                                }
                                                                                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                            </ul>
                                        )}
                                    </div>
                                </li>
                                {/* Add more main items as needed */}
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
                        Edit Menu
                    </div>
                </div>

                <main className="lg:pl-72">
                    <LandingTemplate
                        {...landingPageData}
                        setLandingPageData={setLandingPageData}
                        showCountdown={showCountdown}
                        showNavigation={showNavigation}
                        showLogo={showLogo}
                        logoFile={logoFile}
                        selectedImageIndex={selectedImageIndex}
                        setSelectedImageIndex={setSelectedImageIndex}
                        showTrending={showTrending}
                        showAmazonCouponCount={showAmazonCouponCount}
                        showRatings={showRatings}
                        showRatingsCount={showRatingsCount}
                        showRatingsAverage={showRatingsAverage}
                        showDiscountFlag={showDiscountFlag}
                        editorContent={editorContent}
                    />
                </main>
            </div>
        </>
    );
}
