import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Spinner from "./Templates/Spinner";

const fetchCustomers = async (userToken) => {
    const response = await fetch("https://api.gotomarket.tools/api/customers", {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
    });
    if (!response.ok) {
        throw new Error("Failed to fetch landing pages");
    }
    return response.json();
};

const downloadCSV = async (userToken, accountId) => {
    try {
        const response = await fetch(
            `https://api.gotomarket.tools/api/customers/export-csv?accountId=${accountId}`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );

        if (!response.ok) {
            if (response.status === 404) {
                const data = await response.json();
                throw new Error(data.message); // Throws an error with a specific message
            }
            throw new Error("Failed to fetch customers CSV");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "customers.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error(error);
        alert(error.message); // Display the error message in an alert
    }
};

export default function CustomerList() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const [accountId, setAccountId] = useState("");

    const {
        data: customersData,
        status,
        error,
    } = useQuery(["customers", userToken], () => fetchCustomers(userToken), {
        enabled: !!userToken,
        onSuccess: (data) => {
            setAccountId(data.accountId); // Assuming 'accountId' is part of the response
        },
    });

    const customers = customersData?.customers;

    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                Customers
                            </h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all customers on this account
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                onClick={() =>
                                    downloadCSV(userToken, accountId)
                                }
                                className="flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                            >
                                Export .CSV
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    First Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Last Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Redemptions
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                                >
                                                    <span className="sr-only">
                                                        View
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {status === "loading" ? (
                                                <tr>
                                                    <td
                                                        className="py-4"
                                                        colSpan="7"
                                                    >
                                                        <div className="flex justify-center text-sm">
                                                            <Spinner />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : status === "error" ? (
                                                <p>Error: {error.message}</p>
                                            ) : (
                                                <>
                                                    {customers.length === 0 ? (
                                                        <tr>
                                                            <td
                                                                className="py-4"
                                                                colSpan="7"
                                                            >
                                                                <div className="flex justify-center text-sm">
                                                                    No customers
                                                                    present.
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        customers.map(
                                                            (customer) => (
                                                                <tr
                                                                    key={
                                                                        customer._id
                                                                    }
                                                                >
                                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                        {
                                                                            customer.firstName
                                                                        }
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {
                                                                            customer.lastName
                                                                        }
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {
                                                                            customer.email
                                                                        }
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                        {
                                                                            customer.redemptions
                                                                        }
                                                                    </td>
                                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                        <Link
                                                                            to={`/customer/${customer._id}`}
                                                                            className="text-sky-600 hover:text-sky-900"
                                                                        >
                                                                            View
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
