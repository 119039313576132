import React, { useState } from "react";
import DatePicker from "../components/DatePicker";
import { useNavigate } from "react-router-dom";

export default function Step3({
    landingPageData,
    setLandingPageData,
    updateLandingPageSettings,
    setTrackingPixelHead,
    setTrackingPixelStartBody,
    setTrackingPixelEndBody,
    couponUsageType,
    setRedemptionLimit,
    setStep,
}) {
    const [usageType, setUsageType] = useState(couponUsageType);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleCouponCodesChange = (event) => {
        const codes = event.target.value.split("\n");
        setLandingPageData((prevState) => ({
            ...prevState,
            couponCodes: codes,
        }));
    };

    const handleUsageTypeChange = (event) => {
        setUsageType(event.target.value);
        setLandingPageData((prevState) => ({
            ...prevState,
            couponUsageType: event.target.value, // set the new field
        }));
    };

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        setLandingPageData((prevState) => ({
            ...prevState,
            title: newTitle,
        }));
    };

    const handleDateChange = (startDate, endDate) => {
        setLandingPageData((prev) => ({
            ...prev,
            countdownConfig: {
                ...prev.countdownConfig,
                startDate,
                endDate,
            },
        }));
    };

    const handleRedemptionLimitChange = (event) => {
        setRedemptionLimit(event.target.value);
    };

    const handleTrackingPixelHeadChange = (event) => {
        setTrackingPixelHead(event.target.value);
    };

    const handleTrackingPixelStartBodyChange = (event) => {
        setTrackingPixelStartBody(event.target.value);
    };

    const handleTrackingPixelEndBodyChange = (event) => {
        setTrackingPixelEndBody(event.target.value);
    };

    const handleFromChange = (event) => {
        const newFrom = event.target.value;
        setLandingPageData((prevState) => ({
            ...prevState,
            from: newFrom,
        }));
    };

    const handleSubjectChange = (event) => {
        const newSubject = event.target.value;
        setLandingPageData((prevState) => ({
            ...prevState,
            subject: newSubject,
        }));
    };

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setLandingPageData((prevState) => ({
            ...prevState,
            email: newEmail,
        }));
    };

    const handleBackButtonClick = () => {
        setStep(2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await updateLandingPageSettings();
        setIsLoading(false);
        navigate("/landingpage");
    };
    return (
        <>
            <main className="mx-auto max-w-lg px-4 pb-12 pt-10 lg:pb-16">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-6">
                        <div>
                            <h1 className="text-2xl font-medium leading-6 text-gray-900">
                                Landing Page Settings
                            </h1>
                            <p className="mt-2 text-sm text-gray-500">
                                Let’s get started by filling in the information
                                below to create your new landing page.
                            </p>
                        </div>

                        <div>
                            <h2 className="block text-md font-medium leading-6 text-gray-900">
                                Product Name
                            </h2>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="product-name"
                                    id="product-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={`${landingPageData.title}`}
                                    onChange={handleTitleChange}
                                />
                            </div>
                        </div>

                        <div>
                            <h2
                                htmlFor="project-name"
                                className="block text-md font-medium leading-6 text-gray-900"
                            >
                                Campaign Duration
                            </h2>
                            <DatePicker
                                landingPageData={landingPageData}
                                handleDateChange={handleDateChange}
                            />
                        </div>

                        <div>
                            <h2 className="block text-md font-medium leading-6 text-gray-900">
                                Redemption Limit
                            </h2>
                            <p className="mt-1 text-sm text-gray-500">
                                Set the maximum number of redemptions a single
                                customer can make.
                            </p>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="redemptionLimit"
                                    id="redemptionLimit"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={
                                        landingPageData.redemptionLimit
                                    }
                                    onChange={handleRedemptionLimitChange}
                                />
                            </div>
                        </div>

                        <div>
                            <h2 className="block text-md font-medium leading-6 text-gray-900">
                                Coupon Codes
                            </h2>
                            <p className="mt-1 text-sm text-gray-500">
                                Choose whether the coupons are "one time use" or
                                "multi-use". One time use sends a unique coupon
                                code to each claimant. Multi-use sends the same
                                coupon code to each claimant.
                            </p>
                            <div className="mt-2">
                                <select
                                    value={usageType}
                                    onChange={handleUsageTypeChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                >
                                    <option value="one time use">
                                        One time use
                                    </option>
                                    <option value="multi-use">Multi-use</option>
                                </select>
                            </div>
                            <p className="mt-3 text-sm text-gray-500">
                                {usageType === "one time use"
                                    ? "Enter unique coupon codes, separated by a comma (i.e. 1,2,3)"
                                    : "Enter a single coupon code that will be sent to all claimants"}
                            </p>
                            <div className="mt-2">
                                <textarea
                                    id="couponcodes"
                                    name="couponcodes"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={landingPageData.couponCodes}
                                    onChange={handleCouponCodesChange}
                                />
                            </div>
                        </div>

                        <div>
                            <h2
                                htmlFor="project-name"
                                className="block text-md font-medium leading-6 text-gray-900"
                            >
                                Tracking Pixels
                            </h2>
                            <p className="mt-2 text-sm text-gray-500">
                                Code pasted into the below input field will be
                                injected into the <strong>{`<head>`}</strong> of
                                the landing page
                            </p>
                            <div className="mt-2">
                                <textarea
                                    id="trackingPixelHead"
                                    name="trackingPixelHead"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={
                                        landingPageData.trackingPixelHead
                                    }
                                    onChange={handleTrackingPixelHeadChange}
                                />
                            </div>
                            <p className="mt-3 text-sm text-gray-500">
                                Code pasted into the below input field will be
                                injected immediately after the opening{" "}
                                <strong>{`<body>`}</strong> tag of the landing
                                page
                            </p>
                            <div className="mt-2">
                                <textarea
                                    id="trackingPixelStartBody"
                                    name="trackingPixelStartBody"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={
                                        landingPageData.trackingPixelStartBody
                                    }
                                    onChange={
                                        handleTrackingPixelStartBodyChange
                                    }
                                />
                            </div>
                            <p className="mt-3 text-sm text-gray-500">
                                Code pasted into the below input field will be
                                injected immediately before the closing{" "}
                                <strong>{`</body>`}</strong> tag of the landing
                                page
                            </p>
                            <div className="mt-2">
                                <textarea
                                    id="trackingPixelEndBody"
                                    name="trackingPixelEndBody"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={
                                        landingPageData.trackingPixelEndBody
                                    }
                                    onChange={handleTrackingPixelEndBodyChange}
                                />
                            </div>
                        </div>

                        <div>
                            <h2
                                htmlFor="project-name"
                                className="block text-md font-medium leading-6 text-gray-900"
                            >
                                Email
                            </h2>
                            <p className="mt-2 text-sm text-gray-500">
                                This is the email that will be sent to your
                                customer after they claim their coupon code.
                                Available shortcodes are{" "}
                                {`{firstName}, {lastName}, and {couponCode}`}
                            </p>
                            <p className="mt-2 text-sm text-gray-500">From:</p>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    id="from"
                                    name="from"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={landingPageData.from}
                                    onChange={handleFromChange}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">
                                Subject:
                            </p>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={landingPageData.subject}
                                    onChange={handleSubjectChange}
                                />
                            </div>
                            <p className="mt-3 text-sm text-gray-500">Email</p>
                            <div className="mt-2">
                                <textarea
                                    id="email"
                                    name="email"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                    defaultValue={landingPageData.email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <button
                                type="button" // use 'button' type to prevent form submission
                                onClick={handleBackButtonClick}
                                className="flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-700 px-8"
                            >
                                Back
                            </button>
                            <button
                                type="submit"
                                className="flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 px-8"
                            >
                                {isLoading ? "Loading..." : "Save Landing Page"}
                            </button>
                        </div>
                    </div>
                </form>
            </main>
        </>
    );
}
