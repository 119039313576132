import { useCallback, useEffect, useRef, useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import {
    EnvelopeOpenIcon,
    XMarkIcon,
    UserIcon,
} from "@heroicons/react/24/outline";
import { Tab } from "@headlessui/react";
import StarRating from "./Starrating";
import styled from "styled-components";
import Trending from "./Trending";
import Footer from "./Footer";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const ResponsiveContainer = styled.div`
    position: relative;
    width: 100%;
`;

const AspectRatioWrapper = styled.div`
    position: relative;
    padding-bottom: 100%; /* Set the aspect ratio (e.g., 1:1 would be 100%) */
`;

const Content = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Display the full image without cropping */
    object-position: center;
`;

export default function LandingTemplate({
    landingPageId,
    headline,
    title,
    asin,
    bullet_descriptions,
    images,
    rating,
    couponCodes,
    ratings_count,
    showRatings,
    showRatingsCount,
    showRatingsAverage,
    price,
    salePrice,
    reviews,
    countdownConfig,
    showCountdown,
    showNavigation,
    showLogo,
    logoFile,
    showTrending,
    showAmazonCouponCount,
    showDiscountFlag,
    trackingPixelHead,
    trackingPixelStartBody,
    trackingPixelEndBody,
    redemptionCount,
}) {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [hasCountdownEnded, setHasCountdownEnded] = useState(true);
    const [isCodesAvailable, setIsCodesAvailable] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        document.title = `${title}`;
    }, [title]);

    const totalCoupons = couponCodes[0] ? couponCodes[0].split(",").length : 0;

    const location = useLocation();
    const isLandingPage = /^\/landingpage\/([0-9a-fA-F]{24})$/.test(
        location.pathname
    );
    const isEditPage = location.pathname.includes("/edit/");

    const logoPreview = logoFile ? (
        <img
            src={`data:image/jpeg;base64,${logoFile}`}
            alt="Logo preview"
            className="h-12"
        />
    ) : null;

    let priceInDollars = price / 100;
    let salePriceInDollars = salePrice / 100;

    let discountPercentage = ((price - salePrice) / price) * 100;

    discountPercentage = Math.round(discountPercentage);

    const countdownRef1 = useRef(null);

    const calculateTimeLeft = useCallback(() => {
        const currentTime = new Date().getTime();
        const remainingTime =
            new Date(countdownConfig.endDate).getTime() - currentTime;

        if (remainingTime <= 0) {
            return {
                days: "0",
                hours: "0",
                minutes: "0",
                seconds: "0",
            };
        }

        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        return {
            days: days < 10 ? `0${days}` : days,
            hours: hours < 10 ? `0${hours}` : hours,
            minutes: minutes < 10 ? `0${minutes}` : minutes,
            seconds: seconds < 10 ? `0${seconds}` : seconds,
        };
    }, [countdownConfig]); // countdownConfig is a dependency here, the function will only recompute when countdownConfig changes

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        countdownRef1.current = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearInterval(countdownRef1.current);
        };
    }, [calculateTimeLeft]);

    useEffect(() => {
        // Function to update the `hasCountdownEnded` state
        const updateTimeLeft = () => {
            const timeLeft = calculateTimeLeft();

            if (
                timeLeft.days !== "0" ||
                timeLeft.hours !== "0" ||
                timeLeft.minutes !== "0" ||
                timeLeft.seconds !== "0"
            ) {
                setHasCountdownEnded(false);
            } else {
                setHasCountdownEnded(true);
            }
        };

        // Call this function once initially
        updateTimeLeft();

        // This function will be called every second to update the countdown
        const timer = setInterval(updateTimeLeft, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    useEffect(() => {
        const handleEscape = (event) => {
            if (!showModal) return;

            if (event.key === "Escape") {
                setShowModal(false);
            }
        };

        document.addEventListener("keydown", handleEscape);
        return () => document.removeEventListener("keydown", handleEscape);
    }, [showModal]);

    const startScriptRef = useRef(null);
    const endScriptRef = useRef(null);
    const headScriptRef = useRef(null);

    useEffect(() => {
        if (isLandingPage && !isEditPage) {
            startScriptRef.current = document.createElement("script");
            startScriptRef.current.innerHTML = trackingPixelStartBody;
            document.body.prepend(startScriptRef.current);

            endScriptRef.current = document.createElement("script");
            endScriptRef.current.innerHTML = trackingPixelEndBody;
            document.body.appendChild(endScriptRef.current);

            headScriptRef.current = document.createElement("script");
            headScriptRef.current.innerHTML = trackingPixelHead;
            document.head.appendChild(headScriptRef.current);

            // Make sure to clean up after the component unmounts
            return () => {
                document.body.removeChild(startScriptRef.current);
                document.body.removeChild(endScriptRef.current);
                document.head.removeChild(headScriptRef.current);
            };
        }
    }, [
        trackingPixelStartBody,
        trackingPixelEndBody,
        trackingPixelHead,
        isLandingPage,
        isEditPage,
    ]);

    useEffect(() => {
        // if there is a string of coupon codes, split it into an array of codes
        let codesArray =
            couponCodes && couponCodes.length > 0
                ? couponCodes[0].split(",")
                : [];

        // trim the resulting strings and filter out any empty strings
        codesArray = codesArray
            .map((code) => code.trim())
            .filter((code) => code !== "");

        // if the length of the array of coupon codes is greater than 0, there are still codes left
        setIsCodesAvailable(codesArray.length > 0);
    }, [couponCodes]);

    if (!couponCodes) {
        return null; // or a loading spinner, or some fallback component
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        const response = await fetch(
            "https://api.gotomarket.tools/api/coupon/send",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    fname: event.target.fname.value,
                    lname: event.target.lname.value,
                    email: event.target.email.value,
                    phone: event.target.phone.value,
                    asin: asin,
                    landingPageId: landingPageId,
                }).toString(),
            }
        );

        const responseData = await response.json(); // Extract the JSON from the response

        if (response.ok) {
            setLoading(false);
            setCompleted(true);
            setErrorMessage(null); // If the request was successful, reset the error message
        } else {
            // Handle the error
            setLoading(false);
            // If there was an error, save it in the state
            setErrorMessage(responseData.error);
            if (responseData.error === "Redemption limit hit") {
                // If redemption limit hit, consider it as completed
                setCompleted(true);
            }
        }
    };

    return (
        <>
            <style>
                {`
        .countdown-section-1 .countdown-item {
          display: inline-flex;
          flex-direction: column;
          font-size: 1.25rem;
          font-weight:600;
          align-items: center;
        }

        .countdown-section-1 .countdown-item::after {
          content: attr(data-label);
          display: block;
          font-size: 0.75rem;
          font-weight: 500;
          margin-top: 0.25rem;
          color: #ffffff
        }

        .countdown-section-2 .countdown-item {
            display: inline-flex;
            flex-direction: column;
            font-size: 1.5rem;
            font-weight:800;
            align-items: center;
          }
  
          .countdown-section-2 .countdown-item::after {
            content: attr(data-label);
            display: block;
            font-size: 0.75rem;
            font-weight: 500;
            margin-top: 0.25rem;
            color: #ffffff
          }

          .countdown-section-3 .countdown-item {
            display: inline-flex;
            flex-direction: column;
            font-size: 1.5rem;
            font-weight:800;
            align-items: center;
          }
  
          .countdown-section-3 .countdown-item::after {
            content: attr(data-label);
            display: block;
            font-size: 0.75rem;
            font-weight: 500;
            margin-top: 0.25rem;
            color: #ffffff
          }

          .square {
            position: absolute;
            left:30px;
            top:-10px;
            width: 90px;
            height: 70px;
            z-index:20;
            color:#fff;
            font-weight:600;
            padding: 22px 0px;
            text-align:center;
          }
          
          .square::before {
            content: "";
            position: absolute;
            top: 0px;
            left: -10px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 0;
            border-right: 10px solid #991b1b;
          }
          
          .arrow-left {
            position: relative;
            z-index: 1;
          }
        `}
            </style>
            <div className="bg-white">
                {showNavigation && (
                    <header
                        id="sticky-nav-header"
                        className="bg-gray-900 py-4 relative"
                    >
                        <div className="mx-auto sm:px-6 md:px-0 lg:max-w-7xl">
                            <div className="mx-auto w-full max-w-2xl sm:block lg:max-w-none">
                                <div className="mx-auto px-4 sm:px-0 lg:px-8 pt-0 lg:max-w-7xl grid grid-cols-2 sm:grid-cols-3 items-center">
                                    <div className="hidden sm:block sm:col-span-1">
                                        {showLogo && logoPreview}
                                    </div>
                                    <div className="col-span-1 flex flex-col items-center">
                                        {showCountdown && (
                                            <>
                                                <div className="text-sm font-semibold mb-1 text-orange-500">
                                                    SALE ENDS IN
                                                </div>
                                                <div className="flex justify-center space-x-4 countdown-section-1 text-white">
                                                    <span
                                                        className="countdown-item countdown-days"
                                                        data-label="DAYS"
                                                    >
                                                        <span className="font-bold">
                                                            {timeLeft.days}
                                                        </span>
                                                    </span>
                                                    <span
                                                        className="countdown-item countdown-hours"
                                                        data-label="HOURS"
                                                    >
                                                        <span className="font-bold">
                                                            {timeLeft.hours}
                                                        </span>
                                                    </span>
                                                    <span
                                                        className="countdown-item countdown-minutes"
                                                        data-label="MINS"
                                                    >
                                                        <span className="font-bold">
                                                            {timeLeft.minutes}
                                                        </span>
                                                    </span>
                                                    <span
                                                        className="countdown-item countdown-seconds"
                                                        data-label="SEC"
                                                    >
                                                        <span className="font-bold">
                                                            {timeLeft.seconds}
                                                        </span>
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-span-1">
                                        <div
                                            id="header-cta"
                                            className="header-section text-right"
                                        >
                                            <button
                                                type="button"
                                                className={`${
                                                    hasCountdownEnded ||
                                                    !isCodesAvailable
                                                        ? "bg-gray-500 cursor-not-allowed"
                                                        : "bg-orange-500 hover:bg-orange-600"
                                                } text-white font-semibold py-4 px-6 rounded-md`}
                                                onClick={() =>
                                                    setShowModal(true)
                                                }
                                                disabled={
                                                    hasCountdownEnded ||
                                                    !isCodesAvailable
                                                }
                                            >
                                                {hasCountdownEnded
                                                    ? "Time Has Expired"
                                                    : !isCodesAvailable
                                                    ? "No Coupons Remaining"
                                                    : "Claim Coupon"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                )}

                <div className="mx-auto px-4 sm:px-6 pt-0 lg:pt-8 lg:max-w-7xl lg:px-8">
                    {/* Product */}
                    <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                        <div className="mx-auto my-6 lg:hidden w-full max-w-2xl sm:block lg:max-w-none">
                            <div className="flex lg:hidden flex-col-reverse">
                                <div>
                                    <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                        {headline}
                                    </h1>
                                    <h2 className="text-lg tracking-tight text-gray-900 sm:text-lg mt-3">
                                        {title}
                                    </h2>

                                    {showTrending && (
                                        <>
                                            <Trending />
                                        </>
                                    )}

                                    <div className="mt-4 mb-4 lg:mb-0">
                                        <p className="text-6xl font-bold tracking-tight text-orange-500 inline-flex items-baseline">
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            }).format(salePriceInDollars)}
                                            <span className="text-gray-500 line-through text-2xl mr-2 ml-2 font-normal">
                                                {new Intl.NumberFormat(
                                                    "en-US",
                                                    {
                                                        style: "currency",
                                                        currency: "USD",
                                                    }
                                                ).format(priceInDollars)}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <StarRating
                                        rating={rating}
                                        ratings_count={ratings_count}
                                        showRatings={showRatings}
                                        showRatingsCount={showRatingsCount}
                                        showRatingsAverage={showRatingsAverage}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Image gallery */}
                        <Tab.Group
                            as="div"
                            className="flex flex-col-reverse lg:col-span-4 lg:row-end-1"
                        >
                            {/* Image selector */}
                            <div className="mx-auto mt-6 block w-full max-w-2xl lg:max-w-none">
                                <Tab.List className="grid grid-cols-4 gap-6">
                                    {images.map((image, index) => (
                                        <Tab
                                            key={index}
                                            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span className="sr-only">
                                                        Image {index + 1}
                                                    </span>
                                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                                        <div className="h-full w-full object-cover">
                                                            <img
                                                                src={`data:image/jpeg;base64,${image}`}
                                                                alt="Product"
                                                                className="h-full w-full object-cover object-center"
                                                            />
                                                        </div>
                                                    </span>
                                                    <span
                                                        className={classNames(
                                                            selected
                                                                ? "ring-orange-500"
                                                                : "ring-transparent",
                                                            "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </>
                                            )}
                                        </Tab>
                                    ))}
                                </Tab.List>
                            </div>

                            <div className="mx-auto w-full max-w-2xl block lg:max-w-none relative z-10">
                                <div className="drop-shadow-3xl bg-white p-4">
                                    {showDiscountFlag && (
                                        <div className="square bg-orange-500">
                                            <div className="arrow-left">
                                                {discountPercentage}% Off
                                            </div>
                                        </div>
                                    )}
                                    <Tab.Panels className="lg:col-span-4 lg:row-end-1 z-0">
                                        {images.map((image, index) => (
                                            <Tab.Panel key={index}>
                                                <ResponsiveContainer>
                                                    <AspectRatioWrapper>
                                                        <Content
                                                            src={`data:image/jpeg;base64,${image}`}
                                                            alt="Product"
                                                        />
                                                    </AspectRatioWrapper>
                                                </ResponsiveContainer>
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </div>
                            </div>
                        </Tab.Group>

                        {/* Product details */}
                        <div className="mx-auto mt-8 max-w-2xl sm:mt-8 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                            <div className="hidden lg:flex flex-col-reverse">
                                <div>
                                    <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                        {headline}
                                    </h1>
                                    <h2 className="text-lg tracking-tight text-gray-900 sm:text-lg mt-3">
                                        {title}
                                    </h2>

                                    {showTrending && (
                                        <>
                                            <Trending />
                                        </>
                                    )}

                                    <div className="mt-4">
                                        <p className="text-6xl font-bold tracking-tight text-orange-500 inline-flex items-baseline">
                                            {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            }).format(salePriceInDollars)}
                                            <span className="text-gray-500 line-through text-2xl mr-2 ml-2 font-normal">
                                                {new Intl.NumberFormat(
                                                    "en-US",
                                                    {
                                                        style: "currency",
                                                        currency: "USD",
                                                    }
                                                ).format(priceInDollars)}
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <StarRating
                                        rating={rating}
                                        ratings_count={ratings_count}
                                        showRatings={showRatings}
                                        showRatingsCount={showRatingsCount}
                                        showRatingsAverage={showRatingsAverage}
                                    />
                                </div>
                            </div>

                            {/* <p className="mb-6 mt-0 lg:my-6 text-gray-800">
                                {description}
                            </p> */}

                            <div className="bg-gray-900 rounded-3xl p-8 xl:p-10 mt-8">
                                <div className="flex justify-center items-center">
                                    <div className="flex flex-col items-center">
                                        <div className="text-md font-semibold mb-1 text-orange-500">
                                            SALE ENDS IN
                                        </div>
                                        <div className="flex justify-center space-x-4 countdown-section-2 text-white">
                                            <span
                                                className="countdown-item countdown-days"
                                                data-label="DAYS"
                                            >
                                                <span className="font-bold">
                                                    {timeLeft.days}
                                                </span>
                                            </span>
                                            <span
                                                className="countdown-item countdown-hours"
                                                data-label="HOURS"
                                            >
                                                <span className="font-bold">
                                                    {timeLeft.hours}
                                                </span>
                                            </span>
                                            <span
                                                className="countdown-item countdown-minutes"
                                                data-label="MINS"
                                            >
                                                <span className="font-bold">
                                                    {timeLeft.minutes}
                                                </span>
                                            </span>
                                            <span
                                                className="countdown-item countdown-seconds"
                                                data-label="SEC"
                                            >
                                                <span className="font-bold">
                                                    {timeLeft.seconds}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={`${
                                        hasCountdownEnded || !isCodesAvailable
                                            ? "bg-gray-500 cursor-not-allowed"
                                            : "bg-orange-500 hover:bg-orange-600"
                                    } text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-orange-500 mt-6 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer`}
                                    onClick={() => setShowModal(true)}
                                    disabled={
                                        hasCountdownEnded || !isCodesAvailable
                                    }
                                >
                                    {hasCountdownEnded
                                        ? "Time Has Expired"
                                        : !isCodesAvailable
                                        ? "No Coupons Remaining"
                                        : "Claim Coupon"}
                                </button>
                            </div>
                            {showAmazonCouponCount && (
                                <div className="border-none lg:border-t border-gray-200 pt-8 px-8">
                                    <div className="flex w-full">
                                        {/* Left column */}
                                        <div className="w-1/2">
                                            <img
                                                className="h-auto w-32 mx-auto"
                                                src="/img/amazon.png"
                                                alt="Amazon Logo"
                                            />
                                        </div>

                                        {/* Right column */}
                                        <div className="w-1/2 flex flex-col justify-center items-center border-l-2">
                                            <div className="flex items-center">
                                                <span className="text-center coupon-count coupons-left font-bold">
                                                    {totalCoupons}
                                                    &nbsp;
                                                </span>
                                                <span className="text-center">
                                                    coupons left
                                                </span>
                                            </div>
                                            <div className="flex items-center">
                                                <span className="text-center coupon-count coupons-used font-bold">
                                                    {redemptionCount}&nbsp;
                                                </span>
                                                <span className="text-center">
                                                    coupons used
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="border-none lg:border-t border-gray-200 pt-6">
                                <div className="border-b border-gray-200">
                                    <div className="-mb-px flex space-x-8">
                                        <div className="border-transparent text-gray-900 whitespace-nowrap border-b-2 py-6 text-xl font-medium">
                                            Product Description
                                        </div>
                                    </div>
                                </div>
                                <div className="prose prose-md mt-8 text-gray-900">
                                    <div
                                        className="prose prose-md mt-8 text-gray-900"
                                        dangerouslySetInnerHTML={{
                                            __html: bullet_descriptions,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                            <div className="border-b border-gray-200">
                                <div className="-mb-px flex space-x-8">
                                    <div className="border-transparent text-gray-900 whitespace-nowrap border-b-2 py-6 text-xl font-medium">
                                        Customer Reviews
                                    </div>
                                </div>
                            </div>
                            <div className="-mb-10">
                                {reviews.map((review, reviewIdx) => (
                                    <div
                                        key={review.id}
                                        className="flex space-x-4 text-sm text-gray-900"
                                    >
                                        <div className="flex-none py-10">
                                            <UserIcon className="h-10 w-10 rounded-full bg-gray-100 p-1" />
                                        </div>

                                        <div
                                            className={classNames(
                                                reviewIdx === 0
                                                    ? ""
                                                    : "w-full border-t border-gray-200",
                                                "py-10"
                                            )}
                                        >
                                            <h3 className="font-medium text-gray-900">
                                                {review.name}{" "}
                                                {/* Use review.name instead of review.author */}
                                            </h3>

                                            <div className="mt-4 flex items-center">
                                                {[0, 1, 2, 3, 4].map(
                                                    (rating) => (
                                                        <StarIcon
                                                            key={rating}
                                                            className={classNames(
                                                                review.rating >
                                                                    rating
                                                                    ? "text-yellow-400"
                                                                    : "text-gray-300",
                                                                "h-5 w-5 flex-shrink-0"
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    )
                                                )}
                                            </div>
                                            <p className="sr-only">
                                                {review.rating} out of 5 stars
                                            </p>

                                            <div
                                                className="prose prose-md mt-4 max-w-none text-gray-900"
                                                dangerouslySetInnerHTML={{
                                                    __html: review.text /* Use review.text instead of review.content */,
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div className="block lg:hidden mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                            <div className="mt-10 border-none lg:border-t border-gray-200 pt-10">
                                <div className="border-b border-gray-200">
                                    <div className="-mb-px flex space-x-8">
                                        <div className="border-transparent text-gray-900 whitespace-nowrap border-b-2 py-6 text-xl font-medium">
                                            Share
                                        </div>
                                    </div>
                                </div>
                                <ul
                                    role="list"
                                    className="mt-8 flex items-center space-x-6"
                                >
                                    <li>
                                        <a
                                            href="#"
                                            className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                                        >
                                            <span className="sr-only">
                                                Share on Facebook
                                            </span>
                                            <svg
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                                        >
                                            <span className="sr-only">
                                                Share on Instagram
                                            </span>
                                            <svg
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                                        >
                                            <span className="sr-only">
                                                Share on Twitter
                                            </span>
                                            <svg
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="relative mx-auto px-4 sm:px-6 pt-0 max-w-3xl lg:px-8 top-16">
                    <div className="bg-gray-900 rounded-3xl p-8 xl:p-14 mt-8">
                        <div className="flex justify-center items-center">
                            <div className="flex flex-col items-center">
                                <div className="text-5xl font-bold mb-8 text-white text-center">
                                    What Are You Waiting For?
                                </div>
                                <div className="text-md font-semibold mb-1 text-orange-500">
                                    SALE ENDS IN
                                </div>
                                <div className="flex justify-center space-x-4 countdown-section-3 text-white">
                                    <span
                                        className="countdown-item countdown-days"
                                        data-label="DAYS"
                                    >
                                        <span className="font-bold">
                                            {timeLeft.days}
                                        </span>
                                    </span>
                                    <span
                                        className="countdown-item countdown-hours"
                                        data-label="HOURS"
                                    >
                                        <span className="font-bold">
                                            {timeLeft.hours}
                                        </span>
                                    </span>
                                    <span
                                        className="countdown-item countdown-minutes"
                                        data-label="MINS"
                                    >
                                        <span className="font-bold">
                                            {timeLeft.minutes}
                                        </span>
                                    </span>
                                    <span
                                        className="countdown-item countdown-seconds"
                                        data-label="SEC"
                                    >
                                        <span className="font-bold">
                                            {timeLeft.seconds}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button
                            className={`${
                                hasCountdownEnded || !isCodesAvailable
                                    ? "bg-gray-500 cursor-not-allowed"
                                    : "bg-orange-500 hover:bg-orange-600"
                            } text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-orange-500 mt-6 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-pointer`}
                            onClick={() => setShowModal(true)}
                            disabled={hasCountdownEnded || !isCodesAvailable}
                        >
                            {hasCountdownEnded
                                ? "Time Has Expired"
                                : !isCodesAvailable
                                ? "No Coupons Remaining"
                                : "Claim Coupon"}
                        </button>
                    </div>
                </div>
                <Footer />
                {isEditPage
                    ? null
                    : showModal && (
                          <div
                              className="fixed inset-0 z-10 overflow-y-auto"
                              aria-labelledby="modal-title"
                              role="dialog"
                              aria-modal="true"
                              onClick={() => setShowModal(false)}
                          >
                              {/* Centering Flex Container */}
                              <div className="flex items-center justify-center min-h-screen">
                                  {/* Modal Overlay */}
                                  <div
                                      className="fixed inset-0 bg-gray-500 bg-opacity-75 pt-4 px-4 pb-20 transition-opacity"
                                      aria-hidden="true"
                                  ></div>

                                  {/* Modal Box */}
                                  <div
                                      className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
                                      onClick={(e) => e.stopPropagation()}
                                  >
                                      <div>
                                          <div className="mt-3 flex flex-col items-center sm:mt-5">
                                              <button
                                                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                                  onClick={() =>
                                                      setShowModal(false)
                                                  }
                                              >
                                                  <XMarkIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                  />
                                              </button>
                                              <EnvelopeOpenIcon className="w-12 mb-4" />
                                              <h3
                                                  className="text-3xl leading-6 font-bold text-orange"
                                                  id="modal-title"
                                              >
                                                  Claim Your Coupon
                                              </h3>
                                              <p className="mt-3 text-center">
                                                  Enter your details below to
                                                  claim your coupon.
                                              </p>
                                              <form
                                                  className="w-full"
                                                  onSubmit={handleSubmit}
                                              >
                                                  <div className="mt-6">
                                                      <input
                                                          id="fname"
                                                          name="fname"
                                                          type="text"
                                                          autoComplete="fname"
                                                          placeholder="First Name"
                                                          required
                                                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                                      />
                                                  </div>
                                                  <div className="mt-3">
                                                      <input
                                                          id="lname"
                                                          name="lname"
                                                          type="text"
                                                          autoComplete="lname"
                                                          placeholder="Last Name"
                                                          required
                                                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                                      />
                                                  </div>
                                                  <div className="mt-3">
                                                      <input
                                                          id="email"
                                                          name="email"
                                                          type="email"
                                                          autoComplete="email"
                                                          placeholder="Email Address"
                                                          required
                                                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                                      />
                                                  </div>
                                                  <div className="mt-3">
                                                      <input
                                                          id="phone"
                                                          name="phone"
                                                          type="text"
                                                          autoComplete="phone"
                                                          placeholder="Cell Phone Number"
                                                          required
                                                          className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                                                      />
                                                  </div>

                                                  <button
                                                      type="submit"
                                                      disabled={
                                                          loading ||
                                                          completed ||
                                                          hasCountdownEnded ||
                                                          !isCodesAvailable
                                                      }
                                                      className={`flex w-full justify-center bg-orange rounded-md px-7 py-4 text-md font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 mt-6 ${
                                                          loading ||
                                                          completed ||
                                                          hasCountdownEnded ||
                                                          !isCodesAvailable
                                                              ? "bg-gray-500"
                                                              : "bg-orange-500 hover:bg-orange-600"
                                                      }`}
                                                  >
                                                      {loading
                                                          ? "Loading..."
                                                          : completed
                                                          ? errorMessage ===
                                                            "Redemption limit hit"
                                                              ? "Redemption Limit Reached"
                                                              : "Email Sent!"
                                                          : hasCountdownEnded
                                                          ? "Time Has Expired"
                                                          : !isCodesAvailable
                                                          ? "No Coupons Remaining"
                                                          : "Claim Coupon"}
                                                  </button>
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      )}
            </div>
        </>
    );
}
