import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../context/AuthContext";
import Spinner from "./Templates/Spinner";
import { Link } from "react-router-dom";
import { StarIcon } from "@heroicons/react/20/solid";
import { StarIcon as StarOutlineIcon } from "@heroicons/react/24/outline";

const fetchData = async (userToken) => {
    try {
        const response = await fetch(
            "https://api.gotomarket.tools/api/reviews/get",
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        );
        if (!response.ok) {
            throw new Error("Failed to fetch review data");
        }
        const data = await response.json();
        return data.reviews;
    } catch (error) {
        console.error("Fetching review data failed:", error);
        return []; // Return an empty array in case of error to ensure .map can still function
    }
};

export default function ReviewsList() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleEscape = (event) => {
            if (!showModal) return;

            if (event.key === "Escape") {
                setShowModal(false);
            }
        };

        document.addEventListener("keydown", handleEscape);
        return () => document.removeEventListener("keydown", handleEscape);
    }, [showModal]);

    const {
        data: review,
        status,
        error,
    } = useQuery(["review", userToken], () => fetchData(userToken), {
        enabled: !!userToken,
    });

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return <p>Error: {error.message}</p>;
    }

    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flow-root">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">
                                    Reviews
                                </h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    A list of Reviews on this account
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Reviewer Name
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Survey Name
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Product Reviewed
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Rating
                                                </th>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {review.length === 0 ? (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="py-4 text-center text-sm"
                                                    >
                                                        No Reviews collected.
                                                    </td>
                                                </tr>
                                            ) : (
                                                review.map((reviews) => (
                                                    <tr key={reviews._id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {`${reviews.firstName} ${reviews.lastName}`}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {`${reviews.surveyName}`}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {`${reviews.productName}`}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            <div className="flex items-center">
                                                                {[
                                                                    ...Array(5),
                                                                ].map(
                                                                    (
                                                                        _,
                                                                        index
                                                                    ) =>
                                                                        index <
                                                                        reviews.rating ? (
                                                                            <StarIcon
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="h-6 w-6 text-yellow-400"
                                                                            />
                                                                        ) : (
                                                                            <StarOutlineIcon
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="h-6 w-6 text-gray-300"
                                                                            />
                                                                        )
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            <Link
                                                                to={`${reviews._id}`}
                                                                title="View"
                                                                className="text-sky-600 hover:text-sky-900 mr-2"
                                                            >
                                                                View
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Fallback JSX if none of the above conditions are met
    return null;
}
