import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"; // Import useParams to get the ID from URL

export default function EditAmbassadorFormFields() {
    const [productName, setProductName] = useState("");
    const [image, setImage] = useState("");
    const [keyword, setKeyword] = useState("");
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const { id } = useParams(); // Assuming "pageId" is the URL parameter name
    const navigate = useNavigate(); // Create an instance of useNavigate

    useEffect(() => {
        // Fetch the initial data for the ambassador program
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://api.gotomarket.tools/api/ambassadorproduct/${id}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                );
                if (response.data) {
                    setProductName(response.data.productName);
                    setKeyword(response.data.keyword);
                    setImage(response.data.image);
                }
            } catch (error) {
                console.error("Error fetching program data:", error);
            }
        };
        fetchData();
    }, [id, userToken]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace(/^data:(.*,)?/, "");
                setImage(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.patch(
                `https://api.gotomarket.tools/api/ambassadorproduct/${id}`,
                {
                    account: user.account._id,
                    productName: productName,
                    keyword: keyword,
                    image: image,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            navigate(-1);
        } catch (error) {
            console.error(
                "Error updating program:",
                error.response?.data || error.message
            );
        }
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <label className="block text-sm font-medium text-gray-900">
                    Product Name
                </label>
                <input
                    type="text"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                    required
                />

                <label className="block text-sm font-medium text-gray-900">
                    Keyword
                </label>
                <input
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                    required
                />

                <label className="block text-sm font-medium text-gray-900">
                    Image
                </label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="w-full mt-1 mb-2 px-3 py-2 border shadow-sm focus:ring-orange-500 focus:border-orange-500 block sm:text-sm border-gray-300 rounded-md"
                />

                <label className="block text-sm font-medium text-gray-900">
                    Current Image
                </label>
                <img
                    src={`data:image/jpeg;base64,${image}`}
                    className="max-w-16 max-h-16 object-contain"
                    alt="Product Image"
                />

                <button
                    type="submit"
                    className="flex w-full m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8 mt-6"
                >
                    Update
                </button>
            </form>
        </div>
    );
}
