import { useEffect } from "react";
import AmbassadorsList from "../components/AmbassadorsList";

export default function Ambassador() {
    useEffect(() => {
        document.title = "Ambassador Programs - gotomarket.tools";
    }, []);

    return (
        <>
            <AmbassadorsList />
        </>
    );
}
