import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StarIcon } from "@heroicons/react/20/solid";
import { StarIcon as StarOutlineIcon } from "@heroicons/react/24/outline";

export default function SurveyTemplate() {
    const { id } = useParams();

    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                const response = await axios.get(
                    `https://api.gotomarket.tools/api/surveys/getPublicSurvey/${id}`
                );
                console.log(response.data);
                setProducts(response.data.products);
                setTitle(response.data.surveyName);
                setRatingReviewCollection(response.data.ratingReviewCollection);
                setTrackingPixelsBeforeHead(
                    response.data.trackingPixels.headBefore
                );
                setTrackingPixelsAfterHead(
                    response.data.trackingPixels.headAfter
                );
                setTrackingPixelsBeforeBody(
                    response.data.trackingPixels.bodyBefore
                );
                setTrackingPixelsAfterBody(
                    response.data.trackingPixels.bodyAfter
                );
                setLeaveReviewOnMarketplace(
                    response.data.leaveReviewOnMarketplace
                );
                if (response.data.products.length === 1) {
                    setSelectedProduct(response.data.products[0]);
                    setGiveaways(response.data.products[0].giveaways);
                }
                if (response.data.trackingPixels) {
                    injectScript(
                        headAfterScriptRef,
                        "head",
                        "prepend",
                        response.data.trackingPixels.headAfter
                    );
                    injectScript(
                        headBeforeScriptRef,
                        "head",
                        "append",
                        response.data.trackingPixels.headBefore
                    );
                    injectScript(
                        bodyAfterScriptRef,
                        "body",
                        "prepend",
                        response.data.trackingPixels.bodyAfter
                    );
                    injectScript(
                        bodyBeforeScriptRef,
                        "body",
                        "append",
                        response.data.trackingPixels.bodybefore
                    );
                }
            } catch (error) {
                console.error("Failed to fetch survey data:", error);
            }
        };

        fetchSurveyData();
    }, [id]);

    const validateOrderNumber = (number) => {
        const regex = /^\d{3}-\d{7}-\d{7}$/; // Regex to match the Amazon order format
        return regex.test(number);
    };

    const handleOrderNumberChange = (e) => {
        const value = e.target.value;
        setOrderNumber(value);
        setOrderNumberValid(validateOrderNumber(value));
    };

    const [step, setStep] = useState(1);
    const [orderNumber, setOrderNumber] = useState("");
    const [orderNumberValid, setOrderNumberValid] = useState(false);
    const [title, setTitle] = useState("");
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedGiveaway, setSelectedGiveaway] = useState(null);
    const [giveaways, setGiveaways] = useState([]);
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [review, setReview] = useState("");
    const [buttonText, setButtonText] = useState("Copy Text");
    const [ratingReviewCollection, setRatingReviewCollection] = useState(
        "Do not require either"
    );
    const [leaveReviewOnMarketplace, setLeaveReviewOnMarketplace] =
        useState("");
    const [hasCopied, setHasCopied] = useState(false);
    const [hasPasted, setHasPasted] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [trackingPixelsBeforeHead, setTrackingPixelsBeforeHead] =
        useState("");
    const [trackingPixelsAfterHead, setTrackingPixelsAfterHead] = useState("");
    const [trackingPixelsBeforeBody, setTrackingPixelsBeforeBody] =
        useState("");
    const [trackingPixelsAfterBody, setTrackingPixelsAfterBody] = useState("");
    const headBeforeScriptRef = useRef(null);
    const headAfterScriptRef = useRef(null);
    const bodyBeforeScriptRef = useRef(null);
    const bodyAfterScriptRef = useRef(null);

    useEffect(() => {
        if (
            leaveReviewOnMarketplace ===
            "Require customer to leave review on marketplace"
        ) {
            setHasCopied(false);
            setHasPasted(false);
        } else if (
            leaveReviewOnMarketplace ===
            "Leaving review on marketplace optional"
        ) {
            setHasCopied(true);
            setHasPasted(true);
        }
    }, [leaveReviewOnMarketplace]);

    const nextStep = (event) => {
        event.preventDefault();

        if (orderNumberValid) {
            setStep(2);
        }

        if (step === 2) {
            // If we're not supposed to show the marketplace review prompt
            if (
                leaveReviewOnMarketplace ===
                "Do not show marketplace review prompt at all"
            ) {
                setStep(4); // Skip marketplace review step entirely
            } else {
                // Proceed normally to the next step
                setStep(step + 1);
            }
        }
        // Handle scenario where no ratings or reviews are to be collected
        else if (
            step === 1 &&
            ratingReviewCollection === "Do not collect ratings or reviews"
        ) {
            setStep(4); // Directly move to the final step since no ratings/reviews are collected
        }
        // Normal progression to the next step
        else if (step < 6) {
            setStep(step + 1);
        }

        window.scrollTo({ top: 0 });
    };

    const prevStep = (event) => {
        event.preventDefault();

        // When no ratings or reviews are to be collected
        if (ratingReviewCollection === "Do not collect ratings or reviews") {
            // If the user is on step 4, send them back to step 1
            if (step === 4) {
                setStep(1);
            } else {
                setStep(step - 1);
            }
        } else {
            // If the user is on step 2 or higher, simply go back one step
            if (step > 1) {
                setStep(step - 1);
            }
        }

        window.scrollTo({ top: 0 });
    };

    const canProceed = () => {
        switch (ratingReviewCollection) {
            case "Require rating and review":
                return rating > 0 && review.length >= 50;
            case "Require rating":
                return rating > 0;
            case "Do not require either":
                return true;
            case "Do not collect ratings or reviews":
                return false; // This case might not be necessary as the step will be skipped
            default:
                return false;
        }
    };

    const canProceedPersonalInfo = () => {
        // Ensures all required fields are not empty
        return (
            firstName.trim() &&
            lastName.trim() &&
            email.trim() &&
            phone.trim() &&
            address1.trim() &&
            city.trim() &&
            state.trim() &&
            zip.trim()
        );
    };

    const canProceedMarketplaceReview = () => {
        // Check the setting for leaving a review on the marketplace
        switch (leaveReviewOnMarketplace) {
            case "Require customer to leave review on marketplace":
                // Both copy and paste actions must be completed to proceed
                return hasCopied && hasPasted;
            case "Leaving review on marketplace optional":
                // User can proceed without any additional actions
                return true;
            case "Do not show marketplace review prompt at all":
                // This scenario shouldn't ideally call this function as the step should be skipped
                return true;
            default:
                // Handle unexpected values conservatively by not allowing to proceed
                return false;
        }
    };

    const canProceedGiveaway = () => {
        return selectedGiveaway != null; // returns true if a giveaway is selected, false otherwise
    };

    useEffect(() => {
        if (giveaways.length === 1) {
            setSelectedGiveaway(giveaways[0]);
        }
    }, [giveaways]);

    const handleMouseOver = (newHoverRating) => {
        setHoverRating(newHoverRating);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const handleClick = (newRating) => {
        setRating(newRating);
    };

    const ratingsText = {
        1: "Very Poor",
        2: "Poor",
        3: "Below Average",
        4: "Good",
        5: "Great!",
    };

    const handleProductChange = (event) => {
        const productId = event.target.value;
        const product = products.find((p) => p._id === productId);
        setSelectedProduct(product);
        setGiveaways(product.giveaways);
    };

    const handleGiveawayChange = (event) => {
        const giveawayId = event.target.value;
        const giveaway = giveaways.find((g) => g._id === giveawayId);
        setSelectedGiveaway(giveaway);
    };

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(review)
            .then(() => {
                setButtonText("Copied!");
                setHasCopied(true);
                // Automatically allow pasting if copying is required
                if (
                    leaveReviewOnMarketplace ===
                    "Require customer to leave review on marketplace"
                ) {
                    setHasPasted(false);
                }
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
                setButtonText("Failed to copy");
                setTimeout(() => setButtonText("Copy Text"), 1500);
            });
    };

    const pasteToAmazon = () => {
        // Assume action of pasting (this could just be a button enabling logic)
        setHasPasted(true);
    };

    const injectScript = (ref, target, method, scriptContent) => {
        if (!ref.current && scriptContent) {
            ref.current = document.createElement("script");
            ref.current.innerHTML = scriptContent;
            if (method === "append") {
                document[target].appendChild(ref.current);
            } else {
                document[target].prepend(ref.current);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prepare the data payload
        const reviewData = {
            orderNumber,
            productId: selectedProduct?._id,
            productName: selectedProduct?.productName,
            giveawayId: selectedGiveaway?._id,
            giveawayName: selectedGiveaway?.giveawayName,
            surveyName: title,
            rating,
            review,
            firstName,
            lastName,
            email,
            phone,
            address1,
            address2,
            city,
            state,
            zip,
        };

        try {
            // Send data to your server endpoint
            await axios.post(
                "https://api.gotomarket.tools/api/reviews/create",
                reviewData
            );
            // If the submission is successful, navigate to step 6
            setStep(6);
        } catch (error) {
            console.error("Failed to submit survey data:", error);
            // Handle errors, perhaps set an error message state to display to the user
        }
    };

    useEffect(() => {
        if (title) {
            document.title = `${title} - gotomarket.tools`;
        }
    }, [title]);

    switch (step) {
        case 1:
            return (
                <div className="bg-slate-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-gray-400 rounded-lg overflow-hidden">
                            <h2 className="text-center text-xl p-6 border-b border-gray-400 mb-2 font-extrabold bg-slate-100">
                                Share your experience and receive a free gift!
                            </h2>
                            <div className="p-4">
                                <p className="text-center mb-4">
                                    The process is quick and easy. To begin,
                                    please complete the fields below.
                                </p>
                                {products.length === 1 ? (
                                    <>
                                        <div className="bg-slate-50 border rounded border-gray-400 my-6 text-center">
                                            <h2 className="text-lg font-extrabold p-3">
                                                Thank you for purchasing
                                            </h2>
                                            <div className="flex justify-center items-center w-full">
                                                <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                                    <img
                                                        src={`data:image/jpeg;base64,${products[0].productImage}`}
                                                        className="max-h-full object-contain mx-auto"
                                                        alt={
                                                            products[0]
                                                                .productName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <p className="p-3">
                                                    {products[0].productName}!
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : products.length > 1 ? (
                                    <div>
                                        <label className="text-center block text-lg font-bold mb-2">
                                            Which product did you purchase?
                                        </label>
                                        <div className="bg-slate-50 border rounded border-gray-400 p-4">
                                            <label className="block text-xs font-normal text-gray-900 mb-2">
                                                Which product did you purchase?
                                                <div className="inline text-red-500">
                                                    *
                                                </div>
                                            </label>
                                            <select
                                                value={
                                                    selectedProduct
                                                        ? selectedProduct._id
                                                        : ""
                                                }
                                                onChange={handleProductChange}
                                                className="input border-gray-400 focus:ring-sky-500 w-full focus:border-sky-500 bg-slate-50 p-3 rounded"
                                            >
                                                <option value="">
                                                    --Select Product--
                                                </option>
                                                {products.map((product) => (
                                                    <option
                                                        key={product._id}
                                                        value={product._id}
                                                    >
                                                        {product.productName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {selectedProduct && (
                                            <>
                                                <div className="bg-slate-50 border rounded border-gray-400 my-6">
                                                    <h2 className="text-center text-lg p-3 font-extrabold">
                                                        You purchased...{" "}
                                                    </h2>
                                                    {/* Flex container to center the image */}
                                                    <div className="flex justify-center items-center w-full">
                                                        <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                                            {" "}
                                                            {/* You can adjust width as needed */}
                                                            <img
                                                                src={`data:image/jpeg;base64,${selectedProduct.productImage}`} // Ensure productImage is a base64 encoded string
                                                                className="max-h-full object-contain mx-auto" // mx-auto centers the image horizontally within its container
                                                                alt={
                                                                    selectedProduct.productName
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <p className="p-3">
                                                            {
                                                                selectedProduct.productName
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : null}
                                {selectedProduct && (
                                    <>
                                        <p className="text-base font-bold text-center mb-2">
                                            What is your Amazon order number
                                            containing{" "}
                                            {selectedProduct.productName}
                                        </p>
                                        <div className="bg-slate-50 border rounded border-gray-400 p-4">
                                            <label className="block text-xs font-normal text-gray-900 mb-2">
                                                Amazon Order Number
                                                <div className="inline text-red-500">
                                                    *
                                                </div>
                                            </label>
                                            <div className="flex justify-center items-center w-full">
                                                <input
                                                    id="orderNumber"
                                                    type="text"
                                                    placeholder="Amazon Order Number*"
                                                    className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-white p-3 rounded"
                                                    value={orderNumber}
                                                    onChange={
                                                        handleOrderNumberChange
                                                    }
                                                />
                                            </div>
                                            <label className="block text-center text-xs font-normal text-gray-900 mt-2">
                                                <strong>Format:</strong>{" "}
                                                123-1234567-1234567
                                            </label>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="w-full bg-slate-100 border-t border-gray-400 p-4">
                                <button
                                    onClick={nextStep}
                                    disabled={
                                        !selectedProduct && !orderNumberValid
                                    }
                                    className={`text-white font-semibold w-full shadow-sm rounded-md focus-visible:outline-sky-500 block rounded-md py-4 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                        selectedProduct && orderNumberValid
                                            ? "bg-sky-600 hover:bg-sky-700"
                                            : "bg-slate-400 cursor-not-allowed"
                                    }`}
                                >
                                    Next Step
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 2:
            return (
                <div className="bg-slate-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-gray-400  rounded-lg overflow-hidden">
                            <div className="mb-4 relative border-b border-gray-400">
                                <div className="w-full bg-slate-100 h-6">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-gray-400"
                                        style={{ width: "30%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                20% &nbsp;
                                            </span>
                                            (1/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-6 pb-6">
                                <div className="flex justify-center items-center w-full">
                                    <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                        {" "}
                                        {/* You can adjust width as needed */}
                                        <img
                                            src={`data:image/jpeg;base64,${selectedProduct.productImage}`} // Ensure productImage is a base64 encoded string
                                            className="max-h-full object-contain mx-auto" // mx-auto centers the image horizontally within its container
                                            alt={selectedProduct.productName}
                                        />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p className="pt-3 px-3 pb-1">
                                        How was your experience with
                                    </p>
                                    <p className="pb-3 text-xl font-bold">
                                        {selectedProduct.productName}?
                                    </p>
                                </div>
                                <hr />
                                <label className="block text-md mt-4 text-center font-bold text-gray-900 mb-1">
                                    Overall rating
                                    <div className="inline text-red-500">*</div>
                                </label>
                                <div>
                                    <div className="bg-slate-50 border rounded border-gray-400 p-4 text-center">
                                        {[1, 2, 3, 4, 5].map((index) => (
                                            <button
                                                key={index}
                                                onMouseOver={() =>
                                                    handleMouseOver(index)
                                                }
                                                onMouseLeave={handleMouseLeave}
                                                onClick={() =>
                                                    handleClick(index)
                                                }
                                                className="p-1"
                                            >
                                                {index <=
                                                (hoverRating || rating) ? (
                                                    <StarIcon className="h-10 w-10 text-yellow-500" />
                                                ) : (
                                                    <StarOutlineIcon className="h-10 w-10 text-yellow-500" />
                                                )}
                                            </button>
                                        ))}
                                        <p className="text-center mt-2 text-lg font-semibold">
                                            {ratingsText[
                                                hoverRating || rating
                                            ] || "Rate this product"}
                                        </p>
                                    </div>
                                </div>
                                <label className="block text-md mt-4 text-center font-bold text-gray-900 mb-1">
                                    Add a written review
                                    <div className="inline text-red-500">*</div>
                                </label>
                                <textarea
                                    className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-slate-100 p-3 rounded"
                                    value={review}
                                    onChange={(e) => setReview(e.target.value)}
                                    minLength="50" // HTML5 attribute to enforce minimum length
                                />
                                <div className="text-right">
                                    <span>Characters: </span>
                                    <span
                                        className="text-sm"
                                        style={{
                                            color:
                                                review.length >= 50
                                                    ? "green"
                                                    : "red",
                                        }}
                                    >
                                        {review.length}/50
                                    </span>
                                </div>
                            </div>
                            <div className="flex w-full justify-between bg-slate-100 border-t border-gray-400 p-4">
                                <button
                                    onClick={(e) => prevStep(e)}
                                    className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 py-2 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 hover:bg-sky-700"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={nextStep}
                                    disabled={!canProceed()}
                                    className={`text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 py-2 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
                                        canProceed()
                                            ? "bg-sky-600 hover:bg-sky-700"
                                            : "bg-slate-400 cursor-not-allowed"
                                    }`}
                                >
                                    Next Step
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 3:
            return (
                <div className="bg-slate-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-gray-400  rounded-lg overflow-hidden">
                            <div className="mb-4 relative border-b border-gray-400">
                                <div className="w-full bg-slate-100 h-6">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-gray-400"
                                        style={{ width: "30%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                20% &nbsp;
                                            </span>
                                            (1/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-6 pb-6">
                                <div className="text-center">
                                    <p className="px-3 pb-3 text-xl font-bold">
                                        We're glad you're enjoying{" "}
                                        {selectedProduct.productName}!
                                    </p>
                                </div>
                                <div className="flex justify-center items-center w-full">
                                    <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                        {" "}
                                        {/* You can adjust width as needed */}
                                        <img
                                            src={`data:image/jpeg;base64,${selectedProduct.productImage}`} // Ensure productImage is a base64 encoded string
                                            className="max-h-full object-contain mx-auto" // mx-auto centers the image horizontally within its container
                                            alt={selectedProduct.productName}
                                        />
                                    </div>
                                </div>
                                <div className="text-center">
                                    <p className="p-3">
                                        Please consider leaving your review on
                                        Amazon (this is not mandatory to receive
                                        your free gift)
                                    </p>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div className="col-span-1">
                                        <div className="w-full bg-white border border-gray-400 rounded-lg overflow-hidden flex flex-col">
                                            <div className="w-full text-center font-bold bg-slate-100 border-b border-gray-400 p-4">
                                                Step 1 - Copy Text Below
                                            </div>
                                            <div className="p-6 break-all flex-grow">
                                                {review}
                                            </div>
                                            <div className="flex justify-center items-center bg-slate-100 border-t border-gray-400 p-4">
                                                <button
                                                    onClick={copyToClipboard}
                                                    className="px-4 py-2 bg-sky-600 text-white rounded hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-opacity-50"
                                                >
                                                    {buttonText}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="w-full bg-white border border-gray-400 rounded-lg overflow-hidden flex flex-col">
                                            <div className="w-full text-center font-bold bg-slate-100 border-b border-gray-400 p-4">
                                                Step 2 - Paste on Amazon
                                            </div>
                                            <div className="flex justify-center items-center p-6 flex-grow">
                                                <a
                                                    onClick={pasteToAmazon}
                                                    disabled={!hasCopied}
                                                    href={`https://www.amazon.com/review/review-your-purchases/?asin=${selectedProduct.productAsin}`}
                                                    target="_blank"
                                                    className={`px-4 py-2 ${
                                                        hasCopied
                                                            ? "bg-sky-600 hover:bg-sky-700"
                                                            : "bg-slate-400 cursor-not-allowed"
                                                    } text-white rounded focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-opacity-50`}
                                                >
                                                    Paste on Amazon
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full justify-between bg-slate-100 border-t border-gray-400 p-4">
                                <button
                                    onClick={(e) => prevStep(e)}
                                    className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 py-2 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 hover:bg-sky-700"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={nextStep}
                                    disabled={!canProceedMarketplaceReview()}
                                    className={`text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 py-2 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                        canProceedMarketplaceReview()
                                            ? "bg-sky-600 hover:bg-sky-700"
                                            : "bg-slate-400 cursor-not-allowed"
                                    }`}
                                >
                                    Next Step
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 4:
            return (
                <div className="bg-slate-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div className="text-gray-700 flex justify-center font-sans text-sm">
                        <div className="w-128 bg-white border border-gray-400 rounded-lg overflow-hidden">
                            <div className="px-6 pb-6 pt-4 text-center">
                                {giveaways.length > 1 ? (
                                    <>
                                        <h2 className="text-3xl font-bold mb-5">
                                            Thank you for your feedback, Enjoy a
                                            free gift on us!
                                        </h2>
                                        <div className="bg-slate-50 border rounded border-gray-400 p-4">
                                            <label className="block text-lg font-bold mb-2">
                                                Choose your free gift:
                                            </label>
                                            <select
                                                value={
                                                    selectedGiveaway
                                                        ? selectedGiveaway._id
                                                        : ""
                                                }
                                                onChange={handleGiveawayChange}
                                                className="input border-gray-400 focus:ring-sky-500 w-full focus:border-sky-500 bg-slate-50 p-3 rounded"
                                            >
                                                <option value="">
                                                    --Select Giveaway--
                                                </option>
                                                {giveaways.map((giveaway) => (
                                                    <option
                                                        key={giveaway._id}
                                                        value={giveaway._id}
                                                    >
                                                        {giveaway.giveawayName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <h2 className="text-3xl font-bold mb-5">
                                            Thank you for your feedback, Enjoy a
                                            free {giveaways[0].giveawayName} on
                                            us!
                                        </h2>
                                        <div className="bg-slate-50 border rounded border-gray-400 p-4">
                                            <div className="flex justify-center items-center w-full">
                                                <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                                    {" "}
                                                    <img
                                                        src={`data:image/jpeg;base64,${giveaways[0].giveawayImage}`}
                                                        className="max-h-full object-contain mx-auto"
                                                        alt={
                                                            giveaways[0]
                                                                .giveawayName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <p className="pt-3">
                                                    {giveaways[0].giveawayName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {selectedGiveaway && giveaways.length > 1 && (
                                    <>
                                        <div className="bg-slate-50 border rounded border-gray-400 mt-6">
                                            <h2 className="text-center text-lg p-3 font-extrabold">
                                                You selected...{" "}
                                            </h2>
                                            {/* Flex container to center the image */}
                                            <div className="flex justify-center items-center w-full">
                                                <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                                    {" "}
                                                    <img
                                                        src={`data:image/jpeg;base64,${selectedGiveaway.giveawayImage}`}
                                                        className="max-h-full object-contain mx-auto"
                                                        alt={
                                                            selectedGiveaway.giveawayName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <p className="p-3">
                                                    {
                                                        selectedGiveaway.giveawayName
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="flex w-full justify-between bg-slate-100 border-t border-gray-400 p-4">
                                <button
                                    onClick={prevStep}
                                    className="text-white font-semibold shadow-sm rounded-md bg-sky-600 hover:bg-sky-700 py-2 px-4"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={() => setStep(step + 1)} // Assuming you want to increment the step here
                                    disabled={!canProceedGiveaway()} // Calls canProceedGiveaway() to determine if the button should be disabled
                                    className={`text-white font-semibold shadow-sm rounded-md py-2 px-4 ${
                                        canProceedGiveaway()
                                            ? "bg-sky-600 hover:bg-sky-700"
                                            : "bg-slate-400 cursor-not-allowed"
                                    }`}
                                >
                                    Next Step
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 5:
            return (
                <div className="bg-slate-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-full max-w-3xl bg-white border border-gray-400 rounded-lg overflow-hidden">
                            <div className="mb-4 relative border-b border-black">
                                <div className="w-full bg-gray-200 h-6 dark:bg-gray-700">
                                    <div
                                        className="bg-sky-600 h-6 relative border-r border-black"
                                        style={{ width: "30%" }}
                                    >
                                        <span className="text-white text-xs absolute inset-0 flex justify-center items-center">
                                            <span className="font-semibold">
                                                20% &nbsp;
                                            </span>
                                            (1/5)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-6 pb-6 pt-4">
                                <div className="flex justify-center items-center w-full">
                                    <div className="w-1/3 border border-gray-400 p-2 rounded bg-white">
                                        <img
                                            src={`data:image/jpeg;base64,${selectedGiveaway.giveawayImage}`}
                                            className="max-h-full object-contain mx-auto"
                                            alt={selectedGiveaway.giveawayName}
                                        />
                                    </div>
                                </div>
                                <p className="text-center mb-2 text-lg font-bold mt-4">
                                    To receive your free gift, please enter your
                                    information below.
                                </p>
                                <p className="text-center pb-4">
                                    Please double-check all information is
                                    correct before submitting, inverting
                                    incorrect information may result in you not
                                    receiving your gift.
                                </p>
                                <h3 className="text-xl font-semibold mb-3 text-center">
                                    General Contact Information
                                </h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            First Name*
                                        </label>
                                        <input
                                            id="firstName"
                                            type="text"
                                            placeholder="First Name*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Last Name*
                                        </label>
                                        <input
                                            id="lastName"
                                            type="text"
                                            placeholder="Last Name*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={lastName}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Email Address*
                                        </label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="Email Address*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Phone*
                                        </label>
                                        <input
                                            type="text"
                                            value={phone}
                                            onChange={(e) =>
                                                setPhone(e.target.value)
                                            }
                                            placeholder="Phone*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                        />
                                    </div>
                                </div>

                                <h3 className="text-xl font-semibold mb-3 text-center">
                                    Shipping Information
                                </h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Address 1*
                                        </label>
                                        <input
                                            id="address1"
                                            type="text"
                                            placeholder="Address 1*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={address1}
                                            onChange={(e) =>
                                                setAddress1(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            Address 2
                                        </label>
                                        <input
                                            id="address2"
                                            type="text"
                                            placeholder="Address 2"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={address2}
                                            onChange={(e) =>
                                                setAddress2(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            City*
                                        </label>
                                        <input
                                            id="city"
                                            type="text"
                                            placeholder="City*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={city}
                                            onChange={(e) =>
                                                setCity(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            State*
                                        </label>
                                        <select
                                            id="state"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                            value={state}
                                            onChange={(e) =>
                                                setState(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                Select State
                                            </option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">
                                                California
                                            </option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">
                                                Connecticut
                                            </option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">
                                                District Of Columbia
                                            </option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">
                                                Louisiana
                                            </option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">
                                                Massachusetts
                                            </option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">
                                                Minnesota
                                            </option>
                                            <option value="MS">
                                                Mississippi
                                            </option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">
                                                New Hampshire
                                            </option>
                                            <option value="NJ">
                                                New Jersey
                                            </option>
                                            <option value="NM">
                                                New Mexico
                                            </option>
                                            <option value="NY">New York</option>
                                            <option value="NC">
                                                North Carolina
                                            </option>
                                            <option value="ND">
                                                North Dakota
                                            </option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">
                                                Pennsylvania
                                            </option>
                                            <option value="RI">
                                                Rhode Island
                                            </option>
                                            <option value="SC">
                                                South Carolina
                                            </option>
                                            <option value="SD">
                                                South Dakota
                                            </option>
                                            <option value="TN">
                                                Tennessee
                                            </option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">
                                                Washington
                                            </option>
                                            <option value="WV">
                                                West Virginia
                                            </option>
                                            <option value="WI">
                                                Wisconsin
                                            </option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-900 mb-1">
                                            ZIP*
                                        </label>
                                        <input
                                            type="text"
                                            value={zip}
                                            onChange={(e) =>
                                                setZip(e.target.value)
                                            }
                                            placeholder="ZIP*"
                                            className="input focus:ring-sky-500 w-full focus:border-sky-500 bg-gray-100 p-3 rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full justify-between bg-slate-100 border-t border-gray-400 p-4">
                                <button
                                    onClick={prevStep}
                                    className="text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 py-2 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-sky-600 hover:bg-sky-700"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    disabled={!canProceedPersonalInfo()}
                                    className={`text-white font-semibold shadow-sm rounded-md focus-visible:outline-sky-500 py-2 px-4 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
                                        canProceedPersonalInfo()
                                            ? "bg-sky-600 hover:bg-sky-700"
                                            : "bg-slate-400 cursor-not-allowed"
                                    }`}
                                >
                                    Complete Survey
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 6:
            return (
                <div className="bg-slate-100 bg-cover p-8 min-h-screen min-w-screen">
                    <div
                        className="text-gray-700 flex justify-center font-sans text-sm"
                        style={{ fontFamily: "DM Sans, sans-serif" }}
                    >
                        <div className="w-128 bg-white border border-gray-400 rounded-lg overflow-hidden">
                            <h2 className="text-center text-xl p-6 border-b border-gray-400 mb-2 font-extrabold bg-slate-100">
                                Thank you for your feedback!
                            </h2>
                            <div className="p-6">
                                <p className="text-center text-lg">
                                    We appreciate you taking the time to share
                                    your experience with us! Please allow 1-2
                                    weeks to receive your free product.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );

        default:
            return <div>Unknown step</div>;
    }
}
