import { useContext, useEffect, useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Spinner from "./Templates/Spinner";

const fetchData = async (userToken) => {
    try {
        const response = await fetch(
            "https://api.gotomarket.tools/api/giveaways/get",
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        );
        if (!response.ok) {
            throw new Error("Failed to fetch giveaway data");
        }
        const data = await response.json();
        return data.giveaways;
    } catch (error) {
        console.error("Fetching giveaways failed:", error);
        return []; // Return an empty array in case of error to ensure .map can still function
    }
};

export default function GiveawayList() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();

    const handleDelete = async (id) => {
        if (!user) {
            return;
        }

        // Confirm deletion
        if (window.confirm("Are you sure you want to delete this giveaway?")) {
            try {
                const response = await fetch(
                    `https://api.gotomarket.tools/api/giveaways/${id}`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );

                if (response.ok) {
                    queryClient.invalidateQueries("giveaway");
                    alert("Giveaway deleted successfully!");
                } else {
                    console.error("Failed to delete the giveaway");
                    alert("Failed to delete the giveaway.");
                }
            } catch (error) {
                console.error("Error deleting giveaway:", error);
                alert("Error occurred while deleting the giveaway.");
            }
        }
    };

    useEffect(() => {
        const handleEscape = (event) => {
            if (!showModal) return;

            if (event.key === "Escape") {
                setShowModal(false);
            }
        };

        document.addEventListener("keydown", handleEscape);
        return () => document.removeEventListener("keydown", handleEscape);
    }, [showModal]);

    const {
        data: giveaway,
        status,
        error,
    } = useQuery(["giveaway", userToken], () => fetchData(userToken), {
        enabled: !!userToken,
    });

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return <p>Error: {error.message}</p>;
    }

    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="flow-root">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">
                                    Giveaways
                                </h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    A list of Giveaways on this account
                                </p>
                            </div>
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <Link
                                    type="button"
                                    to="create"
                                    className="flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                                >
                                    Create Giveaway
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Giveaway Name
                                                </th>
                                                <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">
                                                        Actions
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {giveaway.length === 0 ? (
                                                <tr>
                                                    <td
                                                        colSpan="4"
                                                        className="py-4 text-center text-sm"
                                                    >
                                                        No Giveaways available.
                                                        Please add a Giveaway.
                                                    </td>
                                                </tr>
                                            ) : (
                                                giveaway.map((giveaways) => (
                                                    <tr key={giveaways._id}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {`${giveaways.giveawayName}`}
                                                        </td>
                                                        <td className="py-4 pr-4 flex items-center justify-end sm:pr-6">
                                                            <Link
                                                                to={`edit/${giveaways._id}`}
                                                                title="Edit"
                                                                className="text-sky-600 hover:text-sky-900 mr-2"
                                                            >
                                                                <PencilIcon
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </Link>
                                                            <button
                                                                title="Delete"
                                                                className="text-red-600 hover:text-red-900"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        giveaways._id
                                                                    )
                                                                }
                                                            >
                                                                <TrashIcon
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Fallback JSX if none of the above conditions are met
    return null;
}
