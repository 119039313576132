import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function Step1({
    asin,
    setAsin,
    onSubmit,
    isLoading,
    layout,
    setLayout,
}) {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };

    // Handler for layout selection
    const handleLayoutSelection = (selectedLayout) => {
        setLayout(selectedLayout);
    };

    return (
        <>
            <div className="bg-gray-100">
                <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <div className="flex justify-start">
                        <button
                            className="flex items-center space-x-2 text-sky-600"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(-1); // Use navigate(-1) to go back
                            }}
                        >
                            <ArrowLeftIcon className="h-5 w-5" />
                            <span>Back</span>
                        </button>
                    </div>
                </div>
                <main className="mx-auto max-w-2xl px-4 pb-12 lg:pb-16">
                    <div className="mx-auto max-w-7xl py-12 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-3xl">
                            {/* Question 1: Layout selection */}
                            <div>
                                <h3 className="text-3xl leading-6 font-bold text-gray-900 text-center">
                                    Select Layout
                                </h3>
                                <div className="mt-4">
                                    <div className="flex justify-center mt-2">
                                        <button
                                            type="button"
                                            className={`px-4 py-2 rounded-md ${
                                                layout === "Minimal"
                                                    ? "bg-gray-300 text-gray-700"
                                                    : "bg-gray-200 text-gray-700"
                                            }`}
                                            onClick={() =>
                                                handleLayoutSelection("Minimal")
                                            }
                                        >
                                            <img
                                                src="/img/min.svg"
                                                alt="Minimalist"
                                                className="block w-24 mb-2" // Adjust dimensions as needed
                                            />
                                            <p>Minimalist</p>
                                        </button>
                                        <button
                                            type="button"
                                            className={`ml-4 px-4 py-2 rounded-md ${
                                                layout === "Detailed"
                                                    ? "bg-gray-300 text-gray-700"
                                                    : "bg-gray-200 text-gray-700"
                                            }`}
                                            onClick={() =>
                                                handleLayoutSelection(
                                                    "Detailed"
                                                )
                                            }
                                        >
                                            <img
                                                src="/img/max.svg"
                                                alt="Detailed"
                                                className="block w-24 mb-2" // Adjust dimensions as needed
                                            />
                                            <p>Detailed</p>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Question 2: Conditional rendering based on layout selection */}
                            {layout && ( // <-- This ensures that the following elements are not rendered until a layout is selected
                                <>
                                    <h3
                                        className="text-3xl leading-6 font-bold text-gray-900 text-center mt-6"
                                        id="modal-title"
                                    >
                                        Enter ASIN
                                    </h3>
                                    <p className="mt-3 mb-6 text-center">
                                        Enter the ASIN of the product you would
                                        like to generate a landing page for
                                    </p>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            placeholder="Enter ASIN"
                                            value={asin}
                                            onChange={(e) =>
                                                setAsin(e.target.value)
                                            }
                                            className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mb-6"
                                        />
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className="flex m-auto items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-orange-700 sm:px-8"
                                        >
                                            {isLoading
                                                ? "Loading..."
                                                : "Generate Landing Page"}
                                        </button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
