import { Link } from "react-router-dom";

export default function AccountComponent({ firstName, lastName, email }) {
    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                Account
                            </h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Your Account Information
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <Link
                                to="edit"
                                type="button"
                                className="flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                            >
                                Edit
                            </Link>
                        </div>
                    </div>
                    <div className="mt-4 flow-root">
                        <div className="sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"></div>
                                <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-8">
                                    <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg p-4">
                                        <div className="col-span-1">
                                            <label className="block text-md font-medium text-black">
                                                First Name
                                            </label>
                                            <p className="mt-3 block w-full text-sm border-gray-300 rounded-md">
                                                {firstName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg p-4">
                                        <div className="col-span-1">
                                            <label className="block text-md font-medium text-black">
                                                Last Name
                                            </label>
                                            <p className="mt-3 block w-full text-sm border-gray-300 rounded-md">
                                                {lastName}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="bg-white shadow ring-1 ring-black ring-opacity-5 rounded-lg p-4">
                                        <div className="col-span-1">
                                            <label className="block text-md font-medium text-black">
                                                Email
                                            </label>
                                            <p className="mt-3 block w-full text-sm border-gray-300 rounded-md">
                                                {email}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
