import { useContext } from "react";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Spinner from "./Templates/Spinner";

const fetchData = async (userToken) => {
    const response = await fetch(
        "https://api.gotomarket.tools/api/landingpage/",
        {
            headers: { Authorization: `Bearer ${userToken}` },
        }
    );

    if (!response.ok) {
        throw new Error("Failed to fetch landing page");
    }

    const landingPageData = await response.json();

    const landingPagesWithViews = await Promise.all(
        landingPageData.landingPages.map(async (landingpage) => {
            const pageId = landingpage._id;
            try {
                const viewCountResponse = await fetch(
                    `https://api.gotomarket.tools/api/analytics/get-view-count/landingpage/${pageId}`,
                    { headers: { Authorization: `Bearer ${userToken}` } }
                );

                if (!viewCountResponse.ok) {
                    throw new Error("Failed to fetch view count");
                }

                const viewCountData = await viewCountResponse.json();
                return { ...landingpage, views: viewCountData.views || 0 };
            } catch (error) {
                return { ...landingpage, views: 0 };
            }
        })
    );

    return landingPagesWithViews;
};

export default function LandingPageList() {
    const { user } = useContext(AuthContext);
    const userToken = user?.token;
    const queryClient = useQueryClient();

    const handleDelete = async (id) => {
        if (!user) {
            return;
        }

        try {
            const response = await fetch(
                `https://api.gotomarket.tools/api/landingpage/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response.ok) {
                queryClient.invalidateQueries("landingPage");
            } else {
                console.error("Failed to delete the landing page");
            }
        } catch (error) {
            console.error("Error deleting landing page:", error);
        }
    };

    const {
        data: landingPage,
        status,
        error,
    } = useQuery(["landingPage", userToken], () => fetchData(userToken), {
        enabled: !!userToken,
    });

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return <p>Error: {error.message}</p>;
    }

    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                Landing Pages
                            </h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of landing pages on this account
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <Link
                                to="add"
                                type="button"
                                className="flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                            >
                                Add Landing Page
                            </Link>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Product Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Landing Page Type
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Views
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    ASIN
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Coupon Codes Left
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Campaign End Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                ></th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {!landingPage ||
                                            landingPage.length === 0 ? (
                                                <tr>
                                                    <td
                                                        className="py-4"
                                                        colSpan="8"
                                                    >
                                                        <div className="flex justify-center text-sm">
                                                            No landing pages
                                                            available. Please
                                                            add a landing page.
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                landingPage.map((product) => {
                                                    function getCampaignStatus(
                                                        endDate
                                                    ) {
                                                        const now = new Date();
                                                        const campaignEndDate =
                                                            new Date(endDate);
                                                        if (
                                                            now >
                                                            campaignEndDate
                                                        ) {
                                                            return "Complete";
                                                        } else {
                                                            return campaignEndDate.toLocaleString(); // Format as per your requirements
                                                        }
                                                    }

                                                    let remainingCoupons = 0;

                                                    // Updated logic
                                                    if (
                                                        product.couponCodes &&
                                                        product.couponCodes
                                                            .length > 0
                                                    ) {
                                                        remainingCoupons =
                                                            product.couponCodes
                                                                .flatMap(
                                                                    (code) =>
                                                                        code.split(
                                                                            ","
                                                                        )
                                                                )
                                                                .filter(
                                                                    (code) =>
                                                                        code.trim() !==
                                                                        ""
                                                                ).length;
                                                    }

                                                    return (
                                                        <tr key={product._id}>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {product.title &&
                                                                product.title
                                                                    .length > 50
                                                                    ? product.title.substring(
                                                                          0,
                                                                          50
                                                                      ) + "..."
                                                                    : product.title}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {product.layout}
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                                                {product.views !==
                                                                undefined
                                                                    ? product.views
                                                                    : 0}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {product.asin}
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {
                                                                    remainingCoupons
                                                                }
                                                            </td>
                                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                {getCampaignStatus(
                                                                    product
                                                                        .countdownConfig
                                                                        .endDate
                                                                )}
                                                            </td>
                                                            <td className="py-4 pr-4 flex items-center justify-end sm:pr-6">
                                                                <Link
                                                                    to={`/landingpage/edit/${product._id}`}
                                                                    title="Edit"
                                                                    className="text-sky-600 hover:text-sky-900 mr-2"
                                                                >
                                                                    <PencilIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </Link>
                                                                <a
                                                                    href={`/landingpage/${product._id}`}
                                                                    target="_blank"
                                                                    className="text-sky-600 hover:text-sky-900 mr-2"
                                                                >
                                                                    <EyeIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </a>
                                                                <button
                                                                    title="Delete"
                                                                    className="text-red-600 hover:text-red-900"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            product._id
                                                                        )
                                                                    } // Call handleDelete with the qr's id
                                                                >
                                                                    <TrashIcon
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
