import { useEffect } from "react";
import LandingPageTemplate from "../components/Templates/CouponTemplate";

export default function LandingPageTemplates() {
    useEffect(() => {
        document.title = "Landing Page Template - gotomarket.tools";
    }, []);
    return (
        <>
            <LandingPageTemplate />
        </>
    );
}
