import { useEffect } from "react";
import ReviewsList from "../components/ReviewsList";

export default function Review() {
    useEffect(() => {
        document.title = "Review Programs - gotomarket.tools";
    }, []);

    return (
        <>
            <ReviewsList />
        </>
    );
}
