// WithAuth.js
import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import Navigation from "./components/Navigation";

const withAuth = (Component) => {
    return ({ withNavigation, ...props }) => {
        const { user, loading } = useAuth();

        if (loading) {
            return null;
        }

        if (!user) {
            return <Navigate to="/login" />;
        }

        return withNavigation ? (
            <Navigation>
                <Component {...props} />
            </Navigation>
        ) : (
            <Component {...props} />
        );
    };
};

export default withAuth;
