import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

function PlanDetails() {
    const { user } = useContext(AuthContext); // Get the user object from context
    const [planDetails, setPlanDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPlanDetails = async () => {
            try {
                // Replace with your actual API endpoint
                const response = await fetch(
                    `https://api.gotomarket.tools/api/plans/${user.planId}`
                );
                const data = await response.json();

                // Convert price from cents to dollars
                if (data.price) {
                    data.price = (data.price / 100).toFixed(2); // Now, data.price is in dollars
                }

                setPlanDetails(data);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch plan details:", error);
                setLoading(false);
            }
        };

        if (user?.planId) {
            fetchPlanDetails();
        }
    }, [user?.planId]); // Re-run the effect if the planId changes

    if (loading) {
        return <div>Loading plan details...</div>;
    }

    if (!planDetails) {
        return <div>Error fetching plan details or none existent.</div>;
    }

    // Render your plan details here. This is just a placeholder structure.
    return (
        <div>
            <h1>Your Plan Details</h1>
            {/* Display plan details as needed */}
            <p>Name: {planDetails.name}</p>
            <p>Price: ${planDetails.price /* Note the dollar sign here */}</p>
            {/* If you have a description in your plan details */}
            {/* <p>Description: {planDetails.description}</p> */}
            {/* other plan properties... */}
        </div>
    );
}

export default PlanDetails;
