import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useParams } from "react-router-dom"; // import useParams hook

export default function CustomerProfile() {
    const { user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState([]);
    const { customerId } = useParams(); // get customerId from route params

    const fetchCustomer = async () => {
        try {
            const response = await fetch(
                `https://api.gotomarket.tools/api/customers/${customerId}`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch landing pages");
            }
            const { customer } = await response.json();
            setCustomer(customer);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching landing pages:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (user) {
            fetchCustomer();
        }
    }, [user, customerId]);

    // ... (other code)

    return (
        <main>
            {/* Page header */}
            <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">
                            {customer.firstName}&nbsp;{customer.lastName}
                        </h1>
                    </div>
                </div>
            </div>

            <div className="mx-auto mt-8 grid max-w-3xl sm:px-6 lg:max-w-7xl">
                <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h2
                                    id="applicant-information-title"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Customer Information
                                </h2>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Personal details
                                </p>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Email Address
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {customer.email
                                                ? customer.email
                                                : "-"}
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Phone Number
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {customer.phone
                                                ? customer.phone
                                                : "-"}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>

                    {/* Redemption Table */}
                    <section aria-labelledby="redemptions-title">
                        <div className="bg-white shadow sm:rounded-lg mt-6">
                            <div className="px-4 py-5 sm:px-6">
                                <h2
                                    id="redemptions-title"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Redemptions
                                </h2>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Redemption activity for this customer
                                </p>
                            </div>
                            <div className="border-t border-gray-200 py-1">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Product Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Coupon Code
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {customer.redemptions?.map(
                                            (redemption, index) => (
                                                <tr key={index}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                                        <div className="text-sm text-gray-900">
                                                            {`${new Date(
                                                                redemption.createdAt
                                                            ).toLocaleDateString(
                                                                "en-US",
                                                                {
                                                                    year: "numeric",
                                                                    month: "long",
                                                                    day: "numeric",
                                                                }
                                                            )} ${new Date(
                                                                redemption.createdAt
                                                            ).toLocaleTimeString(
                                                                "en-US"
                                                            )}`}
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-sm text-gray-900">
                                                            {redemption
                                                                .productName
                                                                .length > 50
                                                                ? redemption.productName.substring(
                                                                      0,
                                                                      50
                                                                  ) + "..."
                                                                : redemption
                                                                      .landingPage
                                                                      .title}
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="text-sm text-gray-900">
                                                            {
                                                                redemption.couponCode
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}
